export const packageData = [
  {
    id: 1,
    name: 'แพ็คเกจ Standard',
    jobPost: { usage: 10, total: 10 },
    credit: { usage: 300, total: 300 },
    purchasedDate: '14 ก.พ. 2024',
    expiredDate: '15 ก.พ. 2025',
    price: '1000000',
    duration: '1 ปี',
    status: 'using',
  },
  {
    id: 2,
    name: 'แพ็คเกจ Beyond',
    jobPost: { usage: 25, total: 30 },
    credit: { usage: 800, total: 1000 },
    purchasedDate: '14 ก.พ. 2024',
    expiredDate: '15 ก.พ. 2025',
    price: '1000000',
    duration: '1 เดือน',
    status: 'expired',
  },
  {
    id: 3,
    name: 'แพ็คเกจ Beyond',
    jobPost: { usage: 30, total: 30 },
    credit: { usage: 780, total: 1000 },
    purchasedDate: '14 ก.พ. 2024',
    expiredDate: '15 ก.พ. 2025',
    price: '1000000',
    duration: '1 เดือน',
    status: 'expired',
  },
  {
    id: 4,
    name: '3,000 เครดิตค้นหาโปรไฟล์',
    credit: { usage: 2500, total: 3000 },
    purchasedDate: '14 ก.พ. 2024',
    expiredDate: '15 ก.พ. 2025',
    price: '1000000',
    duration: '1 ปี',
    status: 'expired',
  },
];

export const quotationData = [
  {
    index: 1,
    quotationId: 'QTJFF1812202406581',
    createdDate: '18 ธ.ค. 2024',
    expiredDate: '1 ม.ค. 2025',
    total: '28,052.00',
    closedMonth: 'ธ.ค. 2025',
    bookmark: true,
  },
  {
    index: 2,
    quotationId: 'QTJFF1309202400002',
    createdDate: '13 ก.ย. 2024',
    expiredDate: '13 พ.ย. 2024',
    total: '32,599.40',
    closedMonth: 'พ.ย. 2024',
    bookmark: true,
  },
  {
    index: 3,
    quotationId: 'QTJFF2402202400145',
    createdDate: '24 ก.พ. 2024',
    expiredDate: '22 มี.ค. 2024',
    total: '38,452.00',
    closedMonth: 'มี.ค. 2024',
    bookmark: false,
  },
  {
    index: 4,
    quotationId: 'QTJFF2009202400002',
    createdDate: '20 ก.ย. 2024',
    expiredDate: '18 พ.ย. 2024',
    total: '45,209.10',
    closedMonth: 'มี.ค. 2024',
    bookmark: false,
  },
];
