import { Descriptions, Flex } from 'antd';
import React from 'react';
import CompanyBadge from '../../../../components/shared/company-badge';

function CompanyDetail(props) {
  const { company, ...otherProps } = props;

  const companySize = otherProps?.headcountRange?.split(' | ')[0] || '-';
  const companyRange = otherProps?.headcountRange?.split(' | ')[1] || '-';

  const items = [
    {
      key: '1',
      label: (
        <Flex justify="space-between" align="center">
          <>ชื่อบริษัท</>
          {/* <Button useFor="resetPassword" /> */}
        </Flex>
      ),
      children: company?.name || '-',
    },
    {
      key: '2',
      label: 'รหัสบริษัท',
      children: company?.caNumber || '-',
    },
    {
      key: '3',
      label: 'ประเภทธุรกิจ',
      children: otherProps?.businessType || '-',
    },
    {
      key: '4',
      label: 'จำนวนพนักงาน',
      children: companyRange || '-',
    },
    {
      key: '5',
      label: 'ขนาดบริษัท',
      children: companySize || '-',
    },
    {
      key: '6',
      label: 'สถานะบริษัท',
      children: <CompanyBadge status={company?.status} />,
    },
    {
      key: '7',
      label: 'สถานะการโพสต์งาน',
      children: company?.name || '-',
    },
    {
      key: '8',
      label: 'แพ็กเกจล่าสุด',
      children: company?.name || '-',
    },
    {
      key: '9',
      label: 'จำนวนประกาศงาน',
      children: otherProps?.totalJobPost || '-',
    },
    {
      key: '10',
      label: <>อีเมลบริษัท</>,
      children: otherProps?.emailCompany?.companyEmail || '-',
      span: 2,
    },
  ];

  return <Descriptions items={items} column={{ md: 1, lg: 2, xl: 3 }} size="small" />;
}

export default CompanyDetail;
