import React, { useEffect } from 'react';
import { ModalTheme } from '../../../../components/modal';
import Table from '../../../../components/table-antd';
import { useMutation } from '@tanstack/react-query';
import { GET } from '../../../../services';
import { applicantsColumn } from './column';
import CompanyInfomation from '../company-info';
import { Container, ScrollContainer, StickyContainer } from './styled-component';
import { Flex, Typography } from 'antd';

const applicantsApi = async (companyId) => {
  try {
    const response = await GET(`/tls-company/apply-count/${companyId}`);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

function ApplicantsModal(props) {
  const { companyDetails, ...modalProps } = props;

  const companyId = companyDetails?._id;

  const {
    mutate: fetchApplicants,
    data,
    isLoading,
  } = useMutation({
    mutationKey: ['applicants', companyId],
    mutationFn: () => applicantsApi(companyId),
  });

  useEffect(() => {
    fetchApplicants();
  }, []);

  const applicantsData = data?.result?.data || [];
  const applicantsTotal = data?.result?.total || 0;

  return (
    <ModalTheme {...modalProps} title="ยอดผู้สมัคร" width={1840}>
      <Container>
        <StickyContainer>
          <CompanyInfomation {...companyDetails} />
        </StickyContainer>
        <ScrollContainer>
          <Flex justify="flex-end">
            <Typography.Text>{`ยอดผู้สมัครทั้งหมด: ${applicantsTotal} คน`}</Typography.Text>
          </Flex>
          <Table data={applicantsData} loading={isLoading} columns={applicantsColumn()} />
        </ScrollContainer>
      </Container>
    </ModalTheme>
  );
}

export default ApplicantsModal;
