import React from 'react';
import { MODAL_TYPES } from './constants/modal-types';
import { useModalContext } from '../../contexts/modal-context';
import ContactModal from '../../screens/telesales/views/contact-modal';
import HistoryCallModal from '../../screens/telesales/views/history-call-modal';
// import EditCallInformationModal from '../../screens/telesales/views/edit-call-info-modal';
// import PackageItemsModal from '../../screens/telesales/views/package-items-modal';
// import EditContactModal from '../../screens/telesales/views/edit-contact-modal';
// import CallModal from '../../screens/telesales/views/call-modal';
// import AddModal from '../../screens/telesales/views/add-contact-modal';
// import NoteModal from '../../screens/telesales/views/note-modal';
// import QuotationModal from '../../screens/telesales/views/quotation-modal';
// import ApplicantCountModal from '../../screens/telesales/views/applicant-count-modal';
import TrackingModal from './views/tracking-modal';
import SalesModal from './views/sales-modal';

export const ModalRenderer = () => {
  const { modalState, closeModal } = useModalContext();
  const { isOpen, modalType, modalProps } = modalState;

  if (!isOpen || !modalType) return null;

  const baseProps = {
    open: isOpen,
    onClose: closeModal,
    ...modalProps,
  };

  const modalComponents = {
    [MODAL_TYPES.CONTACT]: ContactModal,
    [MODAL_TYPES.HISTORY_CALL]: HistoryCallModal,
    // [MODAL_TYPES.EDIT_CALL_INFORMATION]: EditCallInformationModal,
    // [MODAL_TYPES.PACKAGE_ITEMS]: PackageItemsModal,
    // [MODAL_TYPES.APPLICANTS_COUNT]: ApplicantCountModal,
    // [MODAL_TYPES.EDIT_CONTACT]: EditContactModal,
    // [MODAL_TYPES.ADD_CONTACT]: AddModal,
    // [MODAL_TYPES.CALL]: CallModal,
    // [MODAL_TYPES.NOTE]: NoteModal,
    // [MODAL_TYPES.QUOTATION]: QuotationModal,
    [MODAL_TYPES.TRACKING]: TrackingModal,
    [MODAL_TYPES.SALES]: SalesModal,
  };

  const ModalComponent = modalComponents[modalType];

  return ModalComponent ? <ModalComponent {...baseProps} /> : null;
};
