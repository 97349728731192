import React, { useReducer } from 'react';
import { createContext, useContext } from 'react';
import { ModalRenderer } from '../views/modal-renderer';
import { initialState, modalReducer } from '../views/modal-renderer/reducer';
import useModalEvent from '../views/modal-renderer/hooks/useModalEvent';

export const ModalContext = createContext();

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }
  return context;
};

export const ModalProvider = ({ children }) => {
  const [modalState, dispatch] = useReducer(modalReducer, initialState);

  const openModal = (modalType, modalProps) => {
    dispatch({ type: 'OPEN_MODAL', payload: { modalType, modalProps } });
  };

  const closeModal = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const goBack = () => {
    dispatch({ type: 'GO_BACK' });
  };

  const modalEvent = useModalEvent(openModal);

  const value = {
    modalState,
    modalEvent,
    openModal,
    closeModal,
    goBack,
  };

  return (
    <ModalContext.Provider value={value}>
      {children}
      <ModalRenderer />
    </ModalContext.Provider>
  );
};
