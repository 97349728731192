import { Yup } from '../../../../../../../../../helpers/yup';

function checkArrayOrEmpty() {
  return Yup.mixed().test((value) => {
    if (value === undefined) return true;
    if (value === '') return true;
    if (Array.isArray(value)) return true;
    return false;
  });
}

export const schema = Yup.object().shape({
  note: Yup.string().required(),
  keySuccess: Yup.string(),
  stageManual: Yup.string(),
  followUpDate: Yup.string(),
  purchaseOccasion: Yup.string(),
  knowUs: checkArrayOrEmpty(),
  totalCandidate: Yup.string(),
  positionReceived: Yup.string(),
  jobNeed: Yup.string(),
  avgJobpost: Yup.string(),
  avgSearch: Yup.string(),
  favPosition: Yup.string(),
  findPosition: checkArrayOrEmpty(),
  competitors: checkArrayOrEmpty(),
  budgetPeriod: Yup.string(),
  budget: Yup.string(),
  calledStatus: Yup.string().required(),
});
