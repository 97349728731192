import React from 'react';
import { DateColumn } from '../../../../../../../screens/telesales/views/table/components/render-column';
import CompanyProfileLink from '../../../../../../../components/shared/company-profile-link';

export const column = ({ isPrimaryState }) => {
  if (isPrimaryState) {
    return [
      {
        title: 'ชื่อบริษัท',
        dataIndex: 'companyName',
        key: 'companyName',
        render: (data) => <CompanyProfileLink text={data} href="#" />,
      },
      {
        title: 'รหัสบริษัท',
        dataIndex: 'caNumber',
        key: 'caNumber',
      },

      {
        title: 'ประเภทธุรกิจ',
        dataIndex: 'businessType',
        key: 'businessType',
      },
      {
        title: 'Follow up Date',
        dataIndex: 'followUpDate',
        key: 'followUpDate',
        render: (data) => <DateColumn date={data} />,
      },
      {
        title: 'โทรล่าสุด',
        dataIndex: 'lastedCallDate',
        key: 'lastedCallDate',
        render: (data) => <DateColumn date={data} />,
      },
      {
        title: 'โน้ตล่าสุด',
        dataIndex: 'lastedNote',
        key: 'lastedNote',
      },
    ];
  }
  return [
    {
      title: 'ชื่อบริษัท',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (data) => <Link text={data} href="#" />,
    },
    {
      title: 'รหัสบริษัท',
      dataIndex: 'caNumber',
      key: 'caNumber',
    },

    {
      title: 'ประเภทธุรกิจ',
      dataIndex: 'businessType',
      key: 'businessType',
    },
    {
      title: 'วันที่เริ่มใช้งาน',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (data) => <DateColumn date={data} />,
    },
    {
      title: 'แพ็กเกจที่ใช้ล่าสุด',
      dataIndex: 'currentPackage',
      key: 'currentPackage',
    },
    {
      title: 'โน้ตล่าสุด',
      dataIndex: 'lastedNote',
      key: 'lastedNote',
      align: 'left',
    },
    {
      title: 'ใบเสนอราคา',
      dataIndex: 'quatationNo',
      key: 'quatationNo',
      render: (data) => <Link text={data} href="#" />,
    },
    {
      title: 'ยอดซื้อ (บาท)',
      dataIndex: 'total',
      key: 'total',
    },
  ];
};
