import { Row, Col, Layout } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { color } from '../resources';
const { Header, Sider } = Layout;

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'CSChatThaiUI';
        src: url('../assets/fonts/ttf/CSChatThaiUI.ttf') format("truetype");
    }

    *{
      font-family: 'CSChatThaiUI','sans-sertif' !important;
    }

    html, body {
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    *::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }
    *::-webkit-scrollbar-track {
      border-radius: 0px;
      background-color: ${color.scrollbarTrack};
    }

    *::-webkit-scrollbar-track:hover {
      background-color: ${color.scrollbarTrackHover};
    }

    *::-webkit-scrollbar-track:active {
      background-color: ${color.scrollbarTrackActive};
    }

    *::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: ${color.scrollbarThumb};
    }

    *::-webkit-scrollbar-thumb:hover {
      background-color: ${color.scrollbarThumbHover};
    }

    *::-webkit-scrollbar-thumb:active {
      background-color: ${color.scrollbarThumbActive};
    }

    .ant-table-container{
      *::-webkit-scrollbar {
      height: 12px;
      width: 12px;
    }
    }
`;

export const RowComponent = styled(Row)`
  display: flex;
  align-items: center;
`;

export const Required = styled.div`
  color: ${color.red};
  position: relative;
  top: 3px;
  left: 3px;
`;

export const ContainerButton = styled(Col)`
  text-align-last: ${(props) =>
    props.left ? 'start' : props.center ? 'center' : props.right ? 'end' : ''};
  width: 100%;
`;

export const HeaderBarStyle = styled(Header)`
  padding: 0 !important;
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
`;

export const SideBarStyle = styled(Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
`;

export default GlobalStyle;
