import React from 'react';
import { Space, Button, Typography, Popconfirm } from 'antd';
import { ThaiMonthDateFormat } from '../../../../../../../../components/shared/format-text';
import StatusTag from '../../../../../../../../components/shared/status-tag';

export const jobPosition = ({ isMultipleSelected }) => {
  return [
    {
      title: 'แพ็กเกจ',
      dataIndex: 'package',
      key: 'package',
      render: (status) => <StatusTag type="packageApplicant" status={status} />,
    },
    {
      title: 'ชื่อประกาศงาน',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
    },
    {
      title: 'วันที่โพสต์',
      dataIndex: 'postDate',
      key: 'postDate',
      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'วันที่อัปเดตล่าสุด',
      dataIndex: 'updateDate',
      key: 'updateDate',
      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <StatusTag type="jobStatus" status={status} />,
    },
    {
      title: 'ผู้สมัคร',
      dataIndex: 'candidate',
      key: 'candidate',
      fixed: 'right',
      render: (data, records) => (
        <Space size={8}>
          <Typography.Text>{data}</Typography.Text>
          <Typography.Text type="success">(+{records.candidateIncrease})</Typography.Text>
        </Space>
      ),
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      render: () => (
        <Popconfirm icon={null} title="คุณต้องการแจ้ง PR งานหรือไม่?" okText="ใช่" cancelText="ไม่">
          <Button disabled={!isMultipleSelected}>แจ้ง PR งาน</Button>
        </Popconfirm>
      ),
    },
  ];
};

export const promoteJobColumn = () => {
  return [
    {
      title: 'ชื่อประกาศงาน (ครั้งที่ส่ง)',
      key: 'jobsTitle',
      dataIndex: 'jobsTitle',
      render: (data) => (
        <Space direction="vertical">
          {data.map((item, index) => (
            <Typography.Text key={index}>{item}</Typography.Text>
          ))}
        </Space>
      ),
    },
    {
      title: 'รายละเอียด',
      key: 'attachLinks',
      dataIndex: 'attachLinks',
      render: (data) => (
        <Space direction="vertical">
          {data.map((item, index) => (
            <a key={index}>{item}</a>
          ))}
        </Space>
      ),
    },
    {
      title: 'วันที่ส่ง',
      key: 'sendDate',
      dataIndex: 'sendDate',
      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'ชื่อผู้ส่ง',
      key: 'senderName',
      dataIndex: 'senderName',
    },
  ];
};

export const promoteDetailColumn = () => {
  return [
    {
      title: 'ชื่อประกาศงาน (ครั้งที่ส่ง)',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
    },
    {
      title: 'รายละเอียด',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'วันที่ส่ง',
      dataIndex: 'sendDate',
      key: 'sendDate',
    },
    {
      title: 'ชื่อผู้ส่ง',
      dataIndex: 'sender',
      key: 'sender',
    },
  ];
};
