import React from 'react';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  NotificationOutlined,
  PhoneOutlined,
  PhoneTwoTone,
  ReloadOutlined,
  SaveOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { color } from '../../resources';

export const BUTTON_TEMPLATES = {
  resetPassword: {
    danger: true,
    children: 'รีเซ็ตรหัสผ่าน',
    className: 'reset-password',
    icon: <ReloadOutlined />,
  },
  edited: {
    icon: <EditOutlined />,
    children: 'แก้ไข',
    className: 'edited',
  },
  reportCase: {
    danger: true,
    children: 'ส่งเรื่องดำเนินการ',
    className: 'report-case',
    icon: <FileOutlined />,
  },
  addContact: {
    type: 'primary',
    color: 'success',
    children: 'เพิ่มผู้ติดต่อ',
    icon: <UserAddOutlined />,
  },
  promote: {
    type: 'primary',
    color: 'success',
    children: 'แจ้ง PR งาน',
    icon: <NotificationOutlined />,
  },
  notCall: {
    children: 'โทร',
    icon: <PhoneOutlined />,
  },
  onCalling: {
    children: 'กำลังโทร',
    icon: <PhoneTwoTone />,
  },
  save: {
    type: 'primary',
    children: 'บันทึก',
    icon: <SaveOutlined />,
  },
  cancel: {
    icon: <CloseOutlined />,
    children: 'ยกเลิก',
  },
  sendReport: {
    // danger: true,
    // children: 'ส่งเรื่อง',
    // icon:,
  },
  startCall: {
    type: 'primary',
    children: 'เริ่มการโทร',
    bgColor: color.green80,
    icon: (
      <PhoneOutlined
        style={{
          transform: 'rotate(90deg)',
        }}
      />
    ),
  },
  endCall: {
    type: 'primary',
    danger: true,
    children: 'สิ้นสุดการโทร',
    icon: (
      <PhoneOutlined
        style={{
          transform: 'rotate(90deg)',
        }}
      />
    ),
  },
  removeContact: {
    danger: true,
    children: 'ลบผู้ติดต่อ',
    icon: <DeleteOutlined />,
  },
  editedContact: {
    className: 'edited-contact',
    children: 'แก้ไขผู้ติดต่อ',
    icon: <EditOutlined />,
  },
  remove: {
    danger: true,
    children: 'ลบ',
    icon: <DeleteOutlined />,
  },
  closeReportCases: {
    danger: true,
    children: 'ปิดงานที่เลือก',
  },
};
