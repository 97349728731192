import React, { useState } from 'react';
import Layout from '../../layout';
import { Divider, Space } from 'antd';
import Total from './components/total';
import Table from '../../../../../components/table-antd';
import CompareDate from './components/compare-date';
import { jobData, mockData } from './mock';
import { jobPosition, promoteJobColumn } from './constants/columns';
import Button from '../../../../../components/button-antd';

function JobPosition() {
  const [selectedRows, setSelectedRows] = useState();

  console.log(selectedRows);

  return (
    <Layout>
      <Layout.Header>
        <Layout.Header.Title>ตำแหน่งงาน</Layout.Header.Title>
        <Space size={[8, 8]} direction="vertical" align="end">
          <Total />
          <Space size={[8, 16]} block>
            <CompareDate />
            <Button useFor="promote" />
          </Space>
        </Space>
      </Layout.Header>

      <Table columns={jobPosition()} data={jobData} getSelectedRows={setSelectedRows} />

      <Divider />

      <Table rowKey="id" columns={promoteJobColumn()} dataSource={mockData} />
    </Layout>
  );
}

export default JobPosition;
