import React from 'react';
import PropTypes from 'prop-types';
import { color } from '../../../resources';
import { Typography } from 'antd';

const VERIFIES = {
  silver: {
    label: 'silver',
    fullLabel: 'Silver Verified',
    color: color.grey100,
  },
  blue: {
    label: 'blue',
    fullLabel: 'Blue Verified',
    color: color.oldblue,
  },
  official: {
    label: 'official',
    fullLabel: 'Official Verified',
    color: color.warningGreen,
  },
};

function CompanyBadge(props) {
  const { status, isFullLabel } = props;

  const textColor = VERIFIES?.[status]?.color || null;
  const label = VERIFIES?.[status]?.label || null;
  const fullLabel = VERIFIES?.[status]?.fullLabel || null;

  if (!textColor || !label || !fullLabel) return <></>;

  return (
    <Typography.Text style={{ color: textColor }}>
      {isFullLabel ? fullLabel : label}
    </Typography.Text>
  );
}

CompanyBadge.propTypes = {
  status: PropTypes.string,
  isFullLabel: PropTypes.bool,
};

CompanyBadge.defaultProps = {
  isFullLabel: true,
};
export default CompanyBadge;
