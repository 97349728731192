import React from 'react';
import { convertStrToFormat } from '../../../functions';
import * as dateTimeUtils from '../../../utils/date-time';
import { Space, Typography } from 'antd';
import { color } from '../../../resources';
import LinkComponent from '../../link';

export function NumberFormat({ str, onClick = null, unit = null }) {
  const result = convertStrToFormat(str, 'comma_number');
  if (!result) return <Typography.Text>-</Typography.Text>;

  const label = unit ? `${result} ${unit}` : result;

  if (onClick) {
    return (
      <Space onClick={onClick}>
        <LinkComponent isBlue isUnderline onClick={onClick}>
          {result}
        </LinkComponent>
        <Typography.Text>{unit}</Typography.Text>
      </Space>
    );
  }

  return <Typography.Text>{label}</Typography.Text>;
}

export function DateFormat({ date }) {
  const result = dateTimeUtils.formatDate(date);
  if (!result) return <Typography.Text>-</Typography.Text>;

  return <Typography.Text>{result}</Typography.Text>;
}

export function ThaiMonthDateFormat({ date }) {
  const result = dateTimeUtils.fullThaiDateShortMonth(date);
  if (!result) return <Typography.Text>-</Typography.Text>;

  return <Typography.Text>{result}</Typography.Text>;
}

export function ShortThaiMonthFormat({ date }) {
  const result = dateTimeUtils.formatDateToThaiCustom({ date: date, format: 'MMM YYYY' });
  if (!result) return <Typography.Text>-</Typography.Text>;

  return <Typography.Text>{result}</Typography.Text>;
}

export function DateToHoursFormat({ date }) {
  const result = dateTimeUtils.formatDateCustom({ date: date, format: 'HH:mm' });
  if (!result) return <Typography.Text>-</Typography.Text>;

  return <Typography.Text>{`${result} น.`}</Typography.Text>;
}

export function SecondsToMinutesFormat({ seconds }) {
  const result = dateTimeUtils.formatSecondsToTime(seconds / 1000, 'mm:ss');
  if (!result) return <Typography.Text>-</Typography.Text>;

  return <Typography.Text>{`${result} นาที`}</Typography.Text>;
}
