import React, { useEffect, useState } from 'react';
import { column } from './constants/column';
import { useQuery } from '@tanstack/react-query';
import { GET_WITH_PARAMS } from '../../../../../../services';
import Table from '../../../../../../components/table-antd';

const getCurrentCustomerApi = async ({ pageSize, pageIndex, type }) => {
  try {
    const response = await GET_WITH_PARAMS(`/tls-company/company-used/${type}`, {
      pageSize,
      pageIndex,
    });
    return response;
  } catch (err) {
    throw new Error('new customer api error');
  }
};

function CurrentCustomer({ type }) {
  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
  });

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['newCustomer-noti', pagination.current, pagination.pageSize, type],
    queryFn: () =>
      getCurrentCustomerApi({
        pageSize: pagination.pageSize,
        pageIndex: pagination.current,
        type,
      }),
    select: (response) => response.result,
  });

  const handleTableChange = (newPagination) => {
    setPagination({
      ...newPagination,
    });
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Table
      dataSource={data?.data}
      columns={column()}
      loading={isLoading}
      pagination={{
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: data?.total || 0,
        onChange: (page, pageSize) => handleTableChange({ current: page, pageSize }),
      }}
    />
  );
}

export default CurrentCustomer;
