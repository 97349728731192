export const jobData = [
  {
    key: '1',
    package: 'FREE',
    jobTitle: 'พนักงานบัญชี',
    postDate: '2024-05-19',
    updateDate: '2024-05-18',
    requestPrDate: '2024-05-20',
    candidateIncrease: 20,
    status: 'online',
    promoteStatus: 'pending',
    candidate: 54,
  },
  {
    key: '2',
    package: 'BUY',
    jobTitle: 'Product Owner',
    postDate: '2024-04-10',
    updateDate: '2024-05-09',
    requestPrDate: '2024-05-20',
    candidateIncrease: 2,
    status: 'online',
    promoteStatus: 'pr1',
    candidate: 10,
  },
  {
    key: '22',
    package: 'BUY',
    jobTitle: 'โปรแกรมเมอร์ Owner',
    postDate: '2024-04-10',
    updateDate: '2024-05-09',
    requestPrDate: '2024-05-20',
    candidateIncrease: 2,
    status: 'online',
    promoteStatus: 'pr2',
    candidate: 10,
  },
  {
    key: '3',
    package: 'BUY',
    jobTitle: 'โปรแกรมเมอร์ iOS',
    postDate: '2024-04-08',
    updateDate: '2024-05-07',
    requestPrDate: '2024-05-20',
    candidateIncrease: 15,
    status: 'close',
    promoteStatus: 'pr2',
    candidate: 55,
  },
  {
    key: '4',
    package: 'FREE',
    jobTitle: 'Designer',
    postDate: '2023-12-14',
    updateDate: '2024-05-20',
    requestPrDate: '2024-05-20',
    candidateIncrease: 80,
    status: 'close',
    promoteStatus: 'pr2',
    candidate: 115,
  },
];
