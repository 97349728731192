import React from 'react';

export const quotationsColumn = () => {
  return [
    {
      title: 'เลขใบเสนอราคา',
      dataIndex: 'quotationNo',
      key: 'quotationNo',
    },
  ];
};
