import React from 'react';
import CompanyBadge from '../../../../components/shared/company-badge';
import { Descriptions, Flex } from 'antd';
import CheckStatus from '../../../../components/shared/check-status';
import StatusTag from '../../../../components/shared/status-tag';

function CompanyInfomation(props) {
  const { company, ...otherProps } = props;

  const items = [
    {
      key: '1',
      label: (
        <Flex justify="space-between" align="center">
          <>ชื่อบริษัท</>
        </Flex>
      ),
      children: company?.name || '-',
    },
    {
      key: '2',
      label: 'รหัสบริษัท',
      children: company?.caNumber || '-',
    },
    {
      key: '3',
      label: 'ประเภทธุรกิจ',
      children: otherProps?.businessType || '-',
    },
    {
      key: '4',
      label: 'จำนวนพนักงาน',
      children: otherProps?.headcountRange || '-',
    },
    {
      key: '5',
      label: 'ขนาดบริษัท',
      children: <StatusTag type="headcountRange" status={otherProps.headcountRangeCode} />,
    },
    {
      key: '6',
      label: 'สถานะบริษัท',
      children: <CompanyBadge status={company?.status} />,
    },
    {
      key: '7',
      label: 'สถานะการโพสต์งาน',
      children: <CheckStatus status={otherProps?.isPostAllowed} />,
    },
    {
      key: '8',
      label: 'แพ็กเกจล่าสุด',
      children: otherProps?.lastedPackage || '-',
    },
    {
      key: '9',
      label: 'จำนวนประกาศงาน',
      children: otherProps?.totalJobPost || '-',
    },
    {
      key: '10',
      label: <>อีเมลบริษัท</>,
      children: otherProps?.emailCompany?.companyEmail || '-',
      span: 2,
    },
  ];

  return <Descriptions items={items} column={{ xs: 1, sm: 2, md: 3 }} size="small" />;
}

export default CompanyInfomation;
