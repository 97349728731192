export const mockData = [
  {
    key: '1',
    companyName: 'JobFinFin',
    jobs: [
      {
        key: '1-1',
        jobTitle: 'UI/UX Designer วันงานเดียวจบ',
        contact: 'CS-เบนซ์',
        issueDate: '16 ม.ค. 2024',
        jobDate: '-',
        pr1: '10 คน',
        pr2: '-',
        status: 'pending',
      },
      {
        key: '1-2',
        jobTitle: 'หลักทางบัญชี',
        contact: 'CS-เบนซ์',
        issueDate: '15 ม.ค. 2024',
        jobDate: '-',
        pr1: '15 คน',
        pr2: '-',
        status: 'summary-1',
      },
      {
        key: '1-3',
        jobTitle: 'Customer Support (Sale)',
        contact: 'CS-เบนซ์',
        issueDate: '10 ม.ค. 2024',
        jobDate: '-',
        pr1: '14 คน',
        pr2: '-',
        status: 'success',
      },
    ],
  },
  {
    key: '2',
    companyName: 'GMM',
    jobs: [
      {
        key: '2-1',
        jobTitle: 'นักบัญชี หญิง 3 อัตรา',
        contact: 'CS-เบนซ์',
        issueDate: '8 ม.ค. 2024',
        jobDate: '-',
        pr1: '17 คน',
        pr2: '-',
        status: 'summary-1',
      },
      {
        key: '2-2',
        jobTitle: 'หลักทางบัญชี',
        contact: 'CS-เบนซ์',
        issueDate: '6 ม.ค. 2024',
        jobDate: '-',
        pr1: '24 คน',
        pr2: '55 คน',
        status: 'summary-1',
      },
    ],
  },
  {
    key: '3',
    companyName: 'Copperwired',
    jobs: [
      {
        key: '3-1',
        jobTitle: 'Developer',
        contact: 'CS-เบนซ์',
        issueDate: '16 ม.ค. 2024',
        jobDate: '-',
        pr1: '30 คน',
        pr2: '-',
        status: 'pending',
      },
      {
        key: '3-2',
        jobTitle: 'Accountant',
        contact: 'CS-เบนซ์',
        issueDate: '15 ม.ค. 2024',
        jobDate: '-',
        pr1: '28 คน',
        pr2: '-',
        status: 'pending',
      },
      {
        key: '3-3',
        jobTitle: 'Customer Support (Sale)',
        contact: 'CS-เบนซ์',
        issueDate: '10 ม.ค. 2024',
        jobDate: '-',
        pr1: '55 คน',
        pr2: '68 คน',
        status: 'summary-1',
      },
    ],
  },
  {
    key: '4',
    companyName: 'บจก. ทาไพแคป',
    jobs: [
      {
        key: '4-1',
        jobTitle: 'เจ้าหน้าที่ธุรการ',
        contact: 'CS-เบนซ์',
        issueDate: '1 ม.ค. 2024',
        jobDate: '-',
        pr1: '25 คน',
        pr2: '32 คน',
        status: 'success',
      },
      {
        key: '4-2',
        jobTitle: 'คนขับรถประจำสถาบัน',
        contact: 'CS-เบนซ์',
        issueDate: '10 ม.ค. 2024',
        jobDate: '-',
        pr1: '34 คน',
        pr2: '50 คน',
        status: 'success',
      },
    ],
  },
];
