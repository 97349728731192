import { UserOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import React from 'react';

export const contactColumn = ({ renderButton }) => {
  return [
    {
      title: 'ชื่อผู้ติดต่อ',
      dataIndex: 'name',
      key: 'name',
      render: (data, records) => (
        <Space>
          <Typography.Text>{data}</Typography.Text>
          {records?.isFavorite && <UserOutlined />}
        </Space>
      ),
    },
    {
      title: 'ตำแหน่ง',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'อีเมล',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '',
      key: 'action',
      render: (data, records) => renderButton(data, records),
    },
  ];
};
