import React from 'react';
import TableCustom from '../../../../../../screens/telesales/components/table-custom';
import { column, nestedColumns } from './constants/column';
import { mockData } from './constants/mockData';
import { Table } from 'antd';

function PromoteJob() {
  return (
    <div>
      <Table
        dataSource={mockData}
        columns={column()}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (record) => (
            <Table
              columns={nestedColumns()}
              dataSource={record.jobs}
              pagination={false}
              rowKey="jobTitle"
            />
          ),
          rowExpandable: (record) => record.jobs.length > 0,
        }}
      />
    </div>
  );
}

export default PromoteJob;
