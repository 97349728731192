import { Descriptions } from 'antd';
import React from 'react';
import { NoteContainer } from './styled-component';
import StatusTag from '../../../../components/shared/status-tag';

function OtherDetail(props) {
  const items = [
    {
      key: '1',
      label: 'Key Success',
      children: <StatusTag type="keySuccess" status={props?.keySuccess} /> || '-',
    },
    {
      key: '2',
      label: 'โอกาสในการซื้อ',
      children: props?.purchaseOccasion || '-',
    },
    {
      key: '3',
      label: 'Stage Auto',
      children: <StatusTag type="stageAuto" status={props?.stage} /> || '-',
    },
    {
      key: '4',
      label: 'Stage Manual',
      children: <StatusTag type="stageManual" status={props?.stageManual} /> || '-',
    },
    {
      key: '5',
      label: 'ใบเสนอราคา',
      children: props?.quotations || '-',
      span: 2,
    },
    {
      key: '6',
      label: 'หาตำแหน่งอะไรบ้าง',
      children: props?.findPosition || '-',
      span: 2,
    },
    {
      key: '7',
      label: 'รอบอนุมัติงบประมาณ',
      children: props?.budgetPeriod || '-',
      span: 2,
    },
    {
      key: '8',
      label: 'งบประมาณ',
      children: props?.budget || '-',
      span: 2,
    },
    {
      key: '9',
      label: 'โน้ตข้อความเพิ่มเติม',
      children: props?.lastNote ? <NoteContainer> {props?.lastNote}</NoteContainer> : '-',
      span: 2,
    },
  ];

  return <Descriptions items={items} column={2} size="small" />;
}

export default OtherDetail;
