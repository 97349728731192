import { useForm } from 'react-hook-form';
import { Yup } from '../../../../../../../../../../helpers/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isArrayLength } from '../../../../../../../../../../functions';

export const checkArrayOrEmpty = () => {
  return Yup.mixed().test((value) => {
    if (value === undefined) return true;
    if (value === '') return true;
    if (Array.isArray(value)) return true;
    return false;
  });
};

export const VALIDATION = Yup.object().shape({
  note: Yup.string().required(),
  keySuccess: Yup.string(),
  stageManual: Yup.string(),
  followUpDate: Yup.string(),
  purchaseOccasion: Yup.string(),
  knowUs: checkArrayOrEmpty(),
  totalCandidate: Yup.string(),
  positionReceived: Yup.string(),
  jobNeed: Yup.string(),
  avgJobpost: Yup.string(),
  avgSearch: Yup.string(),
  favPosition: Yup.string(),
  findPosition: checkArrayOrEmpty(),
  competitors: checkArrayOrEmpty(),
  budgetPeriod: Yup.string(),
  budget: Yup.string(),
  calledStatus: Yup.string().required(),
});

const useFormState = (defaultValues) => {
  const formProps = useForm({
    defaultValues: {
      note: '',
      keySuccess: '',
      stageManual: '',
      followUpDate: '',
      keySuccess: defaultValues?.keySuccess || '',
      stageManual: defaultValues?.stageManual || '',
      purchaseOccasion: defaultValues?.purchaseOccasion,
      knowUs: defaultValues?.knowUs,
      totalCandidate: defaultValues?.totalCandidate,
      positionReceived: defaultValues?.positionReceived,
      avgJobPost: defaultValues?.avgJobPost,
      avgSearch: defaultValues?.avgSearch,
      favPosition: defaultValues?.favPosition,
      findPosition: defaultValues?.findPosition || [],
      competitors: defaultValues?.competitors,
      budgetPeriod: defaultValues?.budgetPeriod,
      budget: defaultValues?.budget,
      quotations: isArrayLength(defaultValues?.quotations)
        ? defaultValues?.quotations.map((items) => items?.quotationNo)
        : [],
      additionalNote: defaultValues?.additionalNote,
      calledStatus: '',
    },
    resolver: yupResolver(VALIDATION),
  });
  return { formProps };
};

export default useFormState;
