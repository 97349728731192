import {
  OCCASION_LABEL,
  OCCASION_STATUS_COLOR,
} from '../../../../resources/constants/purchase-occasion';

export const getPurchaseOccasionStatus = (status) => {
  switch (status) {
    case 'high':
      return OCCASION_STATUS_COLOR.high;
    case 'medium':
      return OCCASION_STATUS_COLOR.medium;
    case 'low':
      return OCCASION_STATUS_COLOR.low;
    case 'high_potential':
      return OCCASION_STATUS_COLOR.high_potential;
    case 'sign':
      return OCCASION_STATUS_COLOR.sign;
    case 'done':
      return OCCASION_STATUS_COLOR.done;
    default:
      return null;
  }
};

export const getPurchaseOccasionLabel = (status) => {
  switch (status) {
    case 'high':
      return OCCASION_LABEL.high;
    case 'medium':
      return OCCASION_LABEL.medium;
    case 'low':
      return OCCASION_LABEL.low;
    default:
      return null;
  }
};
