import React, { useState } from 'react';
import Layout from '../../layout';
import { contactColumn } from './constants/columns';
import Button from '../../../../../../../components/button-antd';
import Table from '../../../../../../../components/table-antd';
import { useSalesContext } from '../../../contexts/sales-context/context';
import AddContactDrawer from './components/add-contact-drawer';
import { Popconfirm, Space } from 'antd';
import { DELETE, POST_CUSTOM } from '../../../../../../../services';
import { env } from '../../../../../../../utils/env/config';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  closeLoading,
  openAlert,
  openLoading,
} from '../../../../../../../components/alert/useAlert';
import EditContactDrawer from './components/edit-contact-drawer';
import { color } from '../../../../../../../resources';
import { CALL_STATUS } from '../../../contexts/sales-context/hooks';
import { PhoneOutlined } from '@ant-design/icons';

export const deleteContactAPI = async (contactId) => {
  try {
    const response = await DELETE(`/company-contact/delete-contact/${contactId}`);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

export const resetPasswordAPI = async (body) => {
  try {
    const response = await POST_CUSTOM(
      env.company_web,
      `/app/authen/user_company_forgot_password`,
      body,
    );
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

function ContactDetail() {
  const {
    selectedContact,
    setSelectedContact,
    callState,
    companyDetails,
    contactData,
    isContactLoading,
    handleClickCall,
  } = useSalesContext();

  const queryClient = useQueryClient();

  const [currentContact, setCurrentContact] = useState(null);

  const [openAddContactDrawer, setOpenAddContactDrawer] = useState(false);

  const showAddContactDrawer = () => setOpenAddContactDrawer(true);
  const closeAddContactDrawer = () => setOpenAddContactDrawer(false);

  const [openEditContactDrawer, setOpenEditContactDrawer] = useState(false);

  const showEditContactDrawer = (data) => {
    setOpenEditContactDrawer(true);
    setCurrentContact(data);
  };
  const closeEditContactDrawer = () => {
    setOpenEditContactDrawer(false);
    setCurrentContact(null);
  };

  const deleteContact = useMutation({
    mutationKey: 'deleteContact',
    mutationFn: (contactId) => deleteContactAPI(contactId),
    onSuccess: () => {
      openAlert({
        type: 'success',
        message: 'ลบผู้ติดต่อสำเร็จ',
      });
      queryClient.invalidateQueries(['contactCompany']);
    },
    onError: (err) => {
      console.log(err);
      openAlert({
        type: 'error',
        message: 'เกิดข้อผิดพลาด',
      });
    },
  });

  const resetPasswordCompany = useMutation({
    mutationKey: 'resetPasswordCompany',
    mutationFn: (email) => {
      openLoading();
      return resetPasswordAPI({ email: email });
    },
    onSuccess: () => {
      closeLoading();
      openAlert({
        type: 'success',
        message: 'ส่งเมลรีเซ็ตรหัสผ่านสำเร็จ',
      });
    },
    onError: () => {
      closeLoading();
      openAlert({
        type: 'error',
        message: 'เกิดข้อผิดพลาด',
      });
    },
  });

  const getCallButtonProps = (id) => {
    const isSelectedButton = id === selectedContact?.contactDetails?._id;

    if (callState.status === CALL_STATUS.PENDING) {
      if (isSelectedButton) {
        return {
          bgColor: color.green80,
          type: 'primary',
          children: 'โทร',
          icon: <PhoneOutlined style={{ transform: 'rotate(90deg)' }} />,
        };
      }

      return {
        disabled: true,
        children: 'โทร',
        icon: <PhoneOutlined style={{ transform: 'rotate(90deg)' }} />,
      };
    }

    if (callState.status === CALL_STATUS.CALLING) {
      if (isSelectedButton) {
        return {
          bgColor: color.green80,
          type: 'primary',
          children: 'โทร',
          icon: <PhoneOutlined style={{ transform: 'rotate(90deg)' }} />,
        };
      }

      return {
        disabled: true,
        children: 'โทร',
        icon: <PhoneOutlined style={{ transform: 'rotate(90deg)' }} />,
      };
    }

    return { children: 'โทร', icon: <PhoneOutlined style={{ transform: 'rotate(90deg)' }} /> };
  };

  return (
    <Layout>
      <Layout.Content>
        <Layout.Header>
          <Layout.Header.Title>ผู้ติดต่อ</Layout.Header.Title>
          <Button useFor="addContact" onClick={showAddContactDrawer} />
        </Layout.Header>
        <AddContactDrawer
          open={openAddContactDrawer}
          onClose={closeAddContactDrawer}
          companyId={companyDetails?._id}
        />

        <EditContactDrawer
          open={openEditContactDrawer}
          onClose={closeEditContactDrawer}
          currentContact={currentContact}
          updateSelectedContact={(updateContact) => {
            if (currentContact?._id === selectedContact?.contactDetails?._id) {
              setSelectedContact((prev) => ({
                ...prev,
                contactDetails: updateContact,
              }));
            }
          }}
        />
        <Table
          columns={contactColumn({
            renderButton: (_, rowData) => {
              return (
                <Space>
                  <Button
                    useFor="custom"
                    onClick={() => handleClickCall(rowData)}
                    {...getCallButtonProps(rowData?._id)}
                  />

                  <Button
                    useFor="editedContact"
                    isIcon
                    onClick={() => showEditContactDrawer(rowData)}
                  />
                  <Button
                    useFor="resetPassword"
                    disabled={!rowData?.email}
                    onClick={() => resetPasswordCompany.mutate(rowData?.email)}
                    isIcon
                  />
                  <Popconfirm
                    title="ลบผู้ติดต่อ"
                    description={`ต้องการลบผู้ติดต่อ ${rowData?.name} ?`}
                    onConfirm={() => {
                      deleteContact.mutate(rowData?._id);
                    }}
                    onCancel={() => {}}
                    okText="ใช่"
                    cancelText="ไม่"
                  >
                    <Button
                      useFor="removeContact"
                      isIcon
                      disabled={rowData?._id === selectedContact?.contactDetails?._id}
                    />
                  </Popconfirm>
                </Space>
              );
            },
          })}
          data={contactData}
          loading={isContactLoading}
        />
      </Layout.Content>
    </Layout>
  );
}

export default ContactDetail;
