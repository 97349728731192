import React from 'react';
import { Container, ScrollContainer } from './styled-component';
import { FixedButton } from '../../styled-component';
import OtherDetail from '../../../../components/other-detail';
import Button from '../../../../../../components/button-antd';

function Details({ companyDetails }) {
  return (
    <Container>
      <ScrollContainer>
        <OtherDetail {...companyDetails} />
      </ScrollContainer>
      <FixedButton>
        <Button useFor="edited" />
      </FixedButton>
    </Container>
  );
}

export default Details;
