import React from 'react';
import { MODAL_TYPES } from './constant';
import { useModalContext } from './context';
// import NoteModal from '../../views/modals/note-modal';
import ApplicantsModal from '../../components/applicants-modal';
import PackageItemModal from '../../components/package-item-modal';
import QuotationsModal from '../../components/quotations-modal';
import TrackingModal from '../../components/tracking-modal';
import SalesModal from '../../components/sales-modal';

export const ModalRenderer = () => {
  const { isOpen, modalType, modalProps, closeModal } = useModalContext();

  if (!isOpen || !modalType) return null;

  const baseProps = {
    open: isOpen,
    onClose: closeModal,
    ...modalProps,
  };

  const modalComponents = {
    [MODAL_TYPES.TRACKING]: TrackingModal,
    [MODAL_TYPES.SALES]: SalesModal,
    [MODAL_TYPES.PACKAGE_ITEMS]: PackageItemModal,
    [MODAL_TYPES.APPLICANT_COUNT]: ApplicantsModal,
    // [MODAL_TYPES.NOTE]: NoteModal,
    // [MODAL_TYPES.ADDITION_NOTE]: NoteModal,
    [MODAL_TYPES.QUOTATION]: QuotationsModal,
  };

  const ModalComponent = modalComponents[modalType];

  return ModalComponent ? <ModalComponent {...baseProps} /> : null;
};
