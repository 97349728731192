export const contactData = [
  {
    key: '1',
    name: 'นารัตน์ ยอดเกตุก์',
    position: 'HR',
    phone: '081-954-4848',
    email: 'HR.apitsada@gmail.com',
  },
  {
    key: '2',
    name: 'จอเจียรัย ลอนธิต',
    position: 'HR manager',
    phone: '081-954-2146',
    email: 'HR.apitsada@gmail.com',
  },
  {
    key: '3',
    name: 'อามิน เมาสี',
    position: 'Sale',
    phone: '081-954-7876',
    email: 'HR.apitsada@gmail.com',
  },
];
