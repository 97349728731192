export const manageHistoryColumn = () => {
  return [
    {
      title: 'วันที่',
      dataIndex: 'reportDate',
      key: 'reportDate',
    },
    {
      title: 'รายละเอียด',
      dataIndex: 'reportDetail',
      key: 'reportDetail',
    },
    {
      title: 'ประเภทเรื่อง',
      dataIndex: 'reportTitle',
      key: 'reportTitle',
    },
    {
      title: 'ผู้ดูแลเรื่อง',
      dataIndex: 'supervisor',
      key: 'supervisor',
    },
  ];
};
