import React from 'react';
import { TabTheme } from '../../../components/tabs';
import { tabRenderItems, tabRenderItemsFullAccess } from '../constants/tab';
import { useSelector } from 'react-redux';

function AssignTelesalesView() {
  const { user } = useSelector((state) => state.authen);
  const showTabByRole = user?.role === 'SUPER_ADMIN' ? tabRenderItemsFullAccess : tabRenderItems;

  return <TabTheme type="card" items={showTabByRole} destroyInactiveTabPane={true} />;
}

export default AssignTelesalesView;
