import React, { useEffect } from 'react';
import { column } from './constants/column';
import { Table } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { getErrorMsg } from '../../../../../../utils/api/msg';
import { GET } from '../../../../../../services';

const packageExpiringAPI = async () => {
  try {
    const response = await GET('/tls-company/package-low');
    return response;
  } catch (err) {
    getErrorMsg(err?.message);
    throw new Error('error');
  }
};

function PackageExpiring() {
  const {
    mutate: fetchPackageData,
    data,
    isLoading,
  } = useMutation({
    mutationKey: ['package-expiring'],
    mutationFn: () => packageExpiringAPI(),
  });

  useEffect(() => {
    fetchPackageData();
  }, []);

  const packageExpiringData = data?.result?.data || [];
  return (
    <div>
      <Table dataSource={packageExpiringData} loading={isLoading} columns={column()} />
    </div>
  );
}

export default PackageExpiring;
