import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styled-component';
import { Typography } from 'antd';

function Header(props) {
  const { children } = props;
  return <Container>{children}</Container>;
}

Header.Title = function Title({ children }) {
  return (
    <Typography.Title
      level={4}
      style={{
        margin: 0,
      }}
    >
      {children}
    </Typography.Title>
  );
};

Header.Description = function Description({ children }) {
  return <Typography.Text type="secondary">{children}</Typography.Text>;
};
Header.propTypes = {
  children: PropTypes.any,
};

export default Header;
