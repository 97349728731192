import React, { useState } from 'react';
import { column } from './constants/column';
import { mockData } from './constants/mockData';
import { Table } from 'antd';

function NewCustomer() {
  return (
    <div>
      <Table dataSource={mockData} columns={column()} />
    </div>
  );
}

export default NewCustomer;
