import { Space } from 'antd';
import React from 'react';
import LinkComponent from '../../link';
import { isArrayLength } from '../../../functions';

function QuotationText({ quotations }) {
  if (!isArrayLength(quotations)) return;

  return (
    <Space direction="vertical">
      {quotations.map((item) => (
        <LinkComponent key={`quotation-${item?.label}`} href={item?.url}>
          {item?.label}
        </LinkComponent>
      ))}
    </Space>
  );
}

export default QuotationText;
