export const historyCall = () => {
  return [
    {
      title: 'ชื่อผู้โทร',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'วัน/เวลา ที่โทร',
      dataIndex: 'callDate',
      key: 'callDate',
    },
    {
      title: 'เวลาที่ใช้โทร',
      dataIndex: 'callDuration',
      key: 'callDuration',
    },
    {
      title: 'โน๊ต',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'ชื่อผู้ติดต่อ',
      dataIndex: 'contactName',
      key: 'contactName',
    },
    {
      title: 'เบอร์โทร',
      dataIndex: 'tel',
      key: 'tel',
    },
  ];
};
