import React from 'react';
import { Icon } from '../../../../resources';
import { PageLayout } from '../../../../components/page-layout/PageLayout';
import { FORM_SEARCH } from './constants/form';
import { useOptions } from '../../../../hooks/useOptions';
import { useRole } from '../../../../hooks/useRole';

function SearchInput(props) {
  const { onSearch, isLoading } = props;

  const { isCS, isPR, isTelesale } = useRole();

  const getTitle = () => {
    if (isTelesale) {
      return 'Sales';
    }
    if (isPR) {
      return 'PR';
    }
    if (isCS) {
      return 'Customer service';
    }
  };
  const { options } = useOptions();
  const onSubmit = (formValues) => {
    if (isLoading) {
      return;
    }

    const values = {
      caNumber: formValues?.caNumber || '',
      name: formValues?.name ? formValues?.name.trim() : '',
      keySuccess: formValues?.keySuccess || '',
      stageManual: formValues?.stageManual || '',
      stageAuto: formValues?.stageAuto || '',
      purchaseOccasion: formValues?.purchaseOccasion || '',
      startDate: formValues?.lastedCall?.[0] || '',
      endDate: formValues?.lastedCall?.[1] || '',
      salesName: formValues?.salesName || '',
      followUpStartDate: formValues?.followUpDate?.[0] || '',
      followUpEndDate: formValues?.followUpDate?.[1] || '',
      calledStatus: formValues?.calledStatus || '',
      headCount: formValues?.headCount || '',
      telNo: formValues?.telNo || '',
      email: formValues?.email || '',
    };

    onSearch(values);
  };
  return (
    <PageLayout
      searchLayout={{
        title: getTitle(),
        isEnableEnterSubmit: true,
        formSearch: FORM_SEARCH({ options }),
        icon: <Icon.todo />,
        onClearCustom: () => onSubmit(),
        onSearch: (formValues) => {
          onSubmit(formValues);
        },
      }}
    />
  );
}

export default SearchInput;
