import React from 'react';
import { ModalAntd } from './styled-component';
import PropTypes from 'prop-types';

export const ModalTheme = (props) => {
  const { open, title, onClose, children, width, fullscreen, ...modalProps } = props;

  return (
    <ModalAntd
      open={open}
      onOk={onClose}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose={true}
      title={title || 'Basic Modal'}
      footer={null}
      width={fullscreen ? '100%' : width}
      fullscreen={fullscreen}
      {...modalProps}
    >
      {children}
    </ModalAntd>
  );
};

ModalTheme.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.any,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullscreen: PropTypes.bool,
};

ModalTheme.defaultProps = {
  open: false,
  fullscreen: false,
  title: 'title modal',
  onClose: () => {},
  children: null,
  width: '520px',
};
