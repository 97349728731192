export const color = {
  theme: '#1877F2',
  // linear-gradient(#e66465, #9198e5);
  edit: '#ff7b54',
  submit: '#7eca9c',
  clear: '#707070',
  remove: '#d35d6e',
  search: '#1890ff',
  red80: '#ffe9e9',
  red: '#FF0000',
  redStatus: '#F12E22',
  logout: '#737373',

  yellow: '#F4D35E',
  yellowStatus: '#FFCB14',

  grey80: '#f2fff8',
  grey90: '#f2f2f2',

  grey200: '#CBCBCB',
  grey900: '#343A40',
  grey100: '#414141',
  grey400: '#A0A0A0',
  grey500: '#6d6d6d',
  grey10: '#d9d9d9',
  grey25: '#F5F5F5',

  blue80: '#4857DC',
  blue100: '#2F3592',
  oldblue: '#1877F2',

  green80: '#0FAB58',
  warningGreen: '#25AC64',

  addBg: '#f6ffed',
  removeBg: '#fff1f0',

  scrollbarTrack: '#EDEFF5',
  scrollbarTrackHover: '#E0E0E0',
  scrollbarTrackActive: '#CBCBCB',
  scrollbarThumb: '#A9A9A9',
  scrollbarThumbHover: '#8B8B8B',
  scrollbarThumbActive: '#6B7280',
};
