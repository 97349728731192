import React from 'react';
import QuotationText from '../../../../../../../components/shared/quotation-text';
import { DateFormat } from '../../../../../../../components/shared/format-text';
import CompanyProfileLink from '../../../../../../../components/shared/company-profile-link';
import { numberComma } from '../../../../../../../utils/number';
import { Typography } from 'antd';

export const column = ({ isPrimaryState }) => {
  if (isPrimaryState) {
    return [
      {
        title: 'ชื่อบริษัท',
        dataIndex: 'name',
        key: 'name',
        render: (label, records) => (
          <CompanyProfileLink label={label} authorId={records?.authorId} />
        ),
      },
      {
        title: 'รหัสบริษัท',
        dataIndex: 'caNumber',
        key: 'caNumber',
      },

      {
        title: 'ประเภทธุรกิจ',
        dataIndex: 'businessType',
        key: 'businessType',
      },
      {
        title: 'Follow up Date',
        dataIndex: 'followUpDate',
        key: 'followUpDate',
        render: (date) => <DateFormat date={date} />,
      },
      {
        title: 'โทรล่าสุด',
        dataIndex: 'lastedCallDate',
        key: 'lastedCallDate',
        render: (date) => <DateFormat date={date} />,
      },
      {
        title: 'โน้ตล่าสุด',
        dataIndex: 'lastedNote',
        key: 'lastedNote',
      },
    ];
  }
  return [
    {
      title: 'ชื่อบริษัท',
      dataIndex: 'name',
      key: 'name',
      render: (label, records) => <CompanyProfileLink label={label} authorId={records?.authorId} />,
    },
    {
      title: 'รหัสบริษัท',
      dataIndex: 'caNumber',
      key: 'caNumber',
    },

    {
      title: 'ประเภทธุรกิจ',
      dataIndex: 'businessType',
      key: 'businessType',
    },
    {
      title: 'วันที่เริ่มใช้งาน',
      dataIndex: 'startedPackage',
      key: 'startedPackage',
      render: (date) => <DateFormat date={date} />,
    },
    {
      title: 'แพ็กเกจที่ใช้ล่าสุด',
      dataIndex: 'packageLasted',
      key: 'packageLasted',
    },
    {
      title: 'โน้ตล่าสุด',
      dataIndex: 'lastedNote',
      key: 'lastedNote',
      align: 'left',
    },
    {
      title: 'ใบเสนอราคา',
      dataIndex: 'quotationNo',
      key: 'quotationNo',
      render: (data) => <QuotationText quotations={data} />,
    },
    {
      title: 'ยอดซื้อ (บาท)',
      dataIndex: 'totalPurchase',
      key: 'totalPurchase',
      render: (data) => <Typography.Text>{numberComma(data)}</Typography.Text>,
    },
  ];
};
