import React, { Fragment } from 'react';
import { useController } from 'react-hook-form';
import { ConfigProvider, DatePicker } from 'antd';
import { Required, RowComponent } from '../../../styles/global-style';
import { TextXSMall, TextInputLabel } from '../../text/index.jsx';
import thTh from 'antd/locale/th_TH';
import th from 'dayjs/locale/th'; /**NOTE: ทำให้ datepicker แสดงเดือนภาษาไทย มันไม่ต้องใช้เป็น props แค่ประกาศก็ใช้ได้เลย */
import { renderTypeError } from '../index.jsx';
import dayjs from 'dayjs';
import { isArray } from 'lodash';

const DatePickerComponent = ({ control, item, handleChange, setValue }) => {
  const { rules, name, defaultValue, label, disabled, dataCy, ...propsInput } = item;

  // console.log('th', th);

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const onChange = (e) => {
    setValue(name, null);
    handleChange(e, item);
  };

  if (item?.picker === 'month') {
    return (
      <Fragment>
        <RowComponent>
          {label && <TextInputLabel text={label} />}
          {rules && rules.required && <Required>*</Required>}
        </RowComponent>
        <ConfigProvider locale={thTh}>
          <DatePicker
            id={name}
            style={{ width: '100%' }}
            onChange={(e) => onChange(e)}
            defaultValue={value ? dayjs(value, item.format || 'DD/MM/YYYY') : null}
            {...dataCy}
            {...propsInput}
          />
        </ConfigProvider>
        {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <ConfigProvider locale={thTh}>
        <DatePicker
          id={name}
          style={{ width: '100%' }}
          format={item.format || 'DD/MM/YYYY'}
          allowClear={true}
          disabled={disabled || false}
          disabledDate={item.disabledDate}
          defaultValue={value ? dayjs(value, item.format || 'DD/MM/YYYY') : null}
          onChange={(e) => onChange(e)}
          {...dataCy}
          {...propsInput}
        />
      </ConfigProvider>
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedDatePicker = DatePickerComponent; //memo(, compareRender);

const DateRangeComponent = ({ control, item, handleChange, setValue }) => {
  const { rules, name, defaultValue, label, disabled, ...propsInput } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const onChange = (e) => {
    setValue(name, e);
    handleChange(e, item);
  };

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <ConfigProvider locale={thTh}>
        <DatePicker.RangePicker
          id={name}
          style={{ width: '100%' }}
          format={item?.format || 'DD/MM/YYYY'}
          allowClear={true}
          allowEmpty={true}
          disabled={disabled || false}
          onChange={(e) => onChange(e)}
          value={
            isArray(value)
              ? [
                  dayjs(value[0], item.format || 'DD/MM/YYYY'),
                  dayjs(value[1], item.format || 'DD/MM/YYYY'),
                ]
              : undefined
          }
          {...propsInput}
        />
      </ConfigProvider>
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedDateRange = DateRangeComponent;
