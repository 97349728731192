import { Yup } from '../../../../../../../../../helpers/yup';

export const FORM = () => {
  return [
    {
      name: 'JobThai',
      label: 'JobThai',
      type: 'INPUT',
      placeholder: 'https://www.jobthai.com/th/company/12345',
      span: 24,
    },
    {
      name: 'JobBkk',
      label: 'JobBkk',
      type: 'INPUT',
      placeholder: 'https://www.jobbkk.com/jobs/profile/12345',
      span: 24,
    },
    {
      name: 'JobsDB',
      label: 'JobsDB',
      type: 'INPUT',
      placeholder: 'https://th.jobsdb.com/companies/examplecompany-1234567890/jobs',
      span: 24,
    },
    {
      name: 'JobTopGun',
      label: 'JobTopGun',
      type: 'INPUT',
      placeholder: 'https://www.jobtopgun.com/th/company/12345/jobs',
      span: 24,
    },
  ];
};

export const VALIDATION = Yup.object().shape({
  jobThai: Yup.string(),
  JobBkk: Yup.string(),
  JobsDB: Yup.string(),
  JobTopGun: Yup.string(),
});
