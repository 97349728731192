import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { column } from './constants/column';
import { useMutation } from '@tanstack/react-query';
import { GET } from '../../../../../../services';
import { getErrorMsg } from '../../../../../../utils/api/msg';
import Table from '../../../../../../components/table-antd';

const opportunitiesAPI = async (type) => {
  try {
    const response = await GET(`/tls-company/purchase-occasion/${type}`);
    return response;
  } catch (err) {
    getErrorMsg(err?.message);
    throw new Error('error');
  }
};

function Opportunities(props) {
  const { isPrimaryState, type } = props;

  const {
    mutate: fetchOpportunityData,
    data,
    isLoading,
  } = useMutation({
    mutationKey: ['opportunities', type],
    mutationFn: () => opportunitiesAPI(type),
  });

  useEffect(() => {
    fetchOpportunityData();
  }, []);

  return (
    <Table data={data?.result?.data} loading={isLoading} columns={column({ isPrimaryState })} />
  );
}

Opportunities.propTypes = {
  isPrimaryState: PropTypes.bool,
  type: PropTypes.string,
};

Opportunities.defaultProps = {
  isPrimaryState: false,
  type: '',
};

export default Opportunities;
