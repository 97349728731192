import React from 'react';
import { Row } from 'antd';
import Card from './components/card';
import { useModalContext } from '../../contexts/modal-context/context';
import { useQuery } from '@tanstack/react-query';
import { totalNotiAPI } from './api';
import { numberComma } from '../../../../utils/number';
import { useRole } from '../../../../hooks/useRole';

function DashboardSummary() {
  const { modalEvent } = useModalContext();
  const { isCS, isPR, isTelesale, isSuperAdmin } = useRole();

  const { data } = useQuery({
    queryKey: ['dashboardData'],
    queryFn: () => totalNotiAPI(),
  });

  const dashboardData = data?.result?.data;
  const getBoxs = () => {
    if (isCS) {
      return [
        {
          id: 'support-customer',
          title: 'เรื่องที่ลูกค้าต้องการให้ช่วยเหลือ',
          text1: {
            total: numberComma(dashboardData?.request?.pending),
            unit: 'รอดำเนินการ',
            color: 'red',
          },
          text2: {
            total: numberComma(dashboardData?.request?.done),
            unit: 'ดำเนินการแล้ว',
            color: '#0FAB58',
          },
          onClick: () => handleOpenModal('support-customer'),
          gridColumn: 12,
        },
        {
          id: 'pr-job',
          title: 'การ PR งาน',
          text1: { total: numberComma(dashboardData?.pr?.pending), unit: 'รอการ PR', color: 'red' },
          text2: { total: numberComma(dashboardData?.pr?.done), unit: 'PR แล้ว', color: '#0FAB58' },
          onClick: () => handleOpenModal('pr-job'),
          gridColumn: 12,
        },
        {
          id: 'new-customer',
          title: 'ลูกค้าใหม่ ',
          text1: {
            total: numberComma(dashboardData?.newRegister),
            unit: 'บริษัท',
            color: '#4857DC',
          },
          onClick: () => handleOpenModal('new-customer'),
          gridColumn: 8,
        },
        {
          id: 'usage-2-weeks',
          title: 'ใช้มาแล้ว 14 วัน',
          text1: {
            total: numberComma(dashboardData?.twoWeeksUsage),
            unit: 'บริษัท',
            color: '#4857DC',
          },
          onClick: () => handleOpenModal('usage-2-weeks'),
          gridColumn: 8,
        },
        {
          id: 'usage-1-month',
          title: 'ใช้มาแล้ว 30 วัน',
          text1: {
            total: numberComma(dashboardData?.oneMonthUsage),
            unit: 'บริษัท',
            color: '#4857DC',
          },
          onClick: () => handleOpenModal('usage-1-month'),
          gridColumn: 8,
        },
        {
          id: 'usage-3-months',
          title: 'ใช้มาแล้ว 3 เดือน',
          text1: {
            total: numberComma(dashboardData?.threeMonthsUsage),
            unit: 'บริษัท',
            color: '#4857DC',
          },
          onClick: () => handleOpenModal('usage-3-months'),
          gridColumn: 8,
        },
        {
          id: 'usage-6-months',
          title: 'ใช้มาแล้ว 6 เดือน',
          text1: {
            total: numberComma(dashboardData?.sixMonthsUsage),
            unit: 'บริษัท',
            color: '#4857DC',
          },
          onClick: () => handleOpenModal('usage-6-months'),
          gridColumn: 8,
        },
        {
          id: 'usage-9-months',
          title: 'ใช้มาแล้ว 9 เดือน',
          text1: {
            total: numberComma(dashboardData?.nineMonthsUsage),
            unit: 'บริษัท',
            color: '#4857DC',
          },
          onClick: () => handleOpenModal('usage-9-months'),
          gridColumn: 8,
        },
      ];
    }
    if (isPR) {
      return [
        {
          id: 'pr-job',
          title: 'การ PR งาน',
          text1: { total: 5, unit: 'รอการ PR', color: 'red' },
          text2: { total: 2, unit: 'PR แล้ว', color: '#0FAB58' },
          onClick: () => handleOpenModal('pr-job'),
          gridColumn: 24,
        },
      ];
    }
    if (isTelesale) {
      return [
        {
          id: 'support-customer',
          title: 'เรื่องที่ลูกค้าต้องการให้ช่วยเหลือ',
          text1: {
            total: numberComma(dashboardData?.request?.pending),
            unit: 'รอดำเนินการ',
            color: 'red',
          },
          text2: {
            total: numberComma(dashboardData?.request?.done),
            unit: 'ดำเนินการแล้ว',
            color: '#0FAB58',
          },
          onClick: () => handleOpenModal('support-customer'),
          gridColumn: 12,
        },
        {
          id: 'pr-job',
          title: 'การ PR งาน',
          text1: { total: numberComma(dashboardData?.pr?.pending), unit: 'รอการ PR', color: 'red' },
          text2: { total: numberComma(dashboardData?.pr?.done), unit: 'PR แล้ว', color: '#0FAB58' },
          onClick: () => handleOpenModal('pr-job'),
          gridColumn: 12,
        },
        {
          id: 'package-less-2-m',
          title: 'แพ็กเกจที่เหลือต่ำกว่า 2 เดือน',
          text1: { total: numberComma(dashboardData?.packages), unit: 'บริษัท', color: '#4857DC' },
          onClick: () => handleOpenModal('package-less-2-m'),
          gridColumn: 8,
        },
        {
          id: 'opportunities-25',
          title: 'โอกาสในการซื้อ',
          hightlightTitle: '25% (คุยแล้ว)',
          text1: { total: numberComma(dashboardData?.medium), unit: 'บริษัท', color: '#4857DC' },
          onClick: () => handleOpenModal('opportunities-25'),
          gridColumn: 8,
        },
        {
          id: 'opportunities-50',
          title: 'โอกาสในการซื้อ',
          hightlightTitle: '50% (เสนอราคา)',
          text1: { total: numberComma(dashboardData?.high), unit: 'บริษัท', color: '#4857DC' },
          onClick: () => handleOpenModal('opportunities-50'),
          gridColumn: 8,
        },
        {
          id: 'opportunities-75',
          title: 'โอกาสในการซื้อ',
          hightlightTitle: '75% (High Potential)',
          text1: {
            total: numberComma(dashboardData?.highPotential),
            unit: 'บริษัท',
            color: '#4857DC',
          },
          onClick: () => handleOpenModal('opportunities-75'),
          gridColumn: 8,
        },
        {
          id: 'opportunities-95',
          title: 'โอกาสในการซื้อ',
          hightlightTitle: '95% (Signed)',
          text1: { total: numberComma(dashboardData?.sign), unit: 'บริษัท', color: '#4857DC' },
          onClick: () => handleOpenModal('opportunities-95'),
          gridColumn: 8,
        },
        {
          id: 'opportunities-100',
          title: 'โอกาสในการซื้อ',
          hightlightTitle: '100% (ปิดการขาย)',
          text1: { total: numberComma(dashboardData?.done), unit: 'บริษัท', color: '#4857DC' },
          onClick: () => handleOpenModal('opportunities-100'),
          gridColumn: 8,
        },
      ];
    }

    if (isSuperAdmin) {
      return [
        {
          id: 'support-customer',
          title: 'เรื่องที่ลูกค้าต้องการให้ช่วยเหลือ',
          text1: {
            total: numberComma(dashboardData?.request?.pending),
            unit: 'รอดำเนินการ',
            color: 'red',
          },
          text2: {
            total: numberComma(dashboardData?.request?.done),
            unit: 'ดำเนินการแล้ว',
            color: '#0FAB58',
          },
          onClick: () => handleOpenModal('support-customer'),
          gridColumn: 12,
        },
        {
          id: 'pr-job',
          title: 'การ PR งาน',
          text1: { total: numberComma(dashboardData?.pr?.pending), unit: 'รอการ PR', color: 'red' },
          text2: { total: numberComma(dashboardData?.pr?.done), unit: 'PR แล้ว', color: '#0FAB58' },
          onClick: () => handleOpenModal('pr-job'),
          gridColumn: 12,
        },
        {
          id: 'new-customer',
          title: 'ลูกค้าใหม่ ',
          text1: {
            total: numberComma(dashboardData?.newRegister),
            unit: 'บริษัท',
            color: '#4857DC',
          },
          onClick: () => handleOpenModal('new-customer'),
          gridColumn: 8,
        },
        {
          id: 'usage-2-weeks',
          title: 'ใช้มาแล้ว 14 วัน',
          text1: {
            total: numberComma(dashboardData?.twoWeeksUsage),
            unit: 'บริษัท',
            color: '#4857DC',
          },
          onClick: () => handleOpenModal('usage-2-weeks'),
          gridColumn: 8,
        },
        {
          id: 'usage-1-month',
          title: 'ใช้มาแล้ว 30 วัน',
          text1: {
            total: numberComma(dashboardData?.oneMonthUsage),
            unit: 'บริษัท',
            color: '#4857DC',
          },
          onClick: () => handleOpenModal('usage-1-month'),
          gridColumn: 8,
        },
        {
          id: 'usage-3-months',
          title: 'ใช้มาแล้ว 3 เดือน',
          text1: {
            total: numberComma(dashboardData?.threeMonthsUsage),
            unit: 'บริษัท',
            color: '#4857DC',
          },
          onClick: () => handleOpenModal('usage-3-months'),
          gridColumn: 8,
        },
        {
          id: 'usage-6-months',
          title: 'ใช้มาแล้ว 6 เดือน',
          text1: {
            total: numberComma(dashboardData?.sixMonthsUsage),
            unit: 'บริษัท',
            color: '#4857DC',
          },
          onClick: () => handleOpenModal('usage-6-months'),
          gridColumn: 8,
        },
        {
          id: 'usage-9-months',
          title: 'ใช้มาแล้ว 9 เดือน',
          text1: {
            total: numberComma(dashboardData?.nineMonthsUsage),
            unit: 'บริษัท',
            color: '#4857DC',
          },
          onClick: () => handleOpenModal('usage-9-months'),
          gridColumn: 8,
        },
        {
          id: 'package-less-2-m',
          title: 'แพ็กเกจที่เหลือต่ำกว่า 2 เดือน',
          text1: { total: numberComma(dashboardData?.packages), unit: 'บริษัท', color: '#4857DC' },
          onClick: () => handleOpenModal('package-less-2-m'),
          gridColumn: 8,
        },
        {
          id: 'opportunities-25',
          title: 'โอกาสในการซื้อ',
          hightlightTitle: '25% (คุยแล้ว)',
          text1: { total: numberComma(dashboardData?.medium), unit: 'บริษัท', color: '#4857DC' },
          onClick: () => handleOpenModal('opportunities-25'),
          gridColumn: 8,
        },
        {
          id: 'opportunities-50',
          title: 'โอกาสในการซื้อ',
          hightlightTitle: '50% (เสนอราคา)',
          text1: { total: numberComma(dashboardData?.high), unit: 'บริษัท', color: '#4857DC' },
          onClick: () => handleOpenModal('opportunities-50'),
          gridColumn: 8,
        },
        {
          id: 'opportunities-75',
          title: 'โอกาสในการซื้อ',
          hightlightTitle: '75% (High Potential)',
          text1: {
            total: numberComma(dashboardData?.highPotential),
            unit: 'บริษัท',
            color: '#4857DC',
          },
          onClick: () => handleOpenModal('opportunities-75'),
          gridColumn: 8,
        },
        {
          id: 'opportunities-95',
          title: 'โอกาสในการซื้อ',
          hightlightTitle: '95% (Signed)',
          text1: { total: numberComma(dashboardData?.sign), unit: 'บริษัท', color: '#4857DC' },
          onClick: () => handleOpenModal('opportunities-95'),
          gridColumn: 8,
        },
        {
          id: 'opportunities-100',
          title: 'โอกาสในการซื้อ',
          hightlightTitle: '100% (ปิดการขาย)',
          text1: { total: numberComma(dashboardData?.done), unit: 'บริษัท', color: '#4857DC' },
          onClick: () => handleOpenModal('opportunities-100'),
          gridColumn: 8,
        },
      ];
    }

    return [];
  };

  const handleOpenModal = (defaultTab) => {
    modalEvent.openTrackingModal({ defaultTab, tabKeys: getBoxs().map((item) => item.id) });
  };
  return (
    <Row gutter={[16, 16]} style={{ padding: '24px' }}>
      {getBoxs().map((item, index) => (
        <Card id="sale-card" key={`sale-card-${index}`} {...item} />
      ))}
    </Row>
  );
}

export default DashboardSummary;
