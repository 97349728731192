import { useState, useEffect } from 'react';

const useSelectItems = ({ defaultValues, mappingData, selectValueKey, open }) => {
  const [userList, setUserList] = useState([]);
  const [selectItems, setSelectItems] = useState([]);
  const [removeItems, setRemoveItems] = useState([]);

  useEffect(() => {
    if (open) {
      setUserList(defaultValues);
      setSelectItems([]);
      setRemoveItems([]);
    }
  }, [open]);

  const formatValues = (valuesObj) =>
    valuesObj.map((item) => ({
      ...mappingData(item),
      status: 'added',
    }));

  const sortList = (values) =>
    values.sort((a, b) => {
      if (a.status === 'added' && b.status !== 'added') return -1;
      if (a.status !== 'added' && b.status === 'added') return 1;
      if (a.status === 'removed' && b.status !== 'removed') return -1;
      if (a.status !== 'removed' && b.status === 'removed') return 1;
      return 0;
    });

  const onSelect = (_, valuesObj) => {
    const formattedValues = formatValues(valuesObj);
    const removedFromSelect = selectItems.filter(
      (item) => !valuesObj.some((newUser) => newUser.id === item.id),
    );

    const updatedList = userList
      .filter((user) => !removedFromSelect.some((removedUser) => removedUser.id === user.id))
      .filter((user) => !formattedValues.some((newUser) => newUser.id === user.id))
      .concat(formattedValues);

    setSelectItems(formattedValues);
    setUserList(sortList(updatedList));
  };

  const onDelete = (value) => {
    if (value.status === 'added') {
      const updatedSelectItems = selectItems.filter((item) => item.id !== value.id);
      setSelectItems(updatedSelectItems);

      const updatedList = userList.filter((user) => user.id !== value.id);
      setUserList(sortList(updatedList));
    } else {
      const alreadyRemoved = removeItems.some((item) => item.id === value.id);

      if (!alreadyRemoved) {
        const updatedList = userList.map((user) =>
          user.id === value.id ? { ...user, status: 'removed' } : user,
        );

        setRemoveItems([...removeItems, { ...value, status: 'removed' }]);
        setUserList(sortList(updatedList));
      } else {
        const updatedList = userList.map((user) =>
          user.id === value.id ? { ...user, status: null } : user,
        );

        const updatedRemoveItems = removeItems.filter((item) => item.id !== value.id);
        setRemoveItems(updatedRemoveItems);
        setUserList(sortList(updatedList));
      }
    }
  };

  return {
    userList,
    isEdited: selectItems.length > 0 || removeItems.length > 0,
    selectValues: selectItems.map((i) => i?.[selectValueKey]) || [],
    onSelect,
    onDelete,
  };
};

export default useSelectItems;
