import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styled-component';
import Header from './views/header';
import Content from './views/Content';

function Layout(props) {
  const { children } = props;

  return <Container>{children}</Container>;
}

Layout.Header = Header;
Layout.Content = Content;

Layout.propTypes = {
  children: PropTypes.any,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
