import React, { useState } from 'react';
import Layout from '../../layout';
import { Divider, Popconfirm, Space } from 'antd';
import Total from './components/total';
import Button from '../../../../../../../components/button-antd';
import Table from '../../../../../../../components/table-antd';
import CompareDate from './components/compare-date';
import { jobData, mockData } from './mock';
import { jobPosition, promoteJobColumn } from './constants/columns';

function JobPosition() {
  const [selectedRows, setSelectedRows] = useState();

  console.log(selectedRows);

  const isMultipleSelected = !selectedRows || selectedRows.length === 0;

  return (
    <Layout>
      <Layout.Header>
        <Layout.Header.Title>ตำแหน่งงาน</Layout.Header.Title>
        <Space size={[8, 8]} direction="vertical" align="end">
          <Total />
          <Space size={[8, 16]} block>
            <CompareDate />
            <Popconfirm
              icon={null}
              title="คุณต้องการแจ้ง PR งานหรือไม่?"
              okText="ใช่"
              cancelText="ไม่"
            >
              <Button useFor="promote" disabled={isMultipleSelected} />
            </Popconfirm>
          </Space>
        </Space>
      </Layout.Header>

      <Table
        columns={jobPosition({ isMultipleSelected })}
        data={jobData}
        getSelectedRows={setSelectedRows}
      />

      <Divider />

      <Table rowKey="id" columns={promoteJobColumn()} dataSource={mockData} />
    </Layout>
  );
}

export default JobPosition;
