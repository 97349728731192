import React from 'react';
import { CardTheme } from '../card/index.jsx';
import TableComponent from './../table/index.jsx';

export const TableLayout = ({ props }) => {
  const {
    style,
    columns,
    dataSource,
    rowSelection,
    extraContent,
    hasPagination,
    rowKey,
    components,
    cardProps,
    ...rest
  } = props;
  return (
    <CardTheme
      type={cardProps?.type || 'card'}
      style={{ marginTop: '4%', ...style }}
      content={
        <>
          {extraContent}
          <TableComponent
            rowKey={rowKey}
            columns={columns}
            dataSource={dataSource}
            hasPagination={hasPagination || false}
            rowSelection={rowSelection}
            components={components}
            {...rest}
          />
        </>
      }
      {...cardProps}
    />
  );
};
