import React from 'react';
import PropTypes from 'prop-types';
import SupportCustomer from './views/support-customer';
import PackageExpiring from './views/package-expiring';
import Opportunities from './views/opportunities';
import PromoteJob from './views/promote-job';
import { TabStyled } from './styled-component';
import { ModalTheme } from '../../../../components/modal';
import { useRole } from '../../../../hooks/useRole';
import NewCustomer from './views/new-customer';
import CurrentCustomer from './views/current-customer';

function TrackingModal(props) {
  const { defaultTab, ...modalProps } = props;

  const { isCS, isPR, isTelesale } = useRole();

  const getTabs = () => {
    if (isTelesale) {
      return [
        {
          label: <span>ช่วยเหลือลูกค้า</span>,
          key: 'support-customer',
          children: <SupportCustomer />,
        },
        { label: 'การ PR งาน', key: 'pr-job', children: <PromoteJob /> },
        {
          label: 'แพ็กเกจ <2 เดือน',
          key: 'package-less-2-m',
          children: <PackageExpiring />,
        },
        {
          label: 'โอกาสในการซื้อ 25%',
          key: 'opportunities-25',
          children: <Opportunities isPrimaryState type="medium" />,
        },
        {
          label: 'โอกาสในการซื้อ 50%',
          key: 'opportunities-50',
          children: <Opportunities type="high" />,
        },
        {
          label: 'โอกาสในการซื้อ 75%',
          key: 'opportunities-75',
          children: <Opportunities type="high-potential" />,
        },
        {
          label: 'โอกาสในการซื้อ 95%',
          key: 'opportunities-95',
          children: <Opportunities type="sign" />,
        },
        { label: 'ปิดการขาย', key: 'opportunities-100', children: <Opportunities type="done" /> },
      ];
    }

    if (isCS) {
      return [
        {
          label: <span>ช่วยเหลือลูกค้า</span>,
          key: 'support-customer',
          children: <SupportCustomer />,
        },
        { label: 'การ PR งาน', key: 'pr-job', children: <PromoteJob /> },
        { label: 'ลูกค้าใหม่', key: 'new-customer', children: <NewCustomer /> },
        {
          label: '14 วัน',
          key: 'usage-2-weeks',
          children: <CurrentCustomer type="two-weeks-usage" />,
        },
        {
          label: '30 วัน ',
          key: 'usage-1-month',
          children: <CurrentCustomer type="one-month-usage" />,
        },
        {
          label: '3 เดือน ',
          key: 'usage-3-month',
          children: <CurrentCustomer type="three-months-usage" />,
        },
        {
          label: '6 เดือน',
          key: 'usage-6-months',
          children: <CurrentCustomer type="six-months-usage" />,
        },
        {
          label: '9 เดือน ',
          key: 'usage-9-months',
          children: <CurrentCustomer type="nine-months-usage" />,
        },
      ];
    }

    if (isPR) {
      return [
        {
          label: <span>ช่วยเหลือลูกค้า</span>,
          key: 'support-customer',
          children: <SupportCustomer />,
        },
        { label: 'การ PR งาน', key: 'pr-job', children: <PromoteJob /> },
      ];
    }
    return [];
  };

  return (
    <ModalTheme width={1840} title="การติดตาม" id="tracking-modal-sales" {...modalProps}>
      <TabStyled
        type="card"
        destroyInactiveTabPane
        defaultActiveKey={defaultTab}
        items={getTabs()}
      />
    </ModalTheme>
  );
}

TrackingModal.propTypes = {
  defaultTab: PropTypes.string,
};

TrackingModal.defaultProps = {
  defaultTab: '',
};

export default TrackingModal;
