import styled from 'styled-components';
import { color } from '../../../../../../../../resources';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #f9f9f9;
`;

export const FixedTopContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
`;

export const FixedBottomContainer = styled.div`
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 10;
  padding: 16px;
  border-top: 1px solid #f0f0f0;
`;

export const ContentBelowContainer = styled.div`
  padding: 16px;
  flex-grow: 1;
  overflow-y: auto;
  background-color: #fff;
`;

export const NoteText = styled.div`
  width: 100%;
  height: 70px;
  overflow-y: auto;
  padding: 8px;
  border: 1px solid ${color.grey10};
  border-radius: 6px;
  margin-bottom: 2px;
`;
