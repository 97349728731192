import React, { useEffect, useState } from 'react';
import Layout from '../../layout';
import Table from '../../../../../../../components/table-antd';
import { historyCall } from './constants/columns';
import { Select } from 'antd';
import { useContactCompanyQuery } from '../../../hooks/useContactQuery';
import { useSalesContext } from '../../../contexts/sales-context/context';
import { useMutation } from '@tanstack/react-query';
import { GET } from '../../../../../../../services';

const getCallHistoryCompany = async (companyId) => {
  try {
    const response = await GET(`/tls-company/history/${companyId}`);
    return response?.result?.data;
  } catch (err) {
    throw new Error('error');
  }
};

const getCallHistoryContact = async (contactId) => {
  try {
    const response = await GET(`/company-contact/history/${contactId}`);
    return response?.result?.data;
  } catch (err) {
    throw new Error('error');
  }
};

function HistoryCall() {
  const { companyDetails } = useSalesContext();

  const companyId = companyDetails?._id;
  const { data: fetchContacts, isLoading: isContactLoading } = useContactCompanyQuery(companyId);

  const generateContactOptions = (contacts, id) => {
    if (!contacts) return [];

    const options = contacts.map((c) => ({
      label: c?.name || '-',
      value: c?._id,
    }));

    return [
      {
        label: 'ทั้งหมด',
        value: id,
      },
      ...options,
    ];
  };
  const options = generateContactOptions(fetchContacts, companyId);

  const [selectedOption, setSelectedOption] = useState(companyId || 'ทั้งหมด');

  const historyCompanyData = useMutation({
    mutationKey: ['historyCompanyData'],
    mutationFn: (id) => getCallHistoryCompany(id),
  });

  const historyContactData = useMutation({
    mutationKey: ['historyContactData'],
    mutationFn: (id) => getCallHistoryContact(id),
  });

  const { data, isLoading } =
    companyId === selectedOption ? historyCompanyData : historyContactData;

  useEffect(() => {
    if (selectedOption === companyId) {
      historyCompanyData.mutate(selectedOption);
    } else {
      historyContactData.mutate(selectedOption);
    }
  }, [selectedOption]);

  return (
    <Layout>
      <Layout.Content>
        <Layout.Header>
          <Layout.Header.Title>ประวัติการโทร</Layout.Header.Title>
          <Select
            loading={isContactLoading}
            defaultValue={selectedOption}
            style={{ width: 120 }}
            options={options}
            onChange={(e) => setSelectedOption(e)}
          />
        </Layout.Header>
        <Table columns={historyCall()} data={data} loading={isLoading} />
      </Layout.Content>
    </Layout>
  );
}

export default HistoryCall;
