import { Divider, Space, Typography } from 'antd';
import React from 'react';

function Total() {
  return (
    <Space split={<Divider type="vertical" />}>
      <Space>
        <Typography.Text>ประกาศงานทั้งหมด</Typography.Text>
        <Typography.Text strong>6</Typography.Text>
      </Space>
      <Space>
        <Typography.Text>เครดิตที่ใช้ไปทั้งหมด</Typography.Text>
        <Typography.Text strong>250</Typography.Text>
      </Space>
    </Space>
  );
}

export default Total;
