import React from 'react';
import { Button } from 'antd';
import { StarOutlined } from '@ant-design/icons';
import LinkComponent from '../../../../../../components/link';

export const quotationColumn = () => {
  return [
    {
      title: 'เลขที่ใบเสนอราคา',
      key: 'quotationId',
      dataIndex: 'quotationId',
      render: (data) => <LinkComponent>{data}</LinkComponent>,
    },
    {
      title: 'วันที่ออก',
      key: 'createdDate',
      dataIndex: 'createdDate',
    },
    {
      title: 'วันหมดอายุ',
      key: 'expiredDate',
      dataIndex: 'expiredDate',
    },
    {
      title: 'ยอดรวม (บาท)',
      key: 'total',
      dataIndex: 'total',
    },
    {
      title: 'เดือนที่คาดว่าปิด',
      key: 'closedMonth',
      dataIndex: 'closedMonth',
    },
    {
      key: 'bookmark',
      dataIndex: 'bookmark',
      render: (data) => <Button shape="circle" icon={<StarOutlined />} />,
    },
  ];
};
