import React from 'react';
import { Space, Button, Typography } from 'antd';
import { ReportStatus } from '../../../../../../components/shared/status-tag';

export const jobPosition = () => {
  return [
    {
      title: 'แพ็กเกจ',
      dataIndex: 'package',
      key: 'package',
    },
    {
      title: 'ชื่อประกาศงาน',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
    },
    {
      title: 'วันที่โพสต์',
      dataIndex: 'postDate',
      key: 'postDate',
    },
    {
      title: 'วันที่อัปเดตล่าสุด',
      dataIndex: 'updateDate',
      key: 'updateDate',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'ผู้สมัคร',
      dataIndex: 'candidate',
      key: 'candidate',
    },
    {
      title: '',
      key: 'action',
      render: () => <Button>แจ้ง PR งาน</Button>,
    },
  ];
};

export const promoteJobColumn = () => {
  return [
    {
      title: 'ชื่อประกาศงาน (ครั้งที่ส่ง)',
      key: 'jobsTitle',
      dataIndex: 'jobsTitle',
      render: (data) => (
        <Space direction="vertical">
          {data.map((item, index) => (
            <Typography.Text key={index}>{item}</Typography.Text>
          ))}
        </Space>
      ),
    },
    {
      title: 'รายละเอียด',
      key: 'attachLinks',
      dataIndex: 'attachLinks',
      render: (data) => (
        <Space direction="vertical">
          {data.map((item, index) => (
            <a key={index}>{item}</a>
          ))}
        </Space>
      ),
    },
    {
      title: 'วันที่ส่ง',
      key: 'sendDate',
      dataIndex: 'sendDate',
    },
    {
      title: 'ชื่อผู้ส่ง',
      key: 'senderName',
      dataIndex: 'senderName',
    },
  ];
};

export const promoteDetailColumn = () => {
  return [
    {
      title: 'ชื่อประกาศงาน (ครั้งที่ส่ง)',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
    },
    {
      title: 'รายละเอียด',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'วันที่ส่ง',
      dataIndex: 'sendDate',
      key: 'sendDate',
    },
    {
      title: 'ชื่อผู้ส่ง',
      dataIndex: 'sender',
      key: 'sender',
    },
  ];
};
