export const manageHistoryData = [
  {
    reportDate: '2025-02-14',
    reportDetail: 'งานประกาศหาย',
    reportTitle: 'เกี่ยวกับการใช้งาน',
    supervisor: 'CS | เจมี่-Apisada',
  },
  {
    reportDate: '2024-02-13',
    reportDetail: 'PR ตำแหน่ง UX/UI Designer',
    reportTitle: 'PR งาน',
    supervisor: 'CS | เจมี่-Apisada',
  },
  {
    reportDate: '2025-02-12',
    reportDetail: 'กดซื้อโปรไฟล์ไม่ได้',
    reportTitle: 'เกี่ยวกับการใช้งาน',
    supervisor: 'SALE | มั่ม-Montol',
  },
  {
    reportDate: '2024-02-11',
    reportDetail: 'PR ตำแหน่ง Developer',
    reportTitle: 'PR งาน',
    supervisor: 'SALE | มั่ม-Montol',
  },
  {
    reportDate: '2024-02-11',
    reportDetail: 'PR ตำแหน่ง Designer',
    reportTitle: 'PR งาน',
    supervisor: 'CS | เจมี่-Apisada',
  },
  {
    reportDate: '2025-02-09',
    reportDetail: 'ขอส่วนลด',
    reportTitle: 'เกี่ยวกับการใช้งาน',
    supervisor: 'SALE | มั่ม-Montol',
  },
  {
    reportDate: '2025-02-08',
    reportDetail: 'ผู้สมัครหายไปจากหน้าผู้สมัคร',
    reportTitle: 'เกี่ยวกับการใช้งาน',
    supervisor: 'CS | เจมี่-Apisada',
  },
];
