import React from 'react';
import { ModalTheme } from '../../../../components/modal';
import { Col, Row } from 'antd';

function ModalLayout(props) {
  const { modalProps, children } = props;
  return (
    <ModalTheme {...modalProps}>
      <Row style={{ height: '100%' }}>{children}</Row>
    </ModalTheme>
  );
}

ModalLayout.leftCol = function LeftColumn({ children }) {
  return (
    <Col
      span={12}
      style={{ height: '100%', overflowY: 'hidden', borderRight: '1px solid #f0f0f0' }}
    >
      {children}
    </Col>
  );
};
ModalLayout.rightCol = function RightColumn({ children }) {
  return (
    <Col span={12} style={{ height: '100%', overflowY: 'hidden' }}>
      {children}
    </Col>
  );
};

export default ModalLayout;
