import React from 'react';
import { DateColumn } from '../../../../../../../screens/telesales/views/table/components/render-column';
import { ButtonIconTheme } from '../../../../../../../components/buttons';
import StatusTag from '../../../../../../../components/shared/status-tag';
import CompanyProfileLink from '../../../../../../../components/shared/company-profile-link';

export const column = () => {
  return [
    {
      title: 'รายละเอียด',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'ชื่อบริษัท',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (data) => <CompanyProfileLink text={data} href="#" />,
    },
    {
      title: 'ผู้ส่งเรื่อง',
      dataIndex: 'sender',
      key: 'sender',
    },
    {
      title: 'วันที่ส่งเรื่อง',
      dataIndex: 'sentDate',
      key: 'sentDate',
      render: (data) => <DateColumn date={data} />,
    },
    {
      title: 'วันที่ปิดงาน',
      dataIndex: 'closedDate',
      key: 'closedDate',
      render: (data) => <DateColumn date={data} />,
    },
    {
      title: 'ผู้ปิดงาน',
      dataIndex: 'closer',
      key: 'closer',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <StatusTag type="report" status={status} />,
    },
    {
      title: '',
      key: 'operation',
      width: '80px',
      render: () => <ButtonIconTheme useFor="REMOVE" onClick={() => {}} />,
    },
  ];
};
