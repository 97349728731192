export const mockData = [
  {
    id: '1',
    companyName: 'JobFinFin',
    packageName: 'Standard',
    duration: '3 เดือน',
    expiredDate: '2024-02-02',
    closedDate: null,
    status: 'pending',
  },
  {
    id: '2',
    companyName: 'GMM',
    packageName: 'Enterprise',
    duration: '6 เดือน',
    expiredDate: '2024-07-10',
    closedDate: null,
    status: 'pending',
  },
  {
    id: '3',
    companyName: 'Copperwide',
    packageName: 'Premium',
    duration: '1 ปี',
    expiredDate: '2024-01-10',
    closedDate: null,
    status: 'pending',
  },
  {
    id: '4',
    companyName: 'Nexon',
    packageName: 'Beyond',
    duration: '3 เดือน',
    expiredDate: '2024-01-09',
    closedDate: null,
    status: 'pending',
  },
  {
    id: '5',
    companyName: 'ชาเขียว-ภูเก็ตฮิลส์',
    packageName: 'Beyond Plus',
    duration: '1 ปี',
    expiredDate: '2024-01-09',
    closedDate: null,
    status: 'pending',
  },
  {
    id: '6',
    companyName: 'SCBX',
    packageName: 'Enterprise',
    duration: '3 เดือน',
    expiredDate: '2024-01-08',
    closedDate: '2024-01-11',
    status: 'success',
  },
  {
    id: '7',
    companyName: 'True',
    packageName: 'Standard',
    duration: '1 ปี',
    expiredDate: '2024-01-07',
    closedDate: '2024-01-09',
    status: 'success',
  },
  {
    id: '8',
    companyName: 'Nexon',
    packageName: 'Beyond Plus',
    duration: '1 ปี',
    expiredDate: '2024-01-05',
    closedDate: '2024-01-07',
    status: 'success',
  },
];
