import React, { useState } from 'react';
import Table from '../../../../../../../components/table-antd';
import { PromoteJobColumn } from './constants/column';
import { jobData } from './constants/mock';
import Layout from '../../layout';

function PromoteJob() {
  const [selectedRows, setSelectedRows] = useState();

  return (
    <Layout>
      <Layout.Content>
        <Layout.Header>
          <Layout.Header.Title>โปรโมทงาน</Layout.Header.Title>
        </Layout.Header>
        <Table columns={PromoteJobColumn()} getSelectedRows={setSelectedRows} data={jobData} />
      </Layout.Content>
    </Layout>
  );
}

export default PromoteJob;
