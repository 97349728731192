import { GET } from '../../../../../../services';

export const getJobPromoteApi = async () => {
  try {
    const response = await GET('/send-promote/job/promote');
    return response;
  } catch (err) {
    throw new Error('error');
  }
};
