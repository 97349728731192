import React from 'react';
import { useSelector } from 'react-redux';
import { FORM_INPUT } from './forms/forms';
import { RenderForm } from '../../../../../../../../components/forms';
import { useOptions } from '../../../../../../../../hooks/useOptions';

function ContactForm(props) {
  const { ...formProps } = props;
  const { options } = useOptions();
  const { user } = useSelector((state) => state.authen);

  return (
    <RenderForm
      forms={FORM_INPUT({
        options,
        isDisabledKeySuccess: user?.role !== 'SUPER_ADMIN',
      })}
      {...formProps}
    />
  );
}

export default ContactForm;
