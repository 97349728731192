import { GET, POST } from '../../../../../../../services';

export const getJobPromoteApi = async () => {
  try {
    const response = await GET('/send-promote/job/promote');
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

export const sendPromoteApi = async (payload) => {
  try {
    const response = POST('/send-promote/create-send-promote', payload);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};
