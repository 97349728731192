import { Drawer, Space } from 'antd';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../../../../../components/button-antd';
import { RenderForm } from '../../../../../../../../components/forms';
import { useForm } from 'react-hook-form';
import { FORM, VALIDATION } from './constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { POST } from '../../../../../../../../services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { openAlert } from '../../../../../../../../components/alert/useAlert';

const createReportApi = async (reqBody) => {
  try {
    const response = await POST('/send-request/create-request', reqBody);
    return response;
  } catch (err) {
    throw new Erorr('error');
  }
};

function ReportCaseDrawer(props) {
  const { open, onClose, companyId } = props;

  const queryClient = useQueryClient();

  const formProps = useForm({
    defaultValues: { detail: '' },
    resolver: yupResolver(VALIDATION),
  });

  const { mutate: createReportCase, isLoading } = useMutation({
    mutationKey: 'createReportCase',
    mutationFn: (reqBody) => createReportApi(reqBody),
    onSuccess: () => {
      openAlert({ type: 'success', message: 'ดำเนินการสำเร็จ' });
      queryClient.invalidateQueries(['reportCases']);
    },
    onError: () => {
      openAlert({ type: 'error', message: 'เกิดข้อผิดพลาด' });
    },
  });

  const onSave = () => {
    formProps?.handleSubmit(
      (values) => {
        const reqBody = {
          ...values,
          companyId: companyId,
        };
        createReportCase(reqBody, {
          onSuccess: () => onClose(),
        });
      },
      () => {},
    )();
  };

  useEffect(() => {
    formProps.reset();
  }, [open]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title="ส่งเรื่องดำเนินการ"
      width={720}
      extra={
        <Space>
          <Button useFor="cancel" disabled={isLoading} onClick={onClose} />
          <Button useFor="save" onClick={onSave} loading={isLoading} />
        </Space>
      }
    >
      <Space style={{ width: '100%' }} direction="vertical">
        <RenderForm forms={FORM()} {...formProps} />
      </Space>
    </Drawer>
  );
}

ReportCaseDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  companyId: PropTypes.string,
  requestType: PropTypes.string,
};

ReportCaseDrawer.defaultProps = {
  open: false,
  onClose: () => {},
  companyId: '',
  requestType: '',
};

export default ReportCaseDrawer;
