import React from 'react';
import CompanyProfileLink from '../../../../../../../components/shared/company-profile-link';
import { Typography } from 'antd';
import { numberComma } from '../../../../../../../utils/number';
import { DateFormat } from '../../../../../../../components/shared/format-text';
import StatusTag from '../../../../../../../components/shared/status-tag';

export const column = () => {
  return [
    {
      title: 'ชื่อบริษัท',
      dataIndex: 'name',
      key: 'name',
      render: (label, records) => <CompanyProfileLink label={label} authorId={records?.authorId} />,
    },
    {
      title: 'รหัสบริษัท',
      dataIndex: 'caNumber',
      key: 'caNumber',
    },

    {
      title: 'ขนาดบริษัท',
      dataIndex: 'headcountRangeSize',
      key: 'headcountRangeSize',
      render: (status) => <StatusTag type="headcountRangeSize" status={status} />,
    },
    {
      title: 'จำนวนพนักงาน',
      dataIndex: 'headcountRange',
      key: 'headcountRange',
      render: (status) => <StatusTag type="headcountRange" status={status} />,
    },
    {
      title: 'ประเภทธุรกิจ',
      dataIndex: 'businessType',
      key: 'businessType',
    },
    {
      title: 'วันที่เริ่มใช้งาน',
      dataIndex: 'createDate',
      key: 'createDate',
      render: (data) => <DateFormat date={data} />,
    },
    {
      title: 'แพ็กเกจที่ใช้ล่าสุด',
      dataIndex: 'packages',
      key: 'packages',
    },
    {
      title: 'จำนวนงานที่เปิด',
      dataIndex: 'jobs',
      key: 'jobs',
    },
    {
      title: 'เครดิตที่ใช้',
      dataIndex: 'usageCredit',
      key: 'usageCredit',
    },
    {
      title: 'ยอดผู้สมัคร',
      dataIndex: 'candidate',
      key: 'candidate',
      render: (data) => <Typography.Text>{numberComma(data)}</Typography.Text>,
    },
  ];
};
