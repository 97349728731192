import React from 'react';
import { Typography } from 'antd';

export const compareCompanyColumn = () => {
  return [
    {
      title: '',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'JobFinFin',
      dataIndex: 'jobFinFin',
      key: 'jobFinFin',
      render: (text) => <Typography.Text strong>{text}</Typography.Text>,
    },
    {
      title: 'JobThai',
      dataIndex: 'jobThai',
      key: 'jobThai',
    },
    {
      title: 'Jobbkk',
      dataIndex: 'jobbkk',
      key: 'jobbkk',
    },
    {
      title: 'Jobsdb',
      dataIndex: 'jobsdb',
      key: 'jobsdb',
    },
    {
      title: 'JobTopgun',
      dataIndex: 'jobTopgun',
      key: 'jobTopgun',
    },
  ];
};
