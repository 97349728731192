import React from 'react';

import ModalLayout from './layout/modal-layout';
import { SalesProvider } from './contexts/sales-context/context';
import LeftColumn from './views/left-column';
import RightColumn from './views/right-column';

function SalesModal(props) {
  const { companyDetails, ...modalProps } = props;

  return (
    <SalesProvider companyDetails={companyDetails}>
      <ModalLayout
        modalProps={{
          ...modalProps,
          id: 'sales-modal',
          title: 'รายละเอียดบริษัท',
          fullscreen: true,
        }}
      >
        <ModalLayout.leftCol>
          <LeftColumn />
        </ModalLayout.leftCol>
        <ModalLayout.rightCol>
          <RightColumn />
        </ModalLayout.rightCol>
      </ModalLayout>
    </SalesProvider>
  );
}

export default SalesModal;
