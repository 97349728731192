import { Space, Typography } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { numberComma } from '../../../utils/number';

function CreditUsage(props) {
  const { balance, total } = props;

  if (!balance && !total) return <Typography.Text>-</Typography.Text>;

  return (
    <Space>
      <Typography.Text strong>{numberComma(balance)}</Typography.Text>
      <Typography.Text>/</Typography.Text>
      <Typography.Text>{numberComma(total)}</Typography.Text>
    </Space>
  );
}

CreditUsage.propTypes = {
  balance: PropTypes.number,
  total: PropTypes.number,
};

CreditUsage.defaultProps = {
  balance: null,
  total: null,
};
export default CreditUsage;
