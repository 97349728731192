import React from 'react';
import { TabStyled } from './styled-component';
import ContactDetail from '../../../../components/tabs/contact-detail';
import CompareCompany from '../../../../components/tabs/compare-company';
import JobPosition from '../../../../components/tabs/job-position';
import ManageHistory from '../../../../components/tabs/manage-history';
import Button from '../../../../../../components/button-antd';
import { Space } from 'antd';
import HistoryCall from '../../../../components/tabs/history-call';
import Package from '../../../../components/tabs/package';
import Quotation from '../../../../components/tabs/quotation';

function Tabs() {
  const tabItems = [
    {
      key: 'contact',
      label: 'ผู้ติดต่อ',
      children: <ContactDetail />,
    },
    {
      key: 'history-call',
      label: 'ประวัติการโทร',
      children: <HistoryCall />,
    },
    {
      key: 'compare-company',
      label: 'เปรียบเทียบคู่แข่ง',
      children: <CompareCompany />,
    },
    {
      key: 'job-position',
      label: 'ตำแหน่งงาน',
      children: <JobPosition />,
    },
    {
      key: 'package',
      label: 'แพ็กเกจ',
      children: <Package />,
    },
    {
      key: 'quotation',
      label: 'ใบเสนอราคา',
      children: <Quotation />,
    },
    {
      key: 'manage-history',
      label: 'ประวัติการจัดการ',
      children: <ManageHistory />,
    },
  ];
  return (
    <TabStyled
      type="card"
      items={tabItems}
      defaultActiveKey="contact"
      tabBarExtraContent={
        <Space>
          <Button useFor="reportCase" />
          <Button useFor="resetPassword" isIcon />
        </Space>
      }
    />
  );
}

export default Tabs;
