import React from 'react';
import { ConfigProvider, DatePicker } from 'antd';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import locale from 'antd/es/locale/th_TH';

export const EditableCell = ({ date, control }) => {
  return (
    <Controller
      name="estimateTime"
      control={control}
      defaultValue={date ? dayjs(date) : null}
      render={({ field, fieldState: { error } }) => (
        <ConfigProvider locale={locale}>
          <DatePicker
            picker="month"
            format="MMM YYYY"
            {...field}
            status={error ? 'error' : undefined}
          />
          {error && <div style={{ color: 'red', fontSize: '12px' }}>{error.message}</div>}
        </ConfigProvider>
      )}
    />
  );
};
