import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Tag } from 'antd';
import { useOptions } from '../../../hooks/useOptions';

const STATUS_CONFIGS = {
  report: {
    PENDING: { color: 'warning', label: 'รอดำเนิการ' },
    DONE: { color: 'success', label: 'ดำเนินการแล้ว' },
  },
  pr: {
    pending: { color: 'error', label: 'ยกเลิก' },
    'summary-1': { color: 'warning', label: 'ส่งงานครั้งที่ 1' },
    default: { color: 'success', label: 'ดำเนินการแล้ว' },
  },
  package: {
    ACTIVE: { color: 'processing', label: 'ระหว่างใช้งาน' },
    INACTIVE: { color: 'processing', label: 'ไม่ได้ใช้งาน' },
    EXPIRED: { color: 'error', label: 'หมดอายุ' },
  },
  packageApplicant: {
    FREE: { color: 'gold', label: 'Free' },
    BUY: { color: 'purple', label: 'Buy' },
  },
  keySuccess: {
    buy: { color: 'gold', label: 'buy' },
    success: { color: 'green', label: 'Success' },
    unsuccess: { color: 'red', label: 'Unsuccess' },
    unknown: { color: 'geekblue', label: 'Unknown' },
  },
  stageManual: {
    'TLS-SM01': { color: 'green', label: 'ได้คนเข้าทำงาน' },
    'TLS-SM02': { color: 'green', label: 'ได้ผู้สัมภาษณ์' },
    'TLS-SM03': { color: 'red', label: 'Candidate ไม่ตรง' },
    'TLS-SM04': { color: 'red', label: 'ลองไม่ถูก' },
    'TLS-SM05': { color: 'red', label: 'ติดต่อไม่ได้' },
    'TLS-SM06': { color: 'red', label: 'ปิดการใช้งาน' },
  },
  stageAuto: {
    registered: { color: 'green', label: 'Registered' },
    active: { color: 'green', label: 'Active' },
    new: { color: 'gold', label: 'New' },
    inactive: { color: 'red', label: 'Inactive' },
  },
  purchaseOccasion: {
    high: { color: '#ff7b54', label: 'สูง' },
    medium: { color: '#F12E22', label: 'กลาง' },
    low: { color: '#d9d9d9', label: 'ต่ำ' },
    high_potential: { color: '#FFCB14', label: 'high potential' },
    sign: { color: '#4857DC', label: 'sign' },
    done: { color: '#0FAB58', label: 'ปิดการขาย' },
  },
  headcountRange: {
    OPT_1: { color: 'default', label: 'XS | 1 - 10 คน' },
    OPT_2: { color: 'default', label: 'XS | 11 - 50 คน' },
    OPT_3: { color: 'warning', label: 'S | 51 - 200 คน' },
    OPT_4: { color: 'warning', label: 'S | 201 - 500 คน' },
    OPT_5: { color: 'green', label: 'M | 501 - 1,000 คน' },
    OPT_6: { color: 'green', label: 'M | 1,001 - 5,000 คน' },
    OPT_7: { color: 'blue', label: 'L | 5,001 - 10,000 คน' },
    OPT_8: { color: 'blue', label: 'L | มากกว่า 10,001 คน' },
  },
  jobStatus: {
    online: { color: 'green', label: 'เปิดรับสมัคร' },
    close: { color: 'red', label: 'ปิดรับสมัคร' },
  },
  callStatus: {
    'CALL-01': { color: 'green', label: 'เปิดรับสมัคร' },
    'CALL-02': { color: 'red', label: 'เปิดรับสมัคร' },
    'CALL-03': { color: 'grey', label: 'เปิดรับสมัคร' },
    'CALL-04': { color: 'orange', label: 'เปิดรับสมัคร' },
  },
  promoteStatus: {
    PENDING: { color: 'warning', label: 'รอดำเนินการ' },
    PR: { color: 'processing', label: 'กำลังโปรโมท' },
    DONE: { color: 'success', label: 'ดำเนินการเสร็จสิ้น' },
    CANCEL: { color: 'default', label: 'ยกเลิก' },
  },
  headcountRangeSize: {
    XS: { color: 'default', label: 'XS' },
    S: { color: 'warning', label: 'S' },
    M: { color: 'green', label: 'M' },
    L: { color: 'blue', label: 'L' },
  },
  headcountRange: {
    OPT_1: { color: 'default', label: '1 - 10 คน' },
    OPT_2: { color: 'default', label: '11 - 50 คน' },
    OPT_3: { color: 'warning', label: '51 - 200 คน' },
    OPT_4: { color: 'warning', label: '201 - 500 คน' },
    OPT_5: { color: 'green', label: '501 - 1,000 คน' },
    OPT_6: { color: 'green', label: '1,001 - 5,000 คน' },
    OPT_7: { color: 'blue', label: '5,001 - 10,000 คน' },
    OPT_8: { color: 'blue', label: 'มากกว่า 10,001 คน' },
  },
};

const StatusTag = ({ type, isBadge, status }) => {
  const { options } = useOptions();
  const config = STATUS_CONFIGS[type];

  if (!config) return null;

  const labelFromOption = options?.[type]?.data?.find((v) => v?.value === status)?.label;

  const tagColor = config?.[status]?.color || null;
  const tagLabel = labelFromOption || config?.[status]?.label || null;

  if (!tagLabel && !tagColor) return <>-</>;

  if (isBadge) return <Badge color={tagColor} text={tagLabel} />;

  return <Tag color={tagColor}>{tagLabel}</Tag>;
};

StatusTag.propTypes = {
  type: PropTypes.string.isRequired,
  status: PropTypes.string,
  label: PropTypes.string,
  isBadge: PropTypes.bool,
};

StatusTag.defaultProps = {
  status: 'default',
  label: '',
  isBadge: false,
};

export default StatusTag;
