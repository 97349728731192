import React, { useState } from 'react';
import { createContext, useContext } from 'react';
import {
  //  useCallDetailQuery,
  useCallManagement,
} from './hooks';
import { useContactCompanyQuery } from '../../hooks/useContactQuery';

export const SalesContext = createContext();

export const useSalesContext = () => {
  const context = useContext(SalesContext);
  if (!context) {
    throw new Error('useSalesContext must be used within a SalesProvider');
  }
  return context;
};

export const SalesProvider = ({ children, companyDetails }) => {
  const [companyDetailsState, setCompanyDetailsState] = useState(companyDetails);

  const { data: contactData, isLoading: isContactLoading } = useContactCompanyQuery(
    companyDetails._id,
  );

  const callManage = useCallManagement(companyDetails);

  const value = {
    companyDetails: companyDetailsState,
    updateCompanyDetails: setCompanyDetailsState,
    contactData,
    isContactLoading,
    ...callManage,
  };

  return <SalesContext.Provider value={value}>{children}</SalesContext.Provider>;
};
