import { Modal } from 'antd';
import styled, { css } from 'styled-components';

export const ModalAntd = styled(Modal)`
  /* Common styles for both modes */
  .ant-modal-header {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    height: 56px;
    min-height: 56px;
    max-height: 56px;
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow: hidden;
  }

  .ant-modal-title {
    flex: 1;
    padding: 0 24px;
  }

  .ant-modal-body {
    flex: 1;
    border-bottom: ${(props) => (props.footer ? '1px solid #f0f0f0' : 'none')};
  }

  .ant-modal-footer {
    margin: 0;
    padding: ${(props) => (props.footer ? '16px 24px' : '0')};
  }

  /* Fullscreen specific styles */
  ${(props) =>
    props.fullscreen &&
    css`
      &.ant-modal {
        width: 100vw !important;
        max-width: 100vw;
        height: 100vh;
        margin: 0;
        top: 0;
        padding: 0;
      }

      .ant-modal-content {
        height: 100vh;
        border-radius: 0;
      }

      .ant-modal-body {
        flex: 1 1 auto;
        overflow-y: auto;
      }
    `}

  /* Normal mode specific styles */
  ${(props) =>
    !props.fullscreen &&
    css`
      &.ant-modal {
        height: calc(100vh - 40px);
        top: 20px;
        padding: 0;
      }

      .ant-modal-content {
        border-radius: 8px;
        height: calc(100vh - 40px);
      }

      .ant-modal-body {
        height: 100%;
        overflow-y: auto;
      }
    `}
`;
