import { Descriptions, Flex, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import QuotationText from '../../../../../../../../components/shared/quotation-text';
import StatusTag from '../../../../../../../../components/shared/status-tag';
import { useSalesContext } from '../../../../contexts/sales-context/context';
import {
  Container,
  ContentBelowContainer,
  FixedContainer,
  NoteContainer,
} from './styled-component';
import Button from '../../../../../../../../components/button-antd';
import CompanyInformationForm from './components/company-infomation-form';
import useFormState from './components/company-infomation-form/hook';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { errorAlert } from '../../../../../../../../utils/api/msg';
import { POST } from '../../../../../../../../services';
import { openAlert } from '../../../../../../../../components/alert/useAlert';
import dayjs from 'dayjs';
import { ShortThaiMonthFormat } from '../../../../../../../../components/shared/format-text';

const editCompanyDetailApi = async ({ companyId, reqBody }) => {
  try {
    const response = await POST(`/tls-company/update-company/${companyId}`, reqBody);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

function CompanyOtherDetail() {
  const { companyDetails, updateCompanyDetails } = useSalesContext();

  const items = [
    {
      key: '1',
      label: 'Key Success',
      children: <StatusTag type="keySuccess" status={companyDetails?.keySuccess} />,
    },
    {
      key: '2',
      label: 'โอกาสในการซื้อ',
      children: (
        <StatusTag type="purchaseOccasion" isBadge status={companyDetails?.purchaseOccasion} />
      ),
    },
    {
      key: '3',
      label: 'Stage Auto',
      children: <StatusTag type="stageAuto" status={companyDetails?.stage} />,
    },
    {
      key: '4',
      label: 'Stage Manual',
      children: <StatusTag type="stageManual" status={companyDetails?.stageManual} />,
    },
    {
      key: '5',
      label: 'ใบเสนอราคา',
      children: companyDetails?.quotations ? (
        <QuotationText quotations={companyDetails?.quotations} />
      ) : (
        '-'
      ),
    },
    {
      key: '6',
      label: 'หาตำแหน่งอะไรบ้าง',
      children: companyDetails?.findPosition || '-',
    },
    {
      key: '7',
      label: 'รอบอนุมัติงบประมาณ',
      children: <ShortThaiMonthFormat date={companyDetails?.budgetPeriod} />,
    },
    {
      key: '8',
      label: 'งบประมาณ',
      children: companyDetails?.budget || '-',
    },
    {
      key: '9',
      label: 'โน้ตข้อความเพิ่มเติม',
      children: companyDetails?.lastNote ? (
        <NoteContainer> {companyDetails?.additionalNote}</NoteContainer>
      ) : (
        '-'
      ),
    },
  ];

  const [isEdited, setIsEdited] = useState(false);
  const { formProps } = useFormState(companyDetails);
  const queryClient = useQueryClient();

  const { mutate: editCompanyDetail, isLoading } = useMutation({
    mutationKey: 'editCompanyDetail',
    mutationFn: ({ companyId, reqBody }) => editCompanyDetailApi({ companyId, reqBody }),
    onSuccess: (response) => {
      openAlert({
        type: 'success',
        message: 'แก้ไขข้อมูลสำเร็จ',
      });
      queryClient.invalidateQueries(['tabledata']);
      setIsEdited(false);
      updateCompanyDetails((prev) => ({
        ...prev,
        ...response?.result,
      }));
    },
    onError: () => {
      errorAlert();
    },
  });

  useEffect(() => {
    formProps?.reset();
  }, [isEdited]);

  return (
    <Container>
      <ContentBelowContainer>
        {isEdited ? (
          <CompanyInformationForm formProps={formProps} />
        ) : (
          <Descriptions
            items={items}
            column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 2 }}
            size="small"
          />
        )}
      </ContentBelowContainer>
      <FixedContainer>
        {isEdited ? (
          <Flex style={{ width: '100%' }} justify="flex-end">
            <Space>
              <Button
                useFor="save"
                onClick={() => {
                  const formValues = formProps?.getValues();
                  const { quotations, ...rest } = formValues;

                  const reqBody = {
                    ...rest,

                    ...{
                      followUpDate: rest?.followUpDate
                        ? dayjs(rest.followUpDate, 'DD/MM/YYYY').toISOString()
                        : '',
                    },
                  };

                  editCompanyDetail({
                    companyId: companyDetails?._id,
                    reqBody: reqBody,
                  });
                }}
                loading={isLoading}
              />
              <Button useFor="cancel" onClick={() => setIsEdited(false)} />
            </Space>
          </Flex>
        ) : (
          <Flex style={{ width: '100%' }} justify="center">
            <Button useFor="edited" onClick={() => setIsEdited(true)} />
          </Flex>
        )}
      </FixedContainer>
    </Container>
  );
}

export default CompanyOtherDetail;
