import { GET } from '../../../services';

export const getAdmins = async () => {
  try {
    const response = await GET('/options/admin');
    return response?.result?.data;
  } catch (err) {
    throw new Error('error');
  }
};

export const getSalesCode = async () => {
  try {
    const response = await GET('/options/tls-code');
    return response?.result?.data;
  } catch (err) {
    throw new Error('error');
  }
};

export const getCompanyOptions = async () => {
  try {
    const response = await GET('/options/tls-company-name');
    return response?.result?.data;
  } catch (err) {
    throw new Error('error');
  }
};

export const getCompanyName = async () => {
  try {
    const response = await GET('/options/tls-company-name');
    return response?.result?.data;
  } catch (err) {
    throw new Error('error');
  }
};
export const getHeadCountRange = async () => {
  try {
    const response = await GET('/options/headcount-range');
    return response?.result?.data;
  } catch (err) {
    throw new Error('error');
  }
};
