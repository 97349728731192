import React, { useEffect } from 'react';
import Layout from '../../layout';
import Table from '../../../../../../../components/table-antd';
import { compareCompanyColumn } from './constants/columns';
import { formatToColumnPattern } from './logic';
import { Button, Collapse, Flex, Space } from 'antd';
import { GET } from '../../../../../../../services';
import { useQuery } from '@tanstack/react-query';
import { useSalesContext } from '../../../contexts/sales-context/context';
import CompareCompanyForm from './views/compare-form';

const getCompareCompanyApi = async (companyId) => {
  try {
    const response = await GET(`/tls-company/compare-competitors/${companyId}`);
    return response;
  } catch (error) {
    throw new Error('error');
  }
};

function CompareCompany() {
  const { companyDetails } = useSalesContext();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['compareCompanyData'],
    queryFn: () => getCompareCompanyApi(companyDetails?._id),
  });

  useEffect(() => {
    refetch();
  }, []);

  const formattedData = formatToColumnPattern(data?.result?.data || []);

  const items = [
    {
      key: 'compare-company-form',
      label: 'ฟอร์มเปรียบเทียบ',
      children: <CompareCompanyForm />,
    },
  ];
  return (
    <Layout>
      <Layout.Content>
        <Layout.Header>
          <Layout.Header.Title>ตารางเปรียบเทียบ</Layout.Header.Title>
        </Layout.Header>
        <Space direction="vertical" size={16}>
          <Collapse destroyInactivePanel items={items} />
          <Table columns={compareCompanyColumn()} data={formattedData} loading={isLoading} />
        </Space>
      </Layout.Content>
    </Layout>
  );
}

export default CompareCompany;
