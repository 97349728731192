import React from 'react';
import { FORM_ADMIN_SALES } from '../../constants/form/form';
import { COLUMN } from './constants/column';
import TabLayout from '../../components/tab-layout';
import ManageModal from '../../components/manage-modal';
import useAdminSaleState from '../../hooks/use-admin-sales-state';
import { openAlert } from '../../../../components/alert/useAlert';
import { useDeleteSale } from './hooks/use-delete-sale';
import { ButtonTheme } from '../../../../components/buttons';
import AddSaleModal from '../../components/add-sale-modal';
import { useAddNewSaleCode } from './hooks/use-add-new-sale';
import { Icon } from '../../../../resources';

function AdminSales() {
  const {
    options,
    tableProps,
    paginationProps,
    onSearch,
    onClearCustom,
    selectOtherProps,
    onSave,
  } = useAdminSaleState();

  const [modalProps, setModalProps] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [openAddSaleModalpen, setOpenAddSaleModal] = React.useState(false);

  const { handleDeleteSale } = useDeleteSale();
  const { handleAddSaleCode } = useAddNewSaleCode();

  const onToggleRow = (details) => {
    setModalProps({
      title: `จัดการสิทธิ์ของรหัสเซล ${details?.code}`,
      defaultValues: details?.adminId,
      id: details?.code,
    });

    setOpen(!open);
  };

  const onDeleteSale = (row) => {
    openAlert({
      model: 'confirm',
      title: 'คุณต้องการลบรหัสเซลใช่หรือไม่',
      okText: 'ลบ',
      cancelText: 'ยกเลิก',
      onOk: () => {
        handleDeleteSale({ code: row?.code });
      },
    });
  };

  const onOpenAddSaleModal = () => {
    setOpenAddSaleModal(true);
  };

  const onAddSaleCode = async (body) => {
    await handleAddSaleCode(body);
  };

  return (
    <>
      <TabLayout
        searchLayoutProps={{
          title: 'ตัวกรอง',
          isEnableEnterSubmit: true,
          formSearch: FORM_ADMIN_SALES({ options }),
          onSearch: onSearch,
          onClearCustom: onClearCustom,
        }}
        tableProps={{
          size: 'small',
          columns: COLUMN({
            pageStep: paginationProps?.pageStep,
            onToggleRow,
            onDeleteSale,
          }),
          scroll: { x: 'max-content' },
          style: { width: '100%' },
          contentsExtra: (
            <div style={{ width: '100%', textAlignLast: 'end' }}>
              <ButtonTheme
                useFor="CUSTOM"
                title="สร้าง salecode"
                icon={<Icon.userOutline />}
                type="primary"
                onClick={() => onOpenAddSaleModal()}
              />
            </div>
          ),
          ...tableProps,
        }}
        paginationProps={paginationProps}
      />
      <ManageModal
        open={open}
        onToggle={() => setOpen((prevOpen) => !prevOpen)}
        {...modalProps}
        onSave={onSave}
        selectProps={{
          selectValueKey: 'id',
          mappingData: (values) => ({
            id: values.id,
            name: values.label,
          }),
          placeholder: 'เลือกแอดมิน',
          ...selectOtherProps,
        }}
        renderRow={(item) => <span>{item.name}</span>}
      />
      <AddSaleModal
        open={openAddSaleModalpen}
        onToggle={() => setOpenAddSaleModal((prevOpen) => !prevOpen)}
        onSave={onAddSaleCode}
        selectProps={{
          selectValueKey: 'id',
          placeholder: 'เลือกแอดมิน',
          options: options?.admin,
          mappingData: (values) => ({
            id: values.id,
            value: values.value,
            label: values.label,
          }),
        }}
      />
    </>
  );
}

export default AdminSales;
