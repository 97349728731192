import { GET, POST } from '../../../../../../../services';

export const startCall = async (reqBody) => {
  try {
    const response = await POST(`/call-timer/on-call`, reqBody);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

export const endCall = async ({ callId, reqBody }) => {
  try {
    const response = await POST(`/call-timer/end-call/${callId}`, reqBody);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

export const createTransaction = async (reqBody) => {
  try {
    const response = await POST(`/trx-call/create`, reqBody);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

export const checkOnCall = async (id) => {
  try {
    const response = await GET(`/tls-company/check?contactId=${id}`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const cancelTrxCall = async () => {
  try {
    const response = await GET(`/tls-company/cancel`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};
