import React from 'react';
import PropTypes from 'prop-types';
import {
  ContentWrapper,
  HightlightText,
  OverflowWrap,
  TextWrapper,
  Title,
  UnitText,
  ValueText,
  ValueWrapper,
} from './styled-component';
import { Col } from 'antd';

function Card(props) {
  const { id, title, hightlightTitle, text1, text2, onClick, gridColumn } = props;

  return (
    <Col id={id} span={gridColumn}>
      <ContentWrapper onClick={onClick}>
        <OverflowWrap>
          <Title>{title}</Title>
          {hightlightTitle && <HightlightText strong>{hightlightTitle}</HightlightText>}
        </OverflowWrap>
        <ValueWrapper>
          {text1 && (
            <TextWrapper>
              <ValueText color={text1?.color}>{text1?.total}</ValueText>
              <UnitText>{text1?.unit}</UnitText>
            </TextWrapper>
          )}
          {text2 && (
            <TextWrapper>
              <ValueText color={text2?.color}>{text2?.total}</ValueText>
              <UnitText>{text2?.unit}</UnitText>
            </TextWrapper>
          )}
        </ValueWrapper>
      </ContentWrapper>
    </Col>
  );
}

Card.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  hightlightTitle: PropTypes.string,
  gridColumn: PropTypes.number,
  text1: PropTypes.object,
  text2: PropTypes.object,
  onClick: PropTypes.func,
};

Card.defaultProps = {
  title: '',
  hightlightTitle: '',
  gridColumn: 12,
  text1: null,
  text2: null,
  onClick: () => {},
};

export default Card;
