import React from 'react';
import Row from '../../../../components/row';
import { ModalTheme } from '../../../../components/modal';
import { ButtonTheme } from '../../../../components/buttons';
import { color } from '../../../../resources';
import HistoryCallContents from './views/history-call-contents';

function HistoryCallModal(props) {
  const onClose = () => {
    props.onToggle({ defaultValues: {} });
  };

  return (
    <ModalTheme width={1000} title="ประวัติการโทร" footer={<Footer onClose={onClose} />} {...props}>
      <HistoryCallContents {...props} />
    </ModalTheme>
  );
}

function Footer({ onClose }) {
  return (
    <Row justifyContent="flex-end" alignItems="center">
      <ButtonTheme
        useFor="CUSTOM"
        title="ปิด"
        type="primary"
        color={color.blue80}
        onClick={onClose}
      />
    </Row>
  );
}

export default HistoryCallModal;
