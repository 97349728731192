import React from 'react';
import { Tag } from 'antd';
import * as headCountUtils from '../../../../utils/company/employee-size';
import * as logic from '../../../telesales/logic';

export const FORM_COMPANY_SALES = ({ options }) => {
  return [
    {
      name: 'caNumber',
      label: 'รหัสบริษัท',
      type: 'INPUT',
      placeholder: 'กรอกรหัสบริษัท',
      span: 12,
    },
    {
      name: 'name',
      label: 'ชื่อบริษัท',
      type: 'INPUT',
      placeholder: 'กรอกชื่อบริษัท',
      span: 12,
    },
    {
      name: 'code',
      label: 'รหัสเซล',
      type: 'DROPDOWN',
      mode: 'multiple',
      placeholder: 'เลือกรหัสเซล',
      properties: {
        optionRender: (option) => (
          <span>
            {logic.getSaleCodeDisplay({
              label: option?.label,
              admins: option?.admins,
            })}
          </span>
        ),
        options: options.saleCode || [],
        valueKey: 'value',
        labelKey: 'label',
      },
      span: 12,
    },

    {
      name: 'headcountRange',
      label: 'จำนวนพนักงาน',
      type: 'DROPDOWN',
      mode: 'multiple',
      placeholder: 'เลือกจำนวนพนักงาน',
      tagRender: (props) => (
        <Tag
          color={headCountUtils.getColor(props?.value)}
          closable={props.closable}
          onClose={props.onClose}
          style={{
            marginInlineEnd: 4,
          }}
        >
          {props?.label?.props?.children || ''}
        </Tag>
      ),
      properties: {
        optionRender: (option) => (
          <Tag color={headCountUtils.getColor(option?.value)}>{option?.label}</Tag>
        ),
        options: options.headcountRange || [],
        valueKey: 'value',
        labelKey: 'label',
      },
      span: 12,
    },
  ];
};

export const FORM_ADMIN_SALES = ({ options }) => {
  return [
    {
      name: 'code',
      label: 'รหัสเซล',
      type: 'DROPDOWN',
      mode: 'multiple',
      placeholder: 'เลือกรหัสเซล',
      properties: {
        optionRender: (option) => (
          <span>
            {logic.getSaleCodeDisplay({
              label: option?.label,
              admins: option?.admins,
            })}
          </span>
        ),
        options: options.saleCode,
        valueKey: 'value',
        labelKey: 'label',
      },
      span: 12,
    },
    {
      name: 'userId',
      label: 'แอดมิน',
      type: 'DROPDOWN',
      mode: 'multiple',
      placeholder: 'เลือกแอดมิน',
      properties: {
        options: options.admin,
        valueKey: 'value',
        labelKey: 'label',
      },
      span: 12,
    },
  ];
};
