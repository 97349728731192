import React from 'react';
import { useSalesContext } from '../../contexts/sales-context/context';
import CallDetail from './views/call-detail';
import CompanyOtherDetail from './views/company-other-detail';

function LeftColumn() {
  const { selectedContact } = useSalesContext();

  return selectedContact ? <CallDetail /> : <CompanyOtherDetail />;
}

export default LeftColumn;
