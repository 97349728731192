export const MODAL_TYPES = {
  CONTACT: 'CONTACT_MODAL',
  HISTORY_CALL: 'HISTORY_CALL_MODAL',
  // EDIT_CALL_INFORMATION: 'EDIT_CALL_INFOMATION_MODAL',
  // PACKAGE_ITEMS: 'PACKAGE_ITEMS_MODAL',
  // APPLICANTS_COUNT: 'APPLICANTS_COUNT_MODAL',
  // EDIT_CONTACT: 'EDIT_CONTACT_MODAL',
  // ADD_CONTACT: 'ADD_CONTACT_MODAL',
  // CALL: 'CALL_MODAL',
  // NOTE: 'NOTE_MODAL',
  // QUOTATION: 'QUOTATION_MODAL',
  TRACKING: 'TRACKING_MODAL',
  SALES: 'SALES_MODAL',
};
