import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { column } from './constants/column';
import { useMutation } from '@tanstack/react-query';
import { GET, GET_WITH_PARAMS } from '../../../../../../services';
import { getErrorMsg } from '../../../../../../utils/api/msg';
import Table from '../../../../../../components/table-antd';

const opportunitiesAPI = async (type, payload) => {
  try {
    const response = await GET_WITH_PARAMS(`/tls-company/purchase-occasion/${type}`, payload);
    return response;
  } catch (err) {
    getErrorMsg(err?.message);
    throw new Error('error');
  }
};

function Opportunities(props) {
  const { isPrimaryState, type } = props;
  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
  });

  const {
    mutate: fetchOpportunityData,
    data,
    isLoading,
  } = useMutation({
    mutationKey: ['opportunities', type],
    mutationFn: () =>
      opportunitiesAPI(type, {
        pageSize: pagination.pageSize,
        pageIndex: pagination.current,
      }),
  });

  const handleTableChange = (newPagination) => {
    setPagination({
      ...newPagination,
    });
  };

  useEffect(() => {
    fetchOpportunityData();
  }, []);

  return (
    <Table
      data={data?.result?.data}
      loading={isLoading}
      columns={column({ isPrimaryState })}
      pagination={{
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: data?.total || 0,
        onChange: (page, pageSize) => handleTableChange({ current: page, pageSize }),
      }}
    />
  );
}

Opportunities.propTypes = {
  isPrimaryState: PropTypes.bool,
  type: PropTypes.string,
};

Opportunities.defaultProps = {
  isPrimaryState: false,
  type: '',
};

export default Opportunities;
