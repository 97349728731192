import React, { useEffect } from 'react';
import Layout from '../../layout';
import Table from '../../../../../../../components/table-antd';
import { pagekageColumn } from './constants/columns';
import { useSalesContext } from '../../../contexts/sales-context/context';
import { GET } from '../../../../../../../services';
import { useMutation } from '@tanstack/react-query';
import { numberWithComma } from '../../../../../../../utils/number';

export const getPackageDetailsApi = async (companyId) => {
  try {
    const response = await GET(`/tls-company/package/${companyId}`);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

function Package() {
  const { companyDetails } = useSalesContext();

  const companyId = companyDetails?._id;

  const {
    data,
    mutate: getPackageDetails,
    isLoading,
  } = useMutation({
    mutationKey: ['packageData', companyId],
    mutationFn: () => getPackageDetailsApi(companyId),
  });

  useEffect(() => {
    getPackageDetails();
  }, []);

  return (
    <Layout>
      <Layout.Content>
        <Layout.Header>
          <Layout.Header.Title>แพ็กเกจ</Layout.Header.Title>
        </Layout.Header>
        <Table
          columns={pagekageColumn()}
          data={data?.result?.packages || []}
          loading={isLoading}
          summary={() => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>ยอดรวม</Table.Summary.Cell>
                  <Table.Summary.Cell index={1} />
                  <Table.Summary.Cell index={2} />
                  <Table.Summary.Cell index={3} />
                  <Table.Summary.Cell index={4} />
                  <Table.Summary.Cell index={5} />
                  <Table.Summary.Cell index={6}>
                    {numberWithComma({ input: data?.result?.total })}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} />
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Layout.Content>
    </Layout>
  );
}

export default Package;
