import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableCustom from '../../../../../../screens/telesales/components/table-custom';
import { column } from './constants/column';
import { mockData } from './constants/mockData';
import { Table } from 'antd';

function Opportunities(props) {
  const { isPrimaryState } = props;
  return (
    <div>
      <Table dataSource={mockData} columns={column({ isPrimaryState })} />
    </div>
  );
}

Opportunities.propTypes = {
  isPrimaryState: PropTypes.bool,
};

Opportunities.defaultProps = {
  isPrimaryState: false,
};

export default Opportunities;
