import { color } from '../../../../resources';

export const renderStyle = (status) => {
  switch (status) {
    case 'added':
      return { backgroundColor: color.addBg };
    case 'removed':
      return { backgroundColor: color.removeBg };
    default:
      return {};
  }
};
