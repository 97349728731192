import React, { useEffect } from 'react';
import Layout from '../../layout';
import Table from '../../../../../../../components/table-antd';
import { compareCompanyColumn } from './constants/columns';
import { compareCompanyData } from './mock';
import { formatToColumnPattern } from './logic';
import { Button, Input, Space } from 'antd';
import { GET } from '../../../../../../../services';
import { useQuery } from '@tanstack/react-query';
import { useSalesContext } from '../../../contexts/sales-context/context';

const COMPANIES = [
  {
    name: 'JobThai',
    placeholder: 'https://www.jobthai.com/th/company/12345',
  },
  {
    name: 'JobBKK',
    placeholder: 'https://www.jobbkk.com/jobs/profile/12345',
  },
  {
    name: 'JobsDB',
    placeholder: 'https://th.jobsdb.com/companies/examplecompany-1234567890/jobs',
  },
  {
    name: 'JobTopGun',
    placeholder: 'https://www.jobtopgun.com/th/company/12345/jobs',
  },
];

const getCompareCompanyApi = async (companyId) => {
  try {
    const response = await GET(`/tls-company/compare-competitors/${companyId}`);
    return response;
  } catch (error) {
    throw new Error('error');
  }
};

function CompareCompany() {
  const { companyDetails } = useSalesContext();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['compareCompanyData'],
    queryFn: () => getCompareCompanyApi(companyDetails?._id),
  });

  useEffect(() => {
    refetch();
  }, []);

  const formattedData = formatToColumnPattern(data?.result?.data || []);

  return (
    <Layout>
      <Layout.Content>
        <Layout.Header>
          <Layout.Header.Title>ตารางเปรียบเทียบ</Layout.Header.Title>
        </Layout.Header>
        <Space direction="vertical">
          {COMPANIES.map((c, index) => (
            <Space.Compact key={`${c?.name}-${index}`} style={{ width: '100%' }}>
              <Input addonBefore={c?.name} placeholder={c?.placeholder} />
              <Button type="primary">บันทึก </Button>
            </Space.Compact>
          ))}
        </Space>
        <Table columns={compareCompanyColumn()} data={formattedData} loading={isLoading} />
      </Layout.Content>
    </Layout>
  );
}

export default CompareCompany;
