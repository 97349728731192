import React from 'react';
import CompanyProfileLink from '../../../../../../../components/shared/company-profile-link';
import { DateFormat } from '../../../../../../../components/shared/format-text';

export const column = () => {
  return [
    {
      title: 'ชื่อบริษัท',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (label, records) => <CompanyProfileLink label={label} authorId={records?.authorId} />,
    },
    {
      title: 'รหัสบริษัท',
      dataIndex: 'caNumber',
      key: 'caNumber',
    },

    {
      title: 'ขนาดบริษัท',
      dataIndex: 'companySize',
      key: 'companySize',
    },
    {
      title: 'จำนวนพนักงาน',
      dataIndex: 'employeeSize',
      key: 'employeeSize',
    },
    {
      title: 'ประเภทธุรกิจ',
      dataIndex: 'businessType',
      key: 'businessType',
    },
    {
      title: 'วันที่เริ่มใช้งาน',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (data) => <DateFormat date={data} />,
    },
    {
      title: 'แพ็กเกจที่ใช้ล่าสุด',
      dataIndex: 'currentPackage',
      key: 'currentPackage',
    },
    {
      title: 'ยอด',
      dataIndex: 'total',
      key: 'total',
    },
  ];
};
