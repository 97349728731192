import styled from 'styled-components';

export const NoteContainer = styled.div`
  width: 100%;
  height: 200px;
  overflow: auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const FixedContainer = styled.div`
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 10;
  padding: 16px;
  border-top: 1px solid #f0f0f0;
`;

export const ContentBelowContainer = styled.div`
  padding: 16px;
  flex-grow: 1;
  overflow-y: auto;
  background-color: #fff;
`;
