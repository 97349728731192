const { color } = require('../color');

export const OCCASION_LABEL = {
  high: 'สูง',
  medium: 'กลาง',
  low: 'ต่ำ',
};

export const OCCASION_STATUS_COLOR = {
  high: color.edit,
  medium: color.redStatus,
  low: color.grey10,
  high_potential: color.yellowStatus,
  sign: color.blue80,
  done: color.green80,
};
