import React from 'react';
import { column, nestedColumns } from './constants/column';
import { Table } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getJobPromoteApi } from './api';

function PromoteJob() {
  const { data } = useQuery({
    queryKey: ['jobPromote'],
    queryFn: () => getJobPromoteApi(),
  });

  const promoteJobData = data?.result?.data || [];

  return (
    <div>
      <Table
        dataSource={promoteJobData}
        columns={column()}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (record) => (
            <Table
              columns={nestedColumns()}
              dataSource={record.jobs}
              pagination={false}
              rowKey="jobTitle"
            />
          ),
          rowExpandable: (record) => record.jobs.length > 0,
        }}
      />
    </div>
  );
}

export default PromoteJob;
