import React from 'react';
import { ThaiMonthDateFormat } from '../../../../../../../../components/shared/format-text';

export const manageHistoryColumn = () => {
  return [
    {
      title: 'วันที่',
      dataIndex: 'reportDate',
      key: 'reportDate',
      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'รายละเอียด',
      dataIndex: 'reportDetail',
      key: 'reportDetail',
    },
    {
      title: 'ประเภทเรื่อง',
      dataIndex: 'reportTitle',
      key: 'reportTitle',
    },
    {
      title: 'ผู้ดูแลเรื่อง',
      dataIndex: 'supervisor',
      key: 'supervisor',
    },
  ];
};
