import { Drawer, Space } from 'antd';
import React, { useEffect } from 'react';
import Button from '../../../../../../../../../components/button-antd';
import { Yup } from '../../../../../../../../../helpers/yup';
import { RenderForm } from '../../../../../../../../../components/forms';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addContact } from '../../../../../services/contact-api';
import { openAlert } from '../../../../../../../../../components/alert/useAlert';

const FORM_FIELDS = [
  {
    name: 'name',
    label: 'ชื่อผู้ติดต่อ',
    type: 'INPUT',
    placeholder: 'กรอกชื่อผู้ติดต่อ',
    span: 12,
  },
  { name: 'position', label: 'ตำแหน่ง', type: 'INPUT', placeholder: 'ตำแหน่งผู้ติดต่อ', span: 12 },
  {
    name: 'phone',
    label: 'เบอร์โทรศัพท์ติดต่อ',
    type: 'INPUT',
    placeholder: 'กรอกเบอร์โทรศัพท์ติดต่อ',
    span: 12,
    rules: { required: true },
  },
  { name: 'email', label: 'อีเมลติดต่อ', type: 'INPUT', placeholder: 'อีเมลติดต่อ', span: 12 },
  { name: 'isFavorite', type: 'CHECKBOX_SINGLE', span: 24, label: 'ผู้ติดต่อหลัก', value: false },
];

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string(),
  position: Yup.string(),
  phone: Yup.string().required(),
  email: Yup.string().email(),
  isFavorite: Yup.bool(),
});

function AddContactDrawer({ open, onClose, companyId }) {
  const queryClient = useQueryClient();

  const { mutate: addContactMutate, isLoading } = useMutation({
    mutationKey: 'addContact',
    mutationFn: (reqBody) => addContact(reqBody),
    onSuccess: () => {
      openAlert({ type: 'success', message: 'สร้างผู้ติดต่อสำเร็จ' });
      queryClient.invalidateQueries(['contactCompany']);
    },
    onError: () => {
      openAlert({ type: 'error', message: 'เกิดข้อผิดพลาด' });
    },
  });

  const formProps = useForm({ resolver: yupResolver(VALIDATION_SCHEMA) });

  const onSave = () => {
    formProps.handleSubmit((values) => {
      const reqBody = { companyId, ...values };
      addContactMutate(reqBody, { onSuccess: onClose });
    })();
  };

  useEffect(() => {
    formProps.reset();
  }, [open]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title="เพิ่มผู้ติดต่อ"
      width={720}
      extra={
        <Space>
          <Button useFor="cancel" onClick={onClose} />
          <Button useFor="save" onClick={onSave} loading={isLoading} />
        </Space>
      }
    >
      <RenderForm forms={FORM_FIELDS} {...formProps} />
    </Drawer>
  );
}

export default AddContactDrawer;
