import React from 'react';
import CurrentCustomer from './views/current-customer';
import NewCustomer from './views/new-customer';
import Opportunities from './views/opportunities';
import PackageExpiring from './views/package-expiring';
import PromoteJob from './views/promote-job';
import SupportCustomer from './views/support-customer';

export const getRenderTabs = (keys) => {
  return tabsList.filter((tab) => keys.includes(tab.key));
};

const tabsList = [
  {
    label: <span>ช่วยเหลือลูกค้า</span>,
    key: 'support-customer',
    children: <SupportCustomer />,
  },
  { label: 'การ PR งาน', key: 'pr-job', children: <PromoteJob /> },
  {
    label: 'แพ็กเกจ <2 เดือน',
    key: 'package-less-2-m',
    children: <PackageExpiring />,
  },
  {
    label: 'โอกาสในการซื้อ 25%',
    key: 'opportunities-25',
    children: <Opportunities isPrimaryState />,
  },
  { label: 'โอกาสในการซื้อ 50%', key: 'opportunities-50', children: <Opportunities /> },
  { label: 'โอกาสในการซื้อ 75%', key: 'opportunities-75', children: <Opportunities /> },
  { label: 'โอกาสในการซื้อ 95%', key: 'opportunities-95', children: <Opportunities /> },
  { label: 'ปิดการขาย', key: 'opportunities-100', children: <Opportunities /> },
  { label: 'ลูกค้าใหม่', key: 'new-customer', children: <NewCustomer /> },
  { label: '14 วัน', key: 'usage-2-weeks', children: <CurrentCustomer /> },
  { label: '30 วัน ', key: 'usage-1-month', children: <CurrentCustomer /> },
  { label: '3 เดือน ', key: 'usage-3-month', children: <CurrentCustomer /> },
  { label: '6 เดือน', key: 'usage-6-months', children: <CurrentCustomer /> },
  { label: '9 เดือน ', key: 'usage-9-months', children: <CurrentCustomer /> },
];
