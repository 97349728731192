import React from 'react';
import {
  NumberFormat,
  ShortThaiMonthFormat,
  ThaiMonthDateFormat,
} from '../../../../../../../../components/shared/format-text';
import LinkComponent from '../../../../../../../../components/link';
import { EditableCell } from '../components/editable-cell';

export const quotationColumn = ({ renderButton, isEditing, control }) => {
  return [
    {
      title: 'เลขที่ใบเสนอราคา',
      key: 'quotationNo',
      dataIndex: 'quotationNo',
      fixed: 'left',
      render: (data, recrods) => (
        <LinkComponent isNewTab isShowIcon href={recrods?.quotationUrl}>
          {data}
        </LinkComponent>
      ),
    },
    {
      title: 'วันที่ออก',
      key: 'orderCreate',
      dataIndex: 'orderCreate',
      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'วันหมดอายุ',
      key: 'orderDueDate',
      dataIndex: 'orderDueDate',
      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'ยอดรวม (บาท)',
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      render: (data) => <NumberFormat str={data} />,
    },
    {
      title: 'เดือนที่คาดว่าปิด',
      key: 'estimateTime',
      dataIndex: 'estimateTime',
      render: (date, record) =>
        isEditing(record) ? (
          <EditableCell date={date} control={control} />
        ) : (
          <ShortThaiMonthFormat date={date} />
        ),
    },
    {
      key: 'bookmark',
      dataIndex: 'bookmark',
      fixed: 'right',
      render: (data, records) => renderButton(data, records),
    },
  ];
};
