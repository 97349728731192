import React from 'react';
import { ModalTheme } from '../../../../components/modal';
import Table from '../../../../components/table-antd';
import { quotationsColumn } from './column';
import CompanyInfomation from '../company-info';
import { Container, ScrollContainer, StickyContainer } from './styled-component';

function QuotationsModal(props) {
  const { companyDetails, ...modalProps } = props;

  const quotationsData = companyDetails?.quotations || [];

  return (
    <ModalTheme {...modalProps} title="ใบเสนอราคาทั้งหมด" width={1840}>
      <Container>
        <StickyContainer>
          <CompanyInfomation {...companyDetails} />
        </StickyContainer>
        <ScrollContainer>
          <Table data={quotationsData} columns={quotationsColumn()} />
        </ScrollContainer>
      </Container>
    </ModalTheme>
  );
}

export default QuotationsModal;
