import React from 'react';
import { contactData } from './mock';
import Layout from '../../layout';
import Table from '../../../../../components/table-antd';
import { contactColumn } from './constants/columns';
import Button from '../../../../../components/button-antd';

function ContactDetail() {
  return (
    <Layout>
      <Layout.Content>
        <Layout.Header>
          <Layout.Header.Title>ผู้ติดต่อ</Layout.Header.Title>
          <Button useFor="addContact" />
        </Layout.Header>
        <Table columns={contactColumn()} data={contactData} />
      </Layout.Content>
    </Layout>
  );
}

export default ContactDetail;
