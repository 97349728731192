/* eslint-disable react/no-children-prop */
// Button.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonAntd, ConfigProvider, Tooltip } from 'antd';
import { BUTTON_TEMPLATES } from './templates';

function Button({ useFor, isIcon, children, tooltip, className = '', ...props }) {
  const templateConfig = BUTTON_TEMPLATES[useFor] || {};
  const buttonProps = {
    ...templateConfig,
    ...props,
    className: `custom-button ${className}`.trim(),
  };

  if (isIcon) {
    return (
      <ConfigProvider
        theme={
          buttonProps?.bgColor && {
            token: {
              colorPrimary: buttonProps?.bgColor,
            },
          }
        }
      >
        <Tooltip title={tooltip || buttonProps?.children}>
          <ButtonAntd {...buttonProps} shape="circle" children={null} />{' '}
        </Tooltip>
      </ConfigProvider>
    );
  }

  return (
    <ConfigProvider
      theme={
        buttonProps?.bgColor && {
          token: {
            colorPrimary: buttonProps?.bgColor,
          },
        }
      }
    >
      <ButtonAntd {...buttonProps}>{children || buttonProps?.children}</ButtonAntd>
    </ConfigProvider>
  );
}

Button.propTypes = {
  useFor: PropTypes.oneOf(Object.keys(BUTTON_TEMPLATES)),
  isIcon: PropTypes.bool,
  children: PropTypes.node,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'ghost', 'dashed', 'link', 'text', 'default']),
};

Button.defaultProps = {
  isIcon: false,
  tooltip: null,
};

export default Button;
