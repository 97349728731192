export const ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  ACCOUNTING_ADMIN: 'ACCOUNTING_ADMIN',
  TELESALE: 'TELESALE',
  CUSTOMER_SERVICE: 'CUSTOMER_SERVICE',
  PR: 'PR',
};

const ROLE_CONSTANT = {
  [ROLES.SUPER_ADMIN]: {
    label: 'Super admin',
    color: 'red',
  },
  [ROLES.ADMIN]: {
    label: 'Admin',
    color: 'blue',
  },
  [ROLES.ACCOUNTING_ADMIN]: {
    label: 'Accounting admin',
    color: 'green',
  },
  [ROLES.TELESALE]: {
    label: 'Sales',
    color: 'gold',
  },
  [ROLES.CUSTOMER_SERVICE]: {
    label: 'Customer service',
    color: 'yellow',
  },
  [ROLES.PR]: {
    label: 'PR',
    color: 'yellow',
  },
};

export const getRoleDisplay = (role) => {
  return ROLE_CONSTANT[role]?.label || 'ยังไม่ได้ระบุ';
};

export const getColorRole = (role) => {
  return ROLE_CONSTANT[role]?.color || 'white';
};
