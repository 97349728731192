import React from 'react';
import { ButtonTheme } from '../../../../components/buttons';
import { openAlert } from '../../../../components/alert/useAlert';
import { color } from '../../../../resources';
import useSelectItems from './hooks/use-select-items';
import { Modal, Select } from 'antd';

function AddSaleModal(props) {
  const { selectProps, onSave, open, onToggle } = props;
  const { selectValues, isEdited, onSelect } = useSelectItems({
    open: props?.open,
  });

  const onClose = () => {
    if (isEdited) {
      openAlert({
        model: 'confirm',
        title: 'คุณต้องการยกเลิกการทำรายการ ?',
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
        onOk: () => {
          onToggle();
        },
      });
      return;
    }
    onToggle();
  };

  const onClickSave = () => {
    openAlert({
      model: 'confirm',
      title: 'คุณต้องการสร้าง salecode ใช่หรือไม่',
      okText: 'เพิ่ม',
      cancelText: 'ยกเลิก',
      onOk: () => {
        const body = {
          userAdmin: selectValues,
        };
        onSave(body).then(() => {
          onToggle();
        });
      },
    });
  };

  return (
    <Modal
      title="สร้าง salecode"
      open={open}
      onCancel={onClose}
      footer={
        <ButtonTheme
          type="primary"
          color={color.blue80}
          disabled={!selectValues}
          onClick={onClickSave}
          useFor="custom"
          title="สร้าง salecode"
        />
      }
    >
      <div style={{ padding: '30px 0px' }}>
        <p>เลือกแอดมิน</p>
        <Select
          allowClear
          placeholder={selectProps?.placeholder || ''}
          loading={selectProps?.loading || false}
          options={selectProps?.options}
          style={{
            width: '100%',
          }}
          value={selectValues}
          onChange={onSelect}
          filterOption={(inputValue, option) => {
            return option?.label.toLowerCase().includes(inputValue.toLowerCase());
          }}
          maxTagCount="responsive"
        />
      </div>
    </Modal>
  );
}

export default AddSaleModal;
