import React from 'react';
import {
  DateToHoursFormat,
  SecondsToMinutesFormat,
  ThaiMonthDateFormat,
} from '../../../../../../../../components/shared/format-text';
import { Space } from 'antd';

export const historyCall = () => {
  return [
    {
      title: 'ชื่อผู้โทร',
      dataIndex: 'adminName',
      key: 'adminName',
    },
    {
      title: 'วัน/เวลา ที่โทร',
      dataIndex: 'callDate',
      key: 'callDate',

      render: (date) => (
        <Space>
          <ThaiMonthDateFormat date={date} />
          <DateToHoursFormat date={date} />
        </Space>
      ),
    },
    {
      title: 'เวลาที่ใช้โทร',
      dataIndex: 'callTime',
      key: 'callTime',
      render: (seconds) => <SecondsToMinutesFormat seconds={seconds} />,
    },
    {
      title: 'โน๊ต',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'ชื่อผู้ติดต่อ',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: 'เบอร์โทร',
      dataIndex: 'customerPhone',
      key: 'customerPhone',
    },
  ];
};
