import React from 'react';
import { SearchLayout } from './SearchLayout.jsx';
import { TableLayout } from './TableLayout.jsx';
export const PageLayout = ({ searchLayout, tableLayout, cardProps, content, action }) => {
  return (
    <>
      {searchLayout && <SearchLayout props={{ ...searchLayout, cardProps }} />}
      {content}
      {tableLayout && <TableLayout props={{ ...tableLayout, cardProps }} />}
      {action}
    </>
  );
};
