import React from 'react';
import { Col, Divider, List, Row, Select, Skeleton, Space } from 'antd';
import Text from '../../../../components/text-new';
import { ButtonTheme } from '../../../../components/buttons';
import { ModalTheme } from '../../../../components/modal';
import { openAlert } from '../../../../components/alert/useAlert';
import { color, Icon } from '../../../../resources';
import useSelectItems from './hooks/use-select-items';
import * as logic from './logic';

function ManageModal(props) {
  const { selectProps, onSave } = props;
  const { userList, selectValues, isEdited, onSelect, onDelete } = useSelectItems({
    defaultValues: props.defaultValues,
    open: props?.open,
    mappingData: selectProps.mappingData,
    selectValueKey: selectProps?.selectValueKey,
  });

  const onClose = () => {
    if (isEdited) {
      openAlert({
        model: 'confirm',
        title: 'คุณต้องการยกเลิกการทำรายการ ?',
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',

        onOk: () => {
          props.onToggle();
        },
        onCancel: () => {},
      });
      return;
    }
    props.onToggle();
  };

  const onClickSave = () => {
    openAlert({
      model: 'confirm',
      title: 'คุณต้องการบันทึกรายการ ?',
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',

      onOk: () => {
        onSave({
          id: props.id,
          updateValues: userList,
        }).then(() => props.onToggle());
      },
      onCancel: () => {},
    });
  };

  return (
    <ModalTheme
      width={1000}
      {...props}
      onCancel={onClose}
      footer={
        <ButtonTheme
          type="primary"
          color={color.blue80}
          disabled={!isEdited}
          onClick={onClickSave}
          useFor="custom"
          title="บันทึก"
        />
      }
    >
      <div style={{ height: '100%' }}>
        <Space direction="vertical" style={{ width: '100%', padding: '20px 0', height: '100px' }}>
          <Select
            allowClear
            placeholder={selectProps?.placeholder || ''}
            loading={selectProps?.loading || false}
            options={selectProps?.options(props?.defaultValues)}
            mode="multiple"
            style={{
              width: '100%',
            }}
            value={selectValues}
            onChange={onSelect}
            filterOption={(inputValue, option) => {
              return option?.label.toLowerCase().includes(inputValue.toLowerCase());
            }}
            maxTagCount="responsive"
          />
          <Space split={<Divider type="vertical" />}>
            <Text variant="span">
              ทั้งหมด {userList.filter((x) => !x.status || x.status === 'added').length}
            </Text>
          </Space>
        </Space>
        <div
          style={{
            height: 'calc(100% - 100px)',
            overflow: 'auto',
          }}
        >
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={userList}
            renderItem={(item) => (
              <List.Item style={logic.renderStyle(item.status)}>
                <Skeleton avatar title={false} loading={item.loading} active>
                  <Row style={{ width: '100%' }}>
                    <Col flex="30px" style={{ display: 'flex', alignItems: 'center' }}>
                      {item.status === 'added' && <Icon.plus color="green" />}
                      {item.status === 'removed' && <Icon.minus size={14} color="red" />}
                    </Col>

                    <Col flex="auto">{props?.renderRow && props.renderRow(item)}</Col>

                    <Col flex="50px" style={{ textAlign: 'center' }}>
                      <a
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: '100%',
                          justifyContent: 'center',
                        }}
                        key="remove-action-btn"
                        onClick={() => onDelete(item)}
                      >
                        {item.status === 'removed' || item.status === 'added' ? (
                          <Icon.reload size={18} />
                        ) : (
                          <Icon.remove color="red" />
                        )}
                      </a>
                    </Col>
                  </Row>
                </Skeleton>
              </List.Item>
            )}
          />
        </div>
      </div>
    </ModalTheme>
  );
}

export default ManageModal;
