import styled from 'styled-components';
import { color } from '../../../../resources';

export const FixedButton = styled.div`
  border-top: 1px solid ${color.grey200};
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RightColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #f9f9f9;
`;

export const FixedContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
`;

export const ContentBelowContainer = styled.div`
  padding: 16px;
  flex-grow: 1;
  overflow-y: auto;
  background-color: #fff;
`;
