import React, { useState } from 'react';
import { Space } from 'antd';
import { TabStyled } from './styled-component';
import ContactDetail from './contact-detail';
import CompareCompany from './compare-company';
import JobPosition from './job-position';
import ManageHistory from './manage-history';
import HistoryCall from './history-call';
import Package from './package';
import Quotation from './quotation';
import Button from '../../../../../../components/button-antd';
import ReportCaseDrawer from './components/report-case-drawer';
import { useSalesContext } from '../../contexts/sales-context/context';
import PromoteJob from './promote-job';
import { useRole } from '../../../../../../hooks/useRole';

function Tabs() {
  const { companyDetails } = useSalesContext();
  const { isCS, isPR, isTelesale, isSuperAdmin } = useRole();
  const [openReportCaseDrawer, setOpenReportCaseDrawer] = useState(false);

  const showReportCaseDrawer = () => setOpenReportCaseDrawer(true);
  const closeReportCaseDrawer = () => setOpenReportCaseDrawer(false);

  const getTabItems = () => {
    if (isCS) {
      return [
        {
          key: 'contact',
          label: 'ผู้ติดต่อ',
          children: <ContactDetail />,
        },
        {
          key: 'history-call',
          label: 'ประวัติการโทร',
          children: <HistoryCall />,
        },
        {
          key: 'compare-company',
          label: 'เปรียบเทียบคู่แข่ง',
          children: <CompareCompany />,
        },
        {
          key: 'job-position',
          label: 'ตำแหน่งงาน',
          children: <JobPosition />,
        },
        {
          key: 'package',
          label: 'แพ็กเกจ',
          children: <Package />,
        },
        {
          key: 'quotation',
          label: 'ใบเสนอราคา',
          children: <Quotation />,
        },
        {
          key: 'manage-history',
          label: 'ประวัติการจัดการ',
          children: <ManageHistory />,
        },
      ];
    }
    if (isPR) {
      return [
        {
          key: 'promote-job',
          label: 'โปรโมทงาน',
          children: <PromoteJob />,
        },
        {
          key: 'manage-history',
          label: 'ประวัติการจัดการ',
          children: <ManageHistory />,
        },
      ];
    }
    if (isTelesale) {
      return [
        {
          key: 'contact',
          label: 'ผู้ติดต่อ',
          children: <ContactDetail />,
        },
        {
          key: 'history-call',
          label: 'ประวัติการโทร',
          children: <HistoryCall />,
        },
        {
          key: 'compare-company',
          label: 'เปรียบเทียบคู่แข่ง',
          children: <CompareCompany />,
        },
        {
          key: 'job-position',
          label: 'ตำแหน่งงาน',
          children: <JobPosition />,
        },
        {
          key: 'package',
          label: 'แพ็กเกจ',
          children: <Package />,
        },
        {
          key: 'quotation',
          label: 'ใบเสนอราคา',
          children: <Quotation />,
        },
        {
          key: 'manage-history',
          label: 'ประวัติการจัดการ',
          children: <ManageHistory />,
        },
      ];
    }

    if (isSuperAdmin) {
      return [
        {
          key: 'promote-job',
          label: 'โปรโมทงาน',
          children: <PromoteJob />,
        },
        {
          key: 'contact',
          label: 'ผู้ติดต่อ',
          children: <ContactDetail />,
        },
        {
          key: 'history-call',
          label: 'ประวัติการโทร',
          children: <HistoryCall />,
        },
        {
          key: 'compare-company',
          label: 'เปรียบเทียบคู่แข่ง',
          children: <CompareCompany />,
        },
        {
          key: 'job-position',
          label: 'ตำแหน่งงาน',
          children: <JobPosition />,
        },
        {
          key: 'package',
          label: 'แพ็กเกจ',
          children: <Package />,
        },
        {
          key: 'quotation',
          label: 'ใบเสนอราคา',
          children: <Quotation />,
        },
        {
          key: 'manage-history',
          label: 'ประวัติการจัดการ',
          children: <ManageHistory />,
        },
      ];
    }
    return [];
  };

  return (
    <>
      <TabStyled
        type="card"
        items={getTabItems()}
        defaultActiveKey="contact"
        destroyInactiveTabPane
        tabBarExtraContent={
          <Space>
            <Button useFor="reportCase" onClick={showReportCaseDrawer} />
          </Space>
        }
      />
      <ReportCaseDrawer
        open={openReportCaseDrawer}
        onClose={closeReportCaseDrawer}
        companyId={companyDetails?._id}
      />
    </>
  );
}

export default Tabs;
