export const mockData = [
  {
    id: '1',
    description: 'ติดตามการทำ Pin Map',
    companyName: 'Jobfinfin',
    sender: 'SALE | ลี่-Montol',
    sentDate: '2024-01-10',
    closedDate: null,
    closer: null,
    status: 'cancel',
  },
  {
    id: '2',
    description: 'ราคาวัสดุโครงสร้าง',
    companyName: 'GMM',
    sender: 'CS | เจี๊ยบ-Apisada',
    sentDate: '2024-01-10',
    closedDate: null,
    closer: null,
    status: 'pending',
  },
  {
    id: '3',
    description: 'เงื่อนไขการขนส่งโครงสร้างใหม่',
    companyName: 'Coppercide',
    sender: 'SALE | ผึ้ง-Pornsita',
    sentDate: '2024-01-10',
    closedDate: null,
    closer: null,
    status: 'pending',
  },
  {
    id: '4',
    description: 'แผนงานบริการหมายเลข 0172345678',
    companyName: 'Nexon',
    sender: 'S.Admin | อร-Narida',
    sentDate: '2024-01-09',
    closedDate: null,
    closer: null,
    status: 'pending',
  },
  {
    id: '5',
    description: 'แผนงานบริการหมายเลข 01767654321',
    companyName: 'ชาเขียว-ภูเก็ตฮิลส์',
    sender: 'SALE | ลี่-Montol',
    sentDate: '2024-01-09',
    closedDate: null,
    closer: null,
    status: 'pending',
  },
  {
    id: '6',
    description: 'แผนประจำปี Issue 3',
    companyName: 'Audi Thailand',
    sender: 'CS | เจี๊ยบ-Apisada',
    sentDate: '2024-01-08',
    closedDate: '2024-01-11',
    closer: 'SALE | ลี่-Montol',
    status: 'success',
  },
  {
    id: '7',
    description: 'คนทำใบเสนอราคา',
    companyName: 'TNP CAR and Auto Service',
    sender: 'S.Admin | อร-Narida',
    sentDate: '2024-01-07',
    closedDate: '2024-01-09',
    closer: 'S.Admin | อร-Narida',
    status: 'success',
  },
  {
    id: '8',
    description: 'แก้ URL ลิ้ง งานขายโปรโมชันค่ะ',
    companyName: 'Nexon',
    sender: 'SALE | ลี่-Montol',
    sentDate: '2024-01-05',
    closedDate: '2024-01-07',
    closer: 'CS | เจี๊ยบ-Apisada',
    status: 'success',
  },
];
