import React, { useEffect, useState } from 'react';
import Layout from '../../layout';
import Table from '../../../../../../../components/table-antd';
import { quotationColumn } from './constants/columns';
import { Space, Typography } from 'antd';
import { useSalesContext } from '../../../contexts/sales-context/context';
import { GET, PUT } from '../../../../../../../services';
import { errorAlert } from '../../../../../../../utils/api/msg';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Button from '../../../../../../../components/button-antd';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Yup } from '../../../../../../../helpers/yup';
import dayjs from 'dayjs';
import { color } from '../../../../../../../resources';

const getQuotationsApi = async (companyId) => {
  if (!companyId) return;

  try {
    const response = await GET(`/tls-company/quotations/${companyId}`);
    return response;
  } catch (err) {
    errorAlert();
  }
};

const updateQuotationApi = async (reqBody) => {
  try {
    const response = await PUT(`/tls-company/update-company/quotations`, reqBody);
    return response;
  } catch (err) {
    errorAlert();
  }
};

const quotationSchema = Yup.object({
  estimateTime: Yup.date().required('กรุณาเลือกเดือนที่คาดว่าปิด'),
});

function Quotation() {
  const { companyDetails } = useSalesContext();

  const queryClient = useQueryClient();
  const [editingKey, setEditingKey] = useState('');
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(quotationSchema),
  });

  const { data, isLoading: isGetQuotationsLoading } = useQuery({
    queryKey: ['quotations', companyDetails?._id],
    queryFn: () => getQuotationsApi(companyDetails?._id),
    enabled: !!companyDetails?._id,
  });

  const { mutate: updateQuotation } = useMutation({
    mutationKey: ['updateQuotation'],
    mutationFn: (reqBody) => updateQuotationApi(reqBody),
    onSuccess: () => {
      queryClient.invalidateQueries(['quotations', companyDetails?._id]);
      setEditingKey('');
    },
  });

  const quotations = data?.result?.quotations || [];

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    setValue('estimateTime', record.estimateTime ? dayjs(record.estimateTime) : null);
    setEditingKey(record._id);
  };

  const handleCancel = () => {
    reset();
    setEditingKey('');
  };

  const handleUpdate = (values, record) => {
    updateQuotation({
      companyId: companyDetails?._id,
      quotations: [
        {
          ...record,
          estimateTime: values.estimateTime?.toISOString(),
        },
      ],
    });
  };

  const handleToggleBookmark = (record) => {
    updateQuotation({
      companyId: companyDetails?._id,
      quotations: [
        {
          ...record,
          isSaleClosed: !record?.isSaleClosed,
        },
      ],
    });
  };

  const renderButton = (_, record) => {
    const editable = isEditing(record);

    return (
      <Space>
        {editable ? (
          <Space>
            <Button
              isIcon
              useFor="save"
              onClick={handleSubmit((values) => handleUpdate(values, record))}
            />
            <Button isIcon useFor="cancel" onClick={handleCancel} />
          </Space>
        ) : (
          <Space>
            <Button
              useFor="custom"
              shape="circle"
              icon={
                record?.isSaleClosed ? (
                  <StarFilled style={{ color: color.yellowStatus }} />
                ) : (
                  <StarOutlined style={{ color: color.yellowStatus }} />
                )
              }
              disabled={editingKey !== ''}
              onClick={() => handleToggleBookmark(record)}
            />
            <Button
              isIcon
              useFor="edited"
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            />
          </Space>
        )}
      </Space>
    );
  };

  return (
    <Layout>
      <Layout.Content>
        <Layout.Header>
          <Layout.Header.Title>ใบเสนอราคา</Layout.Header.Title>
          <Typography.Text disabled>ใบเสนอราคาที่คาดว่าปิดได้</Typography.Text>
        </Layout.Header>

        <Table
          columns={quotationColumn({
            renderButton,
            isEditing,
            control,
          })}
          data={quotations}
          loading={isGetQuotationsLoading}
        />
      </Layout.Content>
    </Layout>
  );
}

export default Quotation;
