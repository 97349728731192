import { Yup } from '../../../../../../../../helpers/yup';

export const FORM = () => {
  return [
    {
      name: 'requestType',
      label: 'ถึง',
      type: 'DROPDOWN',
      span: 24,
      defaultValue: 'CUSTOMER_SERVICE',
      properties: {
        options: [
          { value: 'CUSTOMER_SERVICE', label: 'Customer service' },
          { value: 'TELESALE', label: 'Sales' },
        ],
        valueKey: 'value',
        labelKey: 'label',
      },
      rules: {
        required: true,
      },
    },
    {
      name: 'detail',
      label: 'รายละเอียด',
      type: 'INPUT_AREA',
      placeholder: 'กรอกรายละเอียดเรื่องที่ต้องการแจ้ง',
      rows: 6,
      span: 24,
      rules: {
        required: true,
      },
    },
  ];
};

export const VALIDATION = Yup.object().shape({
  requestType: Yup.string().required(),
  detail: Yup.string().required(),
});
