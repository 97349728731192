import React from 'react';
import { ThaiMonthDateFormat } from '../../../../../../../../components/shared/format-text';
import { Button, Space, Typography } from 'antd';
import StatusTag from '../../../../../../../../components/shared/status-tag';

export const PromoteJobColumn = ({ handleSendPromote }) => {
  return [
    {
      title: 'แพ็กเกจ',
      dataIndex: 'package',
      key: 'package',
      render: (status) => <StatusTag type="packageApplicant" status={status} />,
    },
    {
      title: 'ชื่อประกาศงาน',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
    },
    {
      title: 'สถานะการโปรโมท',
      dataIndex: 'promoteStatus',
      key: 'promoteStatus',
      render: (status) => <StatusTag type="promoteStatus" status={status} />,
    },
    {
      title: 'วันที่โพสต์',
      dataIndex: 'postDate',
      key: 'postDate',
      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'วันที่อัปเดตล่าสุด',
      dataIndex: 'updateDate',
      key: 'updateDate',
      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'วันที่ของคำขอ',
      dataIndex: 'requestPrDate',
      key: 'requestPrDate',
      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <StatusTag type="jobStatus" status={status} />,
    },
    {
      title: 'ผู้สมัคร',
      dataIndex: 'candidate',
      key: 'candidate',
      fixed: 'right',
      render: (data, records) => (
        <Space size={8}>
          <Typography.Text>{data}</Typography.Text>
          <Typography.Text type="success">(+{records.candidateIncrease})</Typography.Text>
        </Space>
      ),
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      render: (data, records) => (
        <Button
          disabled={
            records?.promoteStatus === 'PR' ||
            records?.promoteStatus === 'PENDING' ||
            records?.status === 'close'
          }
          onClick={handleSendPromote}
        >
          ส่งงาน
        </Button>
      ),
    },
  ];
};
