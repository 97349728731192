import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { RenderForm } from '../../../../../../../../../components/forms';
import { FORM, VALIDATION } from './form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Flex, Space } from 'antd';

function CompareCompanyForm({ defaultValues }) {
  const formProps = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(VALIDATION),
  });

  useEffect(() => {
    formProps.reset();
  }, []);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <RenderForm forms={FORM()} {...formProps} />
      <Flex style={{ width: '100%' }} justify="flex-end">
        <Button disabled={!formProps?.formState?.isDirty} type="primary">
          บันทึก
        </Button>
      </Flex>
    </Space>
  );
}

CompareCompanyForm.propTypes = {
  defaultValues: PropTypes.object,
};

CompareCompanyForm.defaultProps = {
  defaultValues: {},
};

export default CompareCompanyForm;
