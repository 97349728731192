import { useEffect, useState } from 'react';
import { queryClient } from '../lib/tanstack';
import { useQueries, useQuery } from '@tanstack/react-query';
import { GET } from '../services';

const optionEndpoints = {
  stageManual: 'stage-manual',
  stageAuto: 'stage-auto',
  keySuccess: 'key-success',
  purchaseOccasion: 'purchase-occasion',
  jobCat: 'job-cat',
  competitors: 'competitors',
  knowUs: 'know-us',
  quotation: 'quotation',
  admin: 'admin',
  callStatus: 'call-status',
  headcountRange: 'headcount-range',
};

export const getOption = async (optKey) => {
  try {
    const response = await GET(`/options/${optKey}`);
    if (!response?.success) throw new Error(`Failed to fetch ${optKey}`);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

export const useOption = (type) => {
  if (!optionEndpoints[type]) return null;

  return useQuery({
    queryKey: ['options', type],
    queryFn: async () => {
      const response = await getOption(optionEndpoints[type]);
      return response?.result?.data;
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useOptions = () => {
  const queries = Object.entries(optionEndpoints).map(([key, endpoint]) => {
    return {
      queryKey: ['options', key],
      queryFn: async () => {
        const response = await getOption(endpoint);
        return response?.result?.data;
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    };
  });

  if (!queries) return;

  const queryResults = useQueries({
    queries: queries,
  });

  const options = queryResults.reduce((acc, result, index) => {
    const key = Object.keys(optionEndpoints)[index];

    acc[key] = {
      data: result.data,
      isLoading: result.isLoading,
      error: result.error,
    };
    return acc;
  }, {});

  return {
    options: options || [],
  };
};

export const useInitializeOptions = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const prefetchOptions = async () => {
      try {
        await Promise.all(
          Object.entries(optionEndpoints).map(([key, endpoint]) =>
            queryClient.prefetchQuery({
              queryKey: ['options', key],
              queryFn: async () => {
                const response = await getOption(endpoint);
                return response?.result?.data;
              },
              staleTime: Infinity,
              cacheTime: Infinity,
            }),
          ),
        );
        setInitialized(true);
      } catch (error) {
        console.error('Failed to initialize options:', error);
      }
    };

    prefetchOptions();
  }, [queryClient]);

  return { initialized };
};
