import React from 'react';
import { ButtonIconTheme } from '../../../../components/buttons';
import Row from '../../../../components/row';
import { color, Icon } from '../../../../resources';

function TableActions({ onClick }) {
  return (
    <Row justifyContent="center" alignItems="center" gap="8px">
      <ButtonIconTheme
        type="primary"
        useFor="CUSTOM"
        color={color.edit}
        text="จัดการสิทธิ์"
        icon={<Icon.editCall />}
        onClick={onClick}
      />
    </Row>
  );
}

export default TableActions;
