import { create } from 'apisauce';
import { getQueryString } from '../functions';
import { getCookies } from '../store/useCookies';
import { env } from '../utils/env/config';

export const CONNECT_API = env.url;
export const CONNECT_API_COMPANY = env.company_web;

const TIME_OUT = 300000;

const api = create({
  baseURL: CONNECT_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: TIME_OUT,
});

const apiupload = create({
  baseURL: CONNECT_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */
export const POST = (path, obj, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    api
      .post(path, obj, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */
export const PUT = (path, obj, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    api
      .put(path, obj, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */
export const GET = (path, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    api
      .get(
        path,
        {},
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/**
 * Function for fetching data with query parameters
 * @param {string} path - The API endpoint path
 * @param {Object} params - An object containing query parameters
 * @param {string} token - The authentication token (optional)
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error
 */
export const GET_WITH_PARAMS = (
  path,
  params,
  headers = {},
  config = {},
  token = getCookies('accessToken'),
) =>
  new Promise((resolve, reject) => {
    const queryString = Object.keys(params)
      .map((key) => {
        if (Array.isArray(params[key])) {
          return params[key]
            .map((value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      })
      .join('&');

    const fullPath = `${path}${queryString ? `?${queryString}` : ''}`;

    api
      .get(
        fullPath,
        {},
        {
          ...config,
          headers: {
            Authorization: token,
            ...headers,
          },
        },
      )
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

export const UPLOAD = (path, formdata, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    apiupload
      .post(path, formdata, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ลบข้อมูล
 * @param {string} path
 *
 */
export const DELETE = (path, token = getCookies('accessToken')) => {
  return new Promise((resolve, reject) => {
    api
      .delete(
        path,
        {},
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });
};

/**
 * ฟังก์ชั่นสำหรับ ลบข้อมูล
 * @param {obj} path
 *
 */
export const DELETE_WITH_PARAMS = (path, obj, token = getCookies('accessToken')) => {
  const parseParams = (params) => {
    let options = '';

    for (const [key, value] of Object.entries(params)) {
      if (Array.isArray(value)) {
        for (const element of value) {
          options += `${key}=${element}&`;
        }
      } else {
        options += `${key}=${value}&`;
      }
    }

    return options.slice(0, -1);
  };

  return new Promise((resolve, reject) => {
    api
      .delete(path, obj, {
        headers: {
          Authorization: token,
        },
        paramsSerializer: (params) => parseParams(params),
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });
};

export const POST_CUSTOM = (apiDomain, path, obj, token) => {
  const apiCustom = create({
    baseURL: apiDomain,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: TIME_OUT,
  });

  return new Promise((resolve, reject) => {
    apiCustom
      .post(path, obj, {
        headers: {
          Authorization: token || getCookies('accessToken'),
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });
};

/* ################################################## URL ################################################## */
export const LOGIN = `/authen/login`;
export const LOGIN_GOOGLE = `/authen/login-google`;
export const REGISTER = `/authen/register`;
export const GET_PROFILE = `authen/get-profile`;
export const GET_ALL_PRODUCT = `/product/get-all`;
export const GET_PRODUCT_SEARCH = (obj) => `/product/search?${getQueryString(obj)}`;

export const GET_ALL_POST = `/posts`;
export const INSERT_POST = `/posts`;
export const UPDATE_POST = (id) => `/posts/${id}`;
export const GET_POST_BY_ID = (id) => `/posts/${id}`;

export const CHECK_TOKEN_LOGIN = '/authen/check/access';
