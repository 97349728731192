import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ContentStyle } from '../../style';
import NotfoundScene from '../../../../screens/not-found';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { useSelector } from 'react-redux';

const ContentLayout = ({ getTheme, sidebarMode }) => {
  const { user } = useSelector((state) => state.authen);

  if (!user.role) {
    return null;
  }

  return (
    <ContentStyle style={{ ...getTheme.content[sidebarMode] }}>
      <Switch>
        {Object.values(ROUTES_PATH).map((route) => (
          <Route
            exact
            key={route.KEY}
            path={route.PATH}
            render={(props) => {
              if (route.ACCESS_ROLES && route.ACCESS_ROLES.includes(user.role)) {
                return <route.COMPONENT {...props} />;
              }
              return <Redirect to="/not-found" />;
            }}
          />
        ))}
        <Route path="*" key="NOT_FOUND" component={NotfoundScene} />
      </Switch>
    </ContentStyle>
  );
};

export default ContentLayout;
