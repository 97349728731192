import React from 'react';
import {
  CalledStatusColumn,
  CheckedColumn,
  DateFormatColumn,
  FollowUpColumn,
  JoinColumn,
  LastCallColumn,
  NoteColumn,
  QuotationColumn,
  SaleNamesColumn,
  TotalInformation,
} from '../components/render-column';
import Text from '../../../../../components/text-new';
import { Button, Typography } from 'antd';
import CompanyProfileLink from '../../../../../components/shared/company-profile-link';
import CompanyBadge from '../../../../../components/shared/company-badge';
import { NumberFormat, ThaiMonthDateFormat } from '../../../../../components/shared/format-text';
import StatusTag from '../../../../../components/shared/status-tag';
import LinkComponent from '../../../../../components/link';

export const column = ({ modalEvent, salesCodeOption, pageStep }) => {
  return [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      skipExport: true,
      render: (text, record, index) => <Text variant="span">{index + pageStep}</Text>,
    },
    {
      title: 'ชื่อบริษัท',
      dataIndex: 'company',
      key: 'company',
      align: 'center',
      width: '250px',
      fixed: 'left',
      render: (data) => <CompanyProfileLink label={data?.name} authorId={data?.authorId} />,
    },
    {
      title: 'CA',
      dataIndex: 'caNumber',
      key: 'caNumber',
      align: 'center',
      render: (_, record) => <Typography.Text>{record?.company?.caNumber}</Typography.Text>,
    },
    {
      title: 'สถานะบริษัท',
      dataIndex: 'status',
      key: 'company',
      align: 'center',
      render: (_, record) => <CompanyBadge status={record?.company?.status} isFullLabel />,
    },
    {
      title: 'วันที่สมัคร',
      dataIndex: 'registerDate',
      key: 'registerDate',
      align: 'center',
      render: (_, record) => <ThaiMonthDateFormat date={record?.company?.createDate} />,
    },
    {
      title: 'Stage Auto',
      dataIndex: 'stage',
      key: 'stage',
      align: 'center',
      render: (status) => <StatusTag type="stageAuto" status={status} />,
    },
    {
      title: 'Key Success',
      dataIndex: 'keySuccess',
      key: 'keySuccess',
      align: 'center',
      render: (status) => <StatusTag type="keySuccess" status={status} />,
    },
    {
      title: 'Stage Manual',
      dataIndex: 'stageManual',
      key: 'stageManual',
      align: 'center',
      render: (status) => <StatusTag type="stageManual" status={status} />,
    },
    {
      title: 'โอกาสในการซื้อ',
      dataIndex: 'purchaseOccasion',
      key: 'purchaseOccasion',
      align: 'center',
      render: (status) => <StatusTag isBadge type="purchaseOccasion" status={status} />,
    },
    {
      title: 'Logo',
      dataIndex: 'isHaveLogo',
      key: 'isHaveLogo',
      align: 'center',
      render: (_, record) => <CheckedColumn value={record?.company?.isHaveLogo} />,
    },
    {
      title: 'Cover',
      dataIndex: 'isHaveCover',
      key: 'isHaveLogo',
      align: 'center',
      render: (_, record) => <CheckedColumn value={record?.company?.isHaveCover} />,
    },
    {
      title: 'เข้าใช้งานล่าสุด',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      align: 'center',
      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'ประเภทธุรกิจ',
      dataIndex: 'businessType',
      key: 'businessType',
      align: 'center',
    },
    {
      title: 'จำนวนพนักงาน',
      dataIndex: 'headcountRangeCode',
      key: 'headcountRangeCode',
      align: 'center',
      render: (status) => <StatusTag type="headcountRange" status={status} />,
    },
    {
      title: 'ประกาศงานที่เปิด',
      dataIndex: 'totalJobPost',
      key: 'totalJobPost',
      align: 'center',
      render: (str) => <NumberFormat str={str} />,
    },
    {
      title: 'เครดิตซื้อที่ใช้',
      dataIndex: 'totalCreditUse',
      key: 'totalCreditUse',
      align: 'center',

      render: (str) => <NumberFormat str={str} />,
    },
    {
      title: 'เครดิตฟรีที่ใช้',
      dataIndex: 'totalCreditFree',
      key: 'totalCreditFree',
      align: 'center',

      render: (str) => <NumberFormat str={str} />,
    },
    {
      title: 'ยอดผู้สมัคร',
      dataIndex: 'candidate',
      key: 'candidate',
      align: 'center',
      render: (str, records) => (
        <NumberFormat
          str={str}
          unit="คน"
          onClick={() => modalEvent.openApplicantModal({ companyDetails: records })}
        />
      ),
    },
    {
      title: 'ยอดซื้อ',
      dataIndex: 'totalPurchase',
      key: 'totalPurchase',
      align: 'center',
      render: (str) => <NumberFormat str={str} unit="บาท" />,
    },
    {
      title: 'Package / Item',
      dataIndex: 'allPackage',
      key: 'allPackage',
      align: 'center',
      render: (_, records) => (
        <LinkComponent
          isBlue
          isUnderline
          onClick={() => modalEvent.openPackageModal({ companyDetails: records })}
        >
          ดูเพิ่มเติม
        </LinkComponent>
      ),
    },
    {
      title: 'Follow up date',
      dataIndex: 'followUpDate',
      key: 'followUp',
      align: 'center',
      render: (data) => <FollowUpColumn data={data} />,
    },
    {
      title: 'สถานะการติดต่อล่าสุด',
      dataIndex: 'calledStatus',
      key: 'calledStatus',
      align: 'center',
      render: (data) => <CalledStatusColumn data={data} />,
    },
    {
      title: 'คนที่แก้ไขล่าสุด',
      dataIndex: 'salesName',
      key: 'salesName',
      align: 'center',
    },
    {
      title: 'ผู้ดูแล',
      dataIndex: 'saleCodes',
      key: 'saleCodes',
      align: 'center',
      width: '200px',
      render: (data) => <SaleNamesColumn values={data} options={salesCodeOption?.data} />,
    },
    {
      title: 'โทรล่าสุด',
      dataIndex: 'lastCall',
      key: 'lastCall',
      align: 'center',
      render: (data) => <LastCallColumn data={data} />,
    },
    {
      title: 'โน้ตล่าสุด',
      dataIndex: 'lastNote',
      key: 'lastNote',
      align: 'center',
      render: (data) => <NoteColumn data={data} />,
    },
    {
      title: 'โน้ตข้อความเพิ่มเติม',
      dataIndex: 'additionalNote',
      key: 'additionalNote',
      align: 'center',
      render: (data) => <NoteColumn data={data} />,
    },

    {
      title: 'ใบเสนอราคา',
      dataIndex: 'quotations',
      key: 'quotations',
      align: 'center',
      width: '200px',
      render: (data, records) => (
        <QuotationColumn
          data={data}
          onAction={() =>
            modalEvent.openQuotationModal({ companyDetails: records, quotations: data })
          }
        />
      ),
    },
    {
      title: 'ช่องทางที่รู้จัก JFF',
      dataIndex: 'knowUs',
      key: 'knowUs',
      align: 'center',
      width: '300px',
      render: (data) => <JoinColumn data={data} />,
    },
    {
      title: 'คนที่ได้จาก JFF/คน',
      dataIndex: 'totalCandidate',
      key: 'totalCandidate',
      align: 'center',
    },
    {
      title: 'ตำแหน่งที่ได้จาก JFF',
      dataIndex: 'positionReceived',
      key: 'positionReceived',
      align: 'center',
    },
    {
      title: 'ประกาศที่ใช้เฉลี่ยต่อเดือน',
      dataIndex: 'avgJobPost',
      key: 'avgJobPost',
      align: 'center',
      render: (data) => <TotalInformation total={data} text="ประกาศ/เดือน" />,
    },
    {
      title: 'เครดิตค้นหาที่ใช้เฉลี่ยต่อเดือน',
      dataIndex: 'avgSearch',
      key: 'avgSearch',
      align: 'center',
      render: (data) => <TotalInformation total={data} text="เครดิต/เดือน" />,
    },
    {
      title: 'ตำแหน่งที่หา(ประจำ)',
      dataIndex: 'favPosition',
      key: 'favPosition',
      align: 'center',
    },
    {
      title: 'สายงานที่หา',
      dataIndex: 'findPosition',
      key: 'findPosition',
      align: 'center',
      width: '300px',
      render: (data) => <JoinColumn data={data} />,
    },
    {
      title: 'เว็บหางานอื่นๆที่ใช้',
      dataIndex: 'competitors',
      key: 'competitors',
      align: 'center',
      width: '300px',
      render: (data) => <JoinColumn data={data} />,
    },
    {
      title: 'รอบอนุมัติงบประมาณ',
      dataIndex: 'budgetPeriod',
      key: 'budgetPeriod',
      align: 'center',
      render: (data) => <DateFormatColumn date={data} />,
    },
    {
      title: 'งบประมาณ',
      dataIndex: 'budget',
      key: 'budget',
      align: 'center',
      render: (data) => <TotalInformation total={data} text="บาท/รอบ" />,
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: '160px',
      skipExport: true,
      render: (_, records) => (
        <Button
          type="primary"
          ghost
          onClick={() => modalEvent.openSalesModal({ companyDetails: records })}
        >
          จัดการบริษัท
        </Button>
      ),
    },
  ];
};
