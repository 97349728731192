import React from 'react';
import CompanySales from '../views/company-sales';
import AdminSales from '../views/admin-sales';

export const tabRenderItems = [
  {
    label: 'บริษัท',
    key: 1,
    children: <CompanySales />,
  },
];

export const tabRenderItemsFullAccess = [
  ...tabRenderItems,
  {
    label: 'แอดมิน',
    key: 2,
    children: <AdminSales />,
  },
];
