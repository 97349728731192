import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Fragment } from 'react';
import { App as AppAntD } from 'antd';
import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { navigationRef } from './utils/navigation';
import GlobalStyle from './styles/global-style';
import HandleScene from './screens/handle';
import store from './redux/store.js';
import Alert from './components/alert/alert';
import Loading from './components/loading/loading.jsx';
import LayoutPage from './components/layoutPage';
import * as ROUTES from './resources/routes-name';
import { ModalProvider } from './contexts/modal-context';
import { queryClient } from './lib/tanstack';
import { useInitializeOptions } from './hooks/useOptions';

const AppContainer = () => {
  useInitializeOptions();

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <ModalProvider>
          <Loading>
            <Alert />
            <Router ref={navigationRef}>
              <div />
              <Switch>
                <Route exact path={ROUTES.ROUTE_HANDLE} component={HandleScene} />
                <Route exact component={LayoutPage} />
              </Switch>
            </Router>
          </Loading>
        </ModalProvider>
      </Provider>
    </QueryClientProvider>
  );
};

const App = () => {
  return (
    <Fragment>
      <AppAntD>
        <AppContainer />
        <GlobalStyle />
      </AppAntD>
    </Fragment>
  );
};

export default App;
