import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
`;

export const HeaderBox = styled.div`
  ${(props) =>
    props.height &&
    css`
      height: ${props.height}px;
    `}
`;

export const ScrollBox = styled.div`
  ${(props) =>
    props.height &&
    css`
      height: calc(100% - ${props.height + 8}px);
    `};

  overflow: auto;
`;
