import { Table as TableAntd } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getStored } from '../../store/useLocalStored';
import { TableCustom } from './styled-component';

function renderStripe(_, index) {
  if (index % 2 == 0) {
    return getStored('theme') === 'dark' ? 'stripe-row-black' : 'stripe-row-grey';
  }
}
function Table(props) {
  const { data, columns, getSelectedRows, ...tableProps } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    if (getSelectedRows) {
      getSelectedRows(selectedRows);
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = getSelectedRows
    ? {
        selectedRowKeys,
        onChange: onSelectChange,
      }
    : undefined;

  return (
    <TableCustom
      rowClassName={renderStripe}
      columns={columns}
      dataSource={data}
      rowSelection={rowSelection}
      {...tableProps}
      bordered
      scroll={{ x: 'max-content' }}
    />
  );
}

Table.Summary = TableAntd.Summary;

Table.PropTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  getSelectedRows: PropTypes.func,
};
Table.defaultProps = {
  data: [],
  columns: [],
};

export default Table;
