import React from 'react';
import StatusTag from '../../../../components/shared/status-tag';
import { ThaiMonthDateFormat } from '../../../../components/shared/format-text';

export const packageItemColumn = () => {
  return [
    {
      title: 'Package / Item',
      dataIndex: 'packageName',
      key: 'packageName',
    },
    {
      title: 'วันที่ซื้อ',
      dataIndex: 'packageStart',
      key: 'packageStart',

      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'วันหมดอายุ',
      dataIndex: 'packageExp',
      key: 'packageExp',

      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'ระยะเวลา',
      dataIndex: 'packageDuration',
      key: 'packageDuration',
    },
    {
      title: 'สถานะ',
      dataIndex: 'packageStatus',
      key: 'packageStatus',

      render: (status) => <StatusTag type="package" status={status} />,
    },
  ];
};
