import { GET } from '../../../../services';

export const totalNotiAPI = async () => {
  try {
    const response = await GET(`/tls-company/total-noti`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};
