import React from 'react';
import StatusTag from '../../../../../../../components/shared/status-tag';
import CompanyProfileLink from '../../../../../../../components/shared/company-profile-link';

export const column = () => {
  return [
    {
      title: 'ชื่อบริษัท',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (label, records) => <CompanyProfileLink label={label} authorId={records?.authorId} />,
    },
  ];
};

export const nestedColumns = () => {
  return [
    {
      title: 'ชื่อตำแหน่งงาน',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      width: '40%',
    },
    {
      title: 'ผู้ส่งเรื่อง',
      dataIndex: 'contact',
      key: 'contact',
    },
    {
      title: 'วันที่ส่งเรื่อง',
      dataIndex: 'issueDate',
      key: 'issueDate',
    },
    {
      title: 'วันที่ปิดงาน',
      dataIndex: 'jobDate',
      key: 'jobDate',
    },
    {
      title: 'ยอดผู้สมัครก่อน PR',
      dataIndex: 'pr1',
      key: 'pr1',
    },
    {
      title: 'ยอดผู้สมัครหลัง PR',
      dataIndex: 'pr2',
      key: 'pr2',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',

      render: (status) => <StatusTag type="pr" status={status} />,
    },
  ];
};
