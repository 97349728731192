import { MODAL_TYPES } from '../constants/modal-types';

function useModalEvent(open) {
  const openTrackingModal = (modalProps) => {
    open(MODAL_TYPES.TRACKING, modalProps);
  };

  const openSalesModal = (modalProps) => {
    open(MODAL_TYPES.SALES, modalProps);
  };
  return { openTrackingModal, openSalesModal };
}

export default useModalEvent;
