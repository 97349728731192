import { Space } from 'antd';
import React from 'react';
import DashboardSummary from './views/dashboard-summary';
import SearchInput from './views/search-input';
import CompanyTable from './views/table';
import useTableAction from './hooks/use-table-action';
import Pagination from '../../components/shared/pagination';
import { ModalProvider } from './contexts/modal-context/context';

function CustomerSuccessScene() {
  const { dataSource, isLoading, paginationProps, searchInputProps, filters } = useTableAction();

  return (
    <ModalProvider>
      <Space
        direction="vertical"
        style={{ display: 'flex', gap: '8px', padding: '8px 0', backgroundColor: 'white' }}
      >
        <DashboardSummary />
        <SearchInput {...searchInputProps} />
        <Pagination {...paginationProps}>
          <CompanyTable
            dataSource={dataSource}
            pageStep={paginationProps?.pageStep}
            isLoading={isLoading}
            searchFilters={filters}
          />
        </Pagination>
      </Space>
    </ModalProvider>
  );
}

export default CustomerSuccessScene;
