import React from 'react';
import StatusTag from '../../../../../../../components/shared/status-tag';
import { DateFormat } from '../../../../../../../components/shared/format-text';
import CompanyProfileLink from '../../../../../../../components/shared/company-profile-link';

export const column = () => {
  return [
    {
      title: 'ชื่อบริษัท',
      dataIndex: 'name',
      key: 'name',
      render: (label, records) => <CompanyProfileLink label={label} authorId={records?.authorId} />,
    },
    {
      title: 'ชื่อแพ็กเกจ',
      dataIndex: 'packageName',
      key: 'packageName',
    },

    {
      title: 'ระยะเวลา',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'วันที่หมดอายุ',
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      render: (date) => <DateFormat date={date} />,
    },
    {
      title: 'วันที่ปิดงาน',
      dataIndex: 'closedDate',
      key: 'closedDate',
      render: (date) => <DateFormat date={date} />,
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <StatusTag type="package" status={status} />,
    },
  ];
};
