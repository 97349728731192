import React, { useState } from 'react';
import { Flex, Popconfirm, Space, Typography } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { column } from './constants/column';
import Table from '../../../../../../components/table-antd';
import { DELETE_WITH_PARAMS, GET, PUT } from '../../../../../../services';
import Button from '../../../../../../components/button-antd';
import { isArrayLength } from '../../../../../../functions';
import { errorAlert } from '../../../../../../utils/api/msg';

const getReportCaseApi = async () => {
  try {
    const response = await GET('/send-request/type/request');
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

const updateReportCaseStatusApi = async (reqBody) => {
  try {
    const response = await PUT(`/send-request/update-request`, reqBody);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

const deleteReportCaseApi = async (reqBody) => {
  try {
    const response = await DELETE_WITH_PARAMS('/send-request/delete-request', reqBody);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

function SupportCustomer() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const queryClient = useQueryClient();

  const { data, isLoading: isGetReportCaseLoading } = useQuery({
    queryKey: ['reportCases'],
    queryFn: () => getReportCaseApi(),
  });

  const { mutate: updateReportCaseStatus, isLoading: isUpdateReportCaseStatusLoading } =
    useMutation({
      mutationKey: ['updateReportCaseStatus'],
      mutationFn: (reqBody) => updateReportCaseStatusApi(reqBody),
      onSuccess: () => queryClient.invalidateQueries(['reportCases']),
      onError: () => errorAlert(),
    });

  const { mutate: deleteReportCase, isLoading: isDeleteReportCaseLoading } = useMutation({
    mutationKey: ['deleteReportCase'],
    mutationFn: (reqBody) => deleteReportCaseApi(reqBody),
    onSuccess: () => queryClient.invalidateQueries(['reportCases']),
    onError: () => errorAlert(),
  });

  const isMultipleClosed = isArrayLength(selectedRowKeys.filter((x) => x?.status === 'PENDING'));
  const updateIds = { sendIds: selectedRowKeys?.map((v) => v?._id) || [] };

  const reportCases = data?.result?.data || [];

  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Flex justify="flex-end" align="center">
        <Space>
          <Button
            useFor="closeReportCases"
            disabled={!isMultipleClosed}
            loading={isUpdateReportCaseStatusLoading}
            onClick={() => updateReportCaseStatus(updateIds)}
          />
        </Space>
      </Flex>
      <Table
        getSelectedRows={setSelectedRowKeys}
        rowKey={(record) => record?._id}
        dataSource={reportCases}
        columns={column({
          renderButton: (_, recrods) => (
            <Popconfirm
              title="ลบ"
              description="ต้องการลบใช่หรือไม่?"
              onConfirm={() => {
                const reqBody = {
                  sendId: [recrods?._id],
                };

                deleteReportCase(reqBody);
              }}
              onCancel={() => {}}
              okText="ใช่"
              cancelText="ไม่"
              okButtonProps={{ loading: isDeleteReportCaseLoading }}
            >
              <Button useFor="remove" isIcon />
            </Popconfirm>
          ),
        })}
        loading={isGetReportCaseLoading}
      />
    </Space>
  );
}

export default SupportCustomer;
