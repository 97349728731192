import React from 'react';
import StatusTag from '../../../../components/shared/status-tag';
import { ThaiMonthDateFormat } from '../../../../components/shared/format-text';
import { Typography } from 'antd';

export const applicantsColumn = () => {
  return [
    {
      title: 'Package',
      dataIndex: 'package',
      key: 'package',
      render: (status) => <StatusTag type="packageApplicant" status={status} />,
    },
    {
      title: 'ชื่อประกาศงาน',
      dataIndex: 'jobName',
      key: 'jobName',
    },
    {
      title: 'วันที่โพสต์',
      dataIndex: 'postDate',
      key: 'postDate',
      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'วันที่อัปเดตล่าสุด',
      dataIndex: 'updateDate',
      key: 'updateDate',
      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <StatusTag type="jobStatus" status={status} />,
    },
    {
      title: 'จำนวนผู้สมัคร',
      dataIndex: 'applyCount',
      key: 'applyCount',
      render: (count) => <Typography.Text>{count ? `${count} คน` : 0} </Typography.Text>,
    },
  ];
};
