import React from 'react';
import { Col, Row } from 'antd';
import { ModalTheme } from '../../../../../../components/modal';

function ModalLayout(props) {
  const { modalProps, children } = props;
  return (
    <ModalTheme {...modalProps}>
      <Row
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {children}
      </Row>
    </ModalTheme>
  );
}

ModalLayout.leftCol = function LeftColumn({ children }) {
  return (
    <Col span={8} style={{ height: '100%', overflowY: 'hidden', borderRight: '1px solid #f0f0f0' }}>
      {children}
    </Col>
  );
};

ModalLayout.rightCol = function RightColumn({ children }) {
  return (
    <Col span={16} style={{ height: '100%', overflowY: 'hidden' }}>
      {children}
    </Col>
  );
};

export default ModalLayout;
