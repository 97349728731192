import React, { useState } from 'react';
import TableCustom from '../../../../../../screens/telesales/components/table-custom';
import { column } from './constants/column';
import { mockData } from './constants/mockData';
import { Table } from 'antd';

function SupportCustomer() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    console.log('selectedRows', selectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <Table
        rowSelection={rowSelection}
        rowKey={(record) => record.id}
        dataSource={mockData}
        columns={column()}
      />
    </div>
  );
}

export default SupportCustomer;
