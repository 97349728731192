import React, { useEffect } from 'react';
import { column } from './constants/column';
import { useSalesCodeOptionQuery } from '../../../assign-telesales/hooks/use-options-query';
import { useExportExcel } from '../../hooks/use-table-query';
import { useOptions } from '../../../../hooks/useOptions';
import { useModalContext } from '../../contexts/modal-context/context';
import { Table } from 'antd';

const tableProps = {
  scroll: { x: 'max-content' },
  style: {
    margin: 'unset',
    padding: '8px',
    backgroundColor: 'white',
  },
};

function CompanyTable({ dataSource, pageStep, isLoading }) {
  const { options } = useOptions();
  const { modalEvent } = useModalContext();

  const salesCodeOption = useSalesCodeOptionQuery();
  const { mutateAsync: onExportExcel } = useExportExcel();

  return (
    <Table
      loading={isLoading}
      columns={column({
        modalEvent,
        options,
        salesCodeOption,
        pageStep,
      })}
      dataSource={dataSource}
      isShowStripe
      hasPagination={false}
      {...tableProps}
    />
  );
}

export default CompanyTable;
