export const initialState = {
  isOpen: false,
  modalType: null,
  modalProps: {},
  modalHistory: [],
};

export const modalReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      console.log(action, state);
      return {
        isOpen: true,
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps || {},
        modalHistory: state?.modalType
          ? [...state.modalHistory, { modalType: state.modalType, modalProps: state.modalProps }]
          : state.modalHistory,
      };

    case 'CLOSE_MODAL':
      return initialState;

    case 'GO_BACK': {
      const previousModal = state.modalHistory[state.modalHistory.length - 1];
      if (!previousModal) return initialState;

      return {
        ...state,
        modalType: previousModal.modalType,
        modalProps: previousModal.modalProps,
        modalHistory: state.modalHistory.slice(0, -1),
      };
    }

    default:
      return state;
  }
};
