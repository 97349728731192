import React from 'react';
import StatusTag from '../../../../../../../components/shared/status-tag';
import { ThaiMonthDateFormat } from '../../../../../../../components/shared/format-text';
import CompanyProfileLink from '../../../../../../../components/shared/company-profile-link';

export const column = ({ renderButton }) => {
  return [
    {
      title: 'รายละเอียด',
      dataIndex: 'detail',
      key: 'detail',
      width: '256px',
    },
    {
      title: 'ชื่อบริษัท',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (data, records) => <CompanyProfileLink label={data} authorId={records?._id} />,
    },
    {
      title: 'ผู้ส่งเรื่อง',
      dataIndex: 'userCreate',
      key: 'userCreate',
    },
    {
      title: 'วันที่ส่งเรื่อง',
      dataIndex: 'createDate',
      key: 'createDate',
      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'วันที่ปิดงาน',
      dataIndex: 'updateDate',
      key: 'updateDate',
      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'ผู้ปิดงาน',
      dataIndex: 'userUpdate',
      key: 'userUpdate',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      fixed: 'right',
      render: (status) => <StatusTag type="report" status={status} />,
    },
    {
      title: '',
      key: 'operation',
      width: '80px',
      fixed: 'right',
      align: 'center',
      render: (data, records) => renderButton(data, records),
    },
  ];
};
