import React from 'react';
import Layout from '../../layout';
import Table from '../../../../../components/table-antd';
import { manageHistoryColumn } from './constants/columns';
import { manageHistoryData } from './mock';

function ManageHistory() {
  return (
    <Layout>
      <Layout.Content>
        <Layout.Header>
          <Layout.Header.Title>ประวัติการจัดการ</Layout.Header.Title>
        </Layout.Header>
        <Table columns={manageHistoryColumn()} data={manageHistoryData} />
      </Layout.Content>
    </Layout>
  );
}

export default ManageHistory;
