import React from 'react';
import { ButtonIconTheme } from '../../../../../components/buttons';
import { Icon, color } from '../../../../../resources';
import { Tag } from 'antd';

const TAG_ASSIGN_COMPANY = {
  NEW: { th: 'ใหม่', color: 'green' },
  EXISTING: { th: 'เคยเพิ่มแล้ว', color: 'purple' },
};
function renderTagAssigCompany(type) {
  return (
    (TAG_ASSIGN_COMPANY[type] && (
      <Tag color={TAG_ASSIGN_COMPANY[type]?.color}>{TAG_ASSIGN_COMPANY[type]?.th}</Tag>
    )) ||
    ''
  );
}

export const CompanyAssignColumns = ({ onDelete }) => [
  {
    title: 'company',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    render: (data, record) => {
      return (
        <div>
          {renderTagAssigCompany(record?.type)} {`${data}`}
        </div>
      );
    },
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    render: (text, records) => {
      return (
        <div>
          <ButtonIconTheme
            useFor="CUSTOM"
            color={color.remove}
            icon={<Icon.remove />}
            onClick={() => onDelete(records)}
          />
        </div>
      );
    },
  },
];
