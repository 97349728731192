import React from 'react';
import { Col, Flex, Row, Select, Space, Typography } from 'antd';
import {
  Container,
  ContentBelowContainer,
  FixedBottomContainer,
  FixedTopContainer,
  NoteText,
} from './styled-component';
import { useSalesContext } from '../../../../contexts/sales-context/context';
import Button from '../../../../../../../../components/button-antd';
import ContactDetail from './components/contact-detail';
import CompanyInformationForm from './components/company-infomation-form';
import { convertStrToFormat } from '../../../../../../../../functions';
import StatusTag from '../../../../../../../../components/shared/status-tag';
import { useOptions } from '../../../../../../../../hooks/useOptions';
import { TextInputLabel, TextXSMall } from '../../../../../../../../components/text';
import { Required } from '../../../../../../../../styles/global-style';
import { renderTypeError } from '../../../../../../../../components/forms';
import { CALL_STATUS } from '../../../../contexts/sales-context/hooks';
import useFormState from './components/company-infomation-form/hook';

function CallDetail() {
  const {
    isRunning,
    totalSeconds,
    callState,
    handleStartCall,
    handleEndCall,
    selectedContact,
    cancelSelectedContact,
    companyDetails,
    handleSubmitTransaction,
    updateCompanyDetails,
  } = useSalesContext();
  const { options } = useOptions();

  const { formProps } = useFormState(companyDetails);

  const onSubmitForm = () => {
    formProps?.handleSubmit(
      (values) => {
        const { quotations, ...rest } = values;
        handleSubmitTransaction(rest);
      },
      () => {},
    )();
  };

  return (
    <Container>
      <FixedTopContainer>
        <ContactDetail {...selectedContact?.contactDetails} />
      </FixedTopContainer>
      <ContentBelowContainer>
        <Row gutter={8}>
          <Col span={8}>
            <Flex justify="center">
              <Space direction="vertical" align="center" justify="center">
                <Typography.Text>จับเวลาการโทรติดต่อ</Typography.Text>
                <Typography.Text
                  type={isRunning ? 'danger' : 'secondary'}
                  style={{ fontSize: '40px' }}
                >
                  {convertStrToFormat(totalSeconds, 'time') || '00:00:00'}
                </Typography.Text>
                {callState.status !== CALL_STATUS.CALLING ? (
                  <Button
                    useFor="startCall"
                    onClick={handleStartCall}
                    loading={callState?.isLoading}
                    disabled={callState.status === CALL_STATUS.ENDED}
                  />
                ) : (
                  <Button useFor="endCall" onClick={handleEndCall} loading={callState?.isLoading} />
                )}
              </Space>
            </Flex>
          </Col>
          <Col span={16}>
            <Space style={{ width: '100%' }} direction="vertical">
              <Space direction="vertical" style={{ width: '100%' }}>
                <Space>
                  <TextInputLabel text={'สถานะการติดต่อ'} />
                  <Required>*</Required>
                </Space>
                <div>
                  <Select
                    style={{ width: '100%' }}
                    onChange={(value) => formProps?.setValue('calledStatus', value)}
                  >
                    {options?.callStatus?.data?.map((x) => (
                      <Select.Option key={x.value} value={x.value}>
                        <StatusTag type="callStatus" isBadge status={x?.value} />
                      </Select.Option>
                    ))}
                  </Select>
                  {formProps?.formState?.errors?.calledStatus && (
                    <TextXSMall
                      text={renderTypeError('required', formProps?.formState?.errors?.calledStatus)}
                      color="red"
                    />
                  )}
                </div>
              </Space>
              <Space direction="vertical" style={{ width: '100%' }}>
                <TextInputLabel text="โน้ตข้อความล่าสุด" />
                <NoteText>{companyDetails?.lastedNote}</NoteText>
              </Space>
            </Space>
          </Col>
        </Row>
        <CompanyInformationForm formProps={formProps} />
      </ContentBelowContainer>
      <FixedBottomContainer>
        <Flex align="center" justify="flex-end" gap={8}>
          <Button
            useFor="save"
            disabled={callState?.status !== CALL_STATUS.ENDED}
            onClick={onSubmitForm}
          />
          <Button useFor="cancel" onClick={cancelSelectedContact} />
        </Flex>
      </FixedBottomContainer>
    </Container>
  );
}

export default CallDetail;
