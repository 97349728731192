import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styled-component';

function Content(props) {
  const { children } = props;
  return <Container>{children}</Container>;
}

Content.propTypes = {
  children: PropTypes.any,
};

export default Content;
