import { MODAL_TYPES } from './constant';

function useModalEvent(open) {
  const openTrackingModal = (modalProps) => {
    open(MODAL_TYPES.TRACKING, modalProps);
  };

  const openSalesModal = (modalProps) => {
    open(MODAL_TYPES.SALES, modalProps);
  };

  const openPackageModal = (modalProps) => {
    open(MODAL_TYPES.PACKAGE_ITEMS, modalProps);
  };

  const openApplicantModal = (modalProps) => {
    open(MODAL_TYPES.APPLICANT_COUNT, modalProps);
  };

  const openNoteModal = (modalProps) => {
    open(MODAL_TYPES.NOTE, modalProps);
  };

  const openAdditionalNoteModal = (modalProps) => {
    open(MODAL_TYPES.ADDITION_NOTE, modalProps);
  };

  const openQuotationModal = (modalProps) => {
    open(MODAL_TYPES.QUOTATION, modalProps);
  };

  return {
    openTrackingModal,
    openSalesModal,
    openPackageModal,
    openApplicantModal,
    openNoteModal,
    openAdditionalNoteModal,
    openQuotationModal,
  };
}

export default useModalEvent;
