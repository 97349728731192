import React from 'react';
import { DateColumn } from '../../../../../../../screens/telesales/views/table/components/render-column';
import CompanyProfileLink from '../../../../../../../components/shared/company-profile-link';

export const column = () => {
  return [
    {
      title: 'ชื่อบริษัท',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (data) => <CompanyProfileLink text={data} href="#" />,
    },
    {
      title: 'รหัสบริษัท',
      dataIndex: 'caNumber',
      key: 'caNumber',
    },

    {
      title: 'ขนาดบริษัท',
      dataIndex: 'companySize',
      key: 'companySize',
    },
    {
      title: 'จำนวนพนักงาน',
      dataIndex: 'employeeSize',
      key: 'employeeSize',
    },
    {
      title: 'ประเภทธุรกิจ',
      dataIndex: 'businessType',
      key: 'businessType',
    },
    {
      title: 'วันที่เริ่มใช้งาน',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (data) => <DateColumn date={data} />,
    },
    {
      title: 'แพ็กเกจที่ใช้ล่าสุด',
      dataIndex: 'currentPackage',
      key: 'currentPackage',
    },
    {
      title: 'จำนวนงานที่เปิด',
      dataIndex: 'openedJob',
      key: 'openedJob',
    },
    {
      title: 'เครดิตที่ใช้',
      dataIndex: 'usageCredit',
      key: 'usageCredit',
    },
    {
      title: 'ยอดผู้สมัคร',
      dataIndex: 'totalCandidate',
      key: 'totalCandidate',
    },
  ];
};
