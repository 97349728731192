import { Tabs } from 'antd';
import styled from 'styled-components';

export const TabStyled = styled(Tabs)`
  height: 100%;
  padding: 16px;

  .ant-tabs-content-holder {
    max-height: calc(100% - 40px);
    overflow-y: auto;
  }
`;
