import React from 'react';
import PropTypes from 'prop-types';
import { ModalTheme } from '../../../../components/modal';
import SupportCustomer from './views/support-customer';
import PackageExpiring from './views/package-expiring';
import Opportunities from './views/opportunities';
import PromoteJob from './views/promote-job';
import { useRole } from '../../../../hooks/useRole';
import NewCustomer from './views/new-customer';
import CurrentCustomer from './views/current-customer';
import { TabStyled } from './styled-component';
import { getRenderTabs } from './logic';

function TrackingModal(props) {
  const { defaultTab, tabKeys, ...modalProps } = props;

  const renderTabItems = () => {
    const salesTab = [
      {
        label: <span>ช่วยเหลือลูกค้า</span>,
        key: 'support-customer',
        children: <SupportCustomer />,
      },
      { label: 'การ PR งาน', key: 'pr-job', children: <PromoteJob /> },
      {
        label: 'แพ็กเกจ <2 เดือน',
        key: 'package-less-2-m',
        children: <PackageExpiring />,
      },
      {
        label: 'โอกาสในการซื้อ 25%',
        key: 'opportunities-25',
        children: <Opportunities isPrimaryState />,
      },
      { label: 'โอกาสในการซื้อ 50%', key: 'opportunities-50', children: <Opportunities /> },
      { label: 'โอกาสในการซื้อ 75%', key: 'opportunities-75', children: <Opportunities /> },
      { label: 'โอกาสในการซื้อ 95%', key: 'opportunities-95', children: <Opportunities /> },
      { label: 'ปิดการขาย', key: 'opportunities-100', children: <Opportunities /> },
    ];

    const prTab = [
      {
        label: <span>ช่วยเหลือลูกค้า</span>,
        key: 'support-customer',
        children: <SupportCustomer />,
      },
      { label: 'การ PR งาน', key: 'pr-job', children: <PromoteJob /> },
    ];

    const csTab = [
      {
        label: <span>ช่วยเหลือลูกค้า</span>,
        key: 'support-customer',
        children: <SupportCustomer />,
      },
      { label: 'การ PR งาน', key: 'pr-job', children: <PromoteJob /> },
      { label: 'ลูกค้าใหม่', key: 'new-customer', children: <NewCustomer /> },
      { label: '14 วัน', key: 'usage-2-weeks', children: <CurrentCustomer /> },
      { label: '30 วัน ', key: 'usage-1-month', children: <CurrentCustomer /> },
      { label: '3 เดือน ', key: 'usage-3-month', children: <CurrentCustomer /> },
      { label: '6 เดือน', key: 'usage-6-months', children: <CurrentCustomer /> },
      { label: '9 เดือน ', key: 'usage-9-months', children: <CurrentCustomer /> },
    ];

    if (isCS) return csTab;
    if (isPR) return prTab;
    if (isSales || isTelesale) return salesTab;
    if (isSuperAdmin) return salesTab;

    return [];
  };

  return (
    <ModalTheme width={1840} title="การติดตาม" id="tracking-modal" {...modalProps}>
      <TabStyled
        type="card"
        destroyInactiveTabPane
        defaultActiveKey={defaultTab}
        items={getRenderTabs(tabKeys)}
      />
    </ModalTheme>
  );
}

TrackingModal.propTypes = {
  defaultTab: PropTypes.string,
};

TrackingModal.defaultProps = {
  defaultTab: '',
};

export default TrackingModal;
