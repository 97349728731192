import React from 'react';
import { SideBarStyle } from '../../style';
import { ROUTES_MENU } from '../../../../resources/routes-name';
import { useSelectMenu } from '../../hook/useSelectMenu';
import { Menu } from 'antd';
import { ScrollMenu } from './style';
import BrandBanner from './views/brand-banner';
import UserAvatar from './views/user-avatar';
import { SIDEBAR_WIDTH } from '../../../../resources/constants/component-size';
import { useSelector } from 'react-redux';

const SiderLayout = ({ collapsed, getTheme, sidebarMode }) => {
  const { user } = useSelector((state) => state.authen);
  const { defaultSelectedKeys, defaultOpenKeys, onHandleClick } = useSelectMenu();

  const menus = !user.role
    ? []
    : ROUTES_MENU.filter((e) => e?.access_roles && e.access_roles.includes(user.role));

  return (
    <SideBarStyle
      width={SIDEBAR_WIDTH}
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{
        ...getTheme.sidebar[sidebarMode],
      }}
    >
      <BrandBanner />
      <UserAvatar />

      {defaultOpenKeys && defaultSelectedKeys && (
        <ScrollMenu>
          <Menu
            theme={sidebarMode}
            mode="inline"
            defaultOpenKeys={defaultOpenKeys}
            selectedKeys={defaultSelectedKeys}
            style={{ ...getTheme.sidebar[sidebarMode] }}
            items={menus}
            onClick={onHandleClick}
          />
        </ScrollMenu>
      )}
    </SideBarStyle>
  );
};

export default SiderLayout;
