import React from 'react';
import { PhoneOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';

export const contactColumn = () => {
  return [
    {
      title: 'ชื่อผู้ติดต่อ',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ตำแหน่ง',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'อีเมล',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '',
      key: 'action',
      render: () => (
        <Space>
          <Button shape="circle" icon={<PhoneOutlined />} />
        </Space>
      ),
    },
  ];
};
