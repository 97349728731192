import React from 'react';
import { Button } from 'antd';
import { StarOutlined } from '@ant-design/icons';
import StatusTag from '../../../../../../components/shared/status-tag';
import LinkComponent from '../../../../../../components/link';
import CreditUsage from '../../../../../../components/shared/credit-usage';

export const pagekageColumn = () => {
  return [
    {
      title: 'ชื่อแพ็กเกจ ไอเท็ม',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'ประกาศที่ใช้',
      key: 'jobPost',
      dataIndex: 'jobPost',
      render: (data) => <CreditUsage {...data} />,
    },
    {
      title: 'เครดิตที่ใช้',
      key: 'credit',
      dataIndex: 'credit',
      render: (data) => <CreditUsage {...data} />,
    },
    {
      title: 'วันที่ซื้อ',
      key: 'purchasedDate',
      dataIndex: 'purchasedDate',
    },
    {
      title: 'วันหมดอายุ',
      key: 'expiredDate',
      dataIndex: 'expiredDate',
    },
    {
      title: 'ระยะเวลา',
      key: 'duration',
      dataIndex: 'duration',
    },
    {
      title: 'ราคา (บาท)',
      key: 'price',
      dataIndex: 'price',
    },
    {
      title: 'สถานะ',
      key: 'status',
      dataIndex: 'status',
      render: (data) => <StatusTag type="package" status={data} />,
    },
  ];
};

export const quotationColumn = () => {
  return [
    {
      title: 'เลขที่ใบเสนอราคา',
      key: 'quotationId',
      dataIndex: 'quotationId',
      render: (data) => <LinkComponent>{data}</LinkComponent>,
    },
    {
      title: 'วันที่ออก',
      key: 'createdDate',
      dataIndex: 'createdDate',
    },
    {
      title: 'วันหมดอายุ',
      key: 'expiredDate',
      dataIndex: 'expiredDate',
    },
    {
      title: 'ยอดรวม (บาท)',
      key: 'total',
      dataIndex: 'total',
    },
    {
      title: 'เดือนที่คาดว่าปิด',
      key: 'closedMonth',
      dataIndex: 'closedMonth',
    },
    {
      key: 'bookmark',
      dataIndex: 'bookmark',
      render: (data) => <Button shape="circle" icon={<StarOutlined />} />,
    },
  ];
};
