export const quotationData = [
  {
    index: 1,
    quotationId: 'QTJFF1812202406581',
    createdDate: '18 ธ.ค. 2024',
    expiredDate: '1 ม.ค. 2025',
    total: '28,052.00',
    closedMonth: 'ธ.ค. 2025',
    bookmark: true,
  },
  {
    index: 2,
    quotationId: 'QTJFF1309202400002',
    createdDate: '13 ก.ย. 2024',
    expiredDate: '13 พ.ย. 2024',
    total: '32,599.40',
    closedMonth: 'พ.ย. 2024',
    bookmark: true,
  },
  {
    index: 3,
    quotationId: 'QTJFF2402202400145',
    createdDate: '24 ก.พ. 2024',
    expiredDate: '22 มี.ค. 2024',
    total: '38,452.00',
    closedMonth: 'มี.ค. 2024',
    bookmark: false,
  },
  {
    index: 4,
    quotationId: 'QTJFF2009202400002',
    createdDate: '20 ก.ย. 2024',
    expiredDate: '18 พ.ย. 2024',
    total: '45,209.10',
    closedMonth: 'มี.ค. 2024',
    bookmark: false,
  },
];
