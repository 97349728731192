import { useState, useEffect } from 'react';

const useSelectItems = ({ open }) => {
  const [selectItems, setSelectItems] = useState(null);

  useEffect(() => {
    if (open) {
      setSelectItems(null);
    }
  }, [open]);

  const onSelect = async (value) => {
    setSelectItems(value);
  };

  return {
    isEdited: selectItems,
    selectValues: selectItems || null,
    onSelect,
  };
};

export default useSelectItems;
