import SUCCESS_MESSAGES from './success-msg.json';
import ERROR_MESSAGES from './error-msg.json';
import { openAlert } from '../../../components/alert/useAlert';

export const getSuccessMsg = (msg) => {
  return SUCCESS_MESSAGES[msg] || SUCCESS_MESSAGES.default;
};

export const getErrorMsg = (msg) => {
  return ERROR_MESSAGES[msg] || ERROR_MESSAGES.default;
};

export const successAlert = (apiMessage) => {
  openAlert({ type: 'success', message: getSuccessMsg(apiMessage) });
};

export const errorAlert = (apiMessage) => {
  openAlert({ type: 'error', message: getErrorMsg(apiMessage) });
};
