import React from 'react';
import StatusTag from '../../../../../../../components/shared/status-tag';
import CompanyProfileLink from '../../../../../../../components/shared/company-profile-link';
import { Typography } from 'antd';
import { DateFormat, NumberFormat } from '../../../../../../../components/shared/format-text';

export const column = () => {
  return [
    {
      title: 'ชื่อบริษัท',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (label, records) => <CompanyProfileLink label={label} authorId={records?.authorId} />,
    },
  ];
};

export const nestedColumns = () => {
  return [
    {
      title: 'ชื่อตำแหน่งงาน',
      dataIndex: 'jobName',
      key: 'jobName',
      width: '40%',
      render: (data) => <Typography.Text>{data[0]}</Typography.Text>,
    },
    {
      title: 'ผู้ส่งเรื่อง',
      dataIndex: 'userCreate',
      key: 'userCreate',
    },
    {
      title: 'วันที่ส่งเรื่อง',
      dataIndex: 'createDate',
      key: 'createDate',
      render: (data) => <DateFormat date={data} />,
    },
    {
      title: 'วันที่ปิดงาน',
      dataIndex: 'updateDate',
      key: 'updateDate',
      render: (data) => <DateFormat date={data} />,
    },
    {
      title: 'จำนวนครั้งที่ PR',
      dataIndex: 'promoteCount',
      key: 'promoteCount',
      render: (data) => <NumberFormat str={data} />,
    },
    {
      title: 'ผู้ปิดเรื่อง',
      dataIndex: 'userUpdate',
      key: 'userUpdate',
    },
    {
      title: 'สถานะ',
      dataIndex: 'prStatus',
      key: 'prStatus',
      render: (status) => <StatusTag type="promoteStatus" status={status} />,
    },
  ];
};
