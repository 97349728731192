import React, { useEffect } from 'react';
import { ModalTheme } from '../../../../components/modal';
import Table from '../../../../components/table-antd';
import { useMutation } from '@tanstack/react-query';
import { GET } from '../../../../services';
import { packageItemColumn } from './column';
import CompanyInfomation from '../company-info';
import { Container, ScrollContainer, StickyContainer } from './styled-component';

const packageItemApi = async (companyId) => {
  try {
    const response = await GET(`/tls-company/package/${companyId}`);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

function PackageItemModal(props) {
  const { companyDetails, ...modalProps } = props;

  const companyId = companyDetails?._id;

  const {
    mutate: fetchPackageItem,
    data,
    isLoading,
  } = useMutation({
    mutationKey: ['packageItem', companyId],
    mutationFn: () => packageItemApi(companyId),
  });

  useEffect(() => {
    fetchPackageItem();
  }, []);

  const packageItemData = data?.result?.data || [];

  console.log(packageItemData);

  return (
    <ModalTheme {...modalProps} title="Package / Item ทั้งหมด" width={1840}>
      <Container>
        <StickyContainer>
          <CompanyInfomation {...companyDetails} />
        </StickyContainer>
        <ScrollContainer>
          <Table data={packageItemData} loading={isLoading} columns={packageItemColumn()} />
        </ScrollContainer>
      </Container>
    </ModalTheme>
  );
}

export default PackageItemModal;
