import { useState } from 'react';
import {
  useCheckOnCall,
  useCreateCallTransaction,
  useDestoryTrxCall,
  useEndCall,
  useStartCall,
} from '../../hooks/useCallQuery';
import { openAlert } from '../../../../../../components/alert/useAlert';
import { useStopwatch } from 'react-timer-hook';
import * as dateUtils from '../../../../../../utils/date-time';

export const CALL_STATUS = {
  IDLE: 'idle',
  PENDING: 'pending',
  CALLING: 'calling',
  ENDED: 'ended',
};

export const useCallManagement = (companyDetails) => {
  const [selectedContact, setSelectedContact] = useState(null);
  const { mutateAsync: checkOnCall } = useCheckOnCall();
  const { mutateAsync: destroyTrxCall } = useDestoryTrxCall();

  const { totalSeconds, isRunning, start, pause, reset } = useStopwatch();
  const [callState, setCallState] = useState({
    status: CALL_STATUS.IDLE,
    startDetails: null,
    endDetails: null,
    isLoading: false,
  });

  const startCall = useStartCall();
  const endCall = useEndCall();
  const createTransaction = useCreateCallTransaction();

  const setLoading = () => {
    setCallState((prev) => ({ ...prev, isLoading: true }));
  };

  const clearLoading = () => {
    setCallState((prev) => ({ ...prev, isLoading: false }));
  };

  // useEffect(() => {
  //   setCallState({
  //     status: CALL_STATUS.IDLE,
  //     startDetails: null,
  //     endDetails: null,
  //     isLoading: false,
  //   });

  //   if (selectedContact?.startTime && selectedContact?.endTime) {
  //     setCallState((prev) => ({
  //       ...prev,
  //       status: CALL_STATUS.WAITING_CONFIRM,
  //     }));
  //     const stopwatchOffset = dateUtils.getStopwatchOffset(
  //       selectedContact?.startTime,
  //       selectedContact?.endTime,
  //     );
  //     reset(stopwatchOffset, false);
  //   } else if (selectedContact?.startTime) {
  //     setCallState((prev) => ({
  //       ...prev,
  //       status: CALL_STATUS.CALLING,
  //     }));
  //     const stopwatchOffset = dateUtils.getStopwatchOffset(selectedContact?.startTime);
  //     reset(stopwatchOffset);
  //   } else {
  //     setCallState((prev) => ({
  //       ...prev,
  //       status: CALL_STATUS.IDLE,
  //     }));
  //     reset(null, false);
  //   }
  // }, []);

  const handleStartCall = async () => {
    try {
      start();
      setLoading();
      const startDate = dateUtils.getCurrentTime();

      const reqBody = {
        companyId: companyDetails?._id,
        userContactId: selectedContact?.contactDetails?._id,
        startTime: startDate,
      };

      const response = await startCall.mutateAsync(reqBody);

      if (response?.success) {
        setCallState((prev) => ({
          ...prev,
          status: CALL_STATUS.CALLING,
          startDetails: response?.result,
          isLoading: false,
        }));
      }
    } catch (err) {
      setCallState((prev) => ({
        ...prev,
        status: CALL_STATUS.IDLE,
        isLoading: false,
      }));
      reset(null, false);
    }
  };

  const handleEndCall = async () => {
    try {
      setLoading();
      pause();
      const endTimeStamp = dateUtils.getCurrentTime();

      const callId = selectedContact?.onCallId || callState.startDetails?._id;
      if (!callId) return;

      const params = {
        callId: callId,
        reqBody: {
          endTime: endTimeStamp,
        },
      };

      const response = await endCall.mutateAsync(params);

      if (response?.success) {
        setCallState((prev) => ({
          ...prev,
          status: CALL_STATUS.ENDED,
          endDetails: response?.result,
          isLoading: false,
        }));
      }
    } catch (error) {
      setCallState((prev) => ({
        ...prev,
        status: CALL_STATUS.IDLE,
        isLoading: false,
      }));
      reset(null, false);
    }
  };

  const handleSubmitTransaction = async (formValues) => {
    try {
      setLoading();
      const reqBody = {
        ...formValues,
        callId: selectedContact?.onCallId || callState.startDetails?._id,
        contactId: selectedContact?.customerId || callState.startDetails?.customer_id || contactId,
        followUpDate: dateUtils.formatToDate(formValues?.followUpDate),
      };

      const response = await createTransaction.mutateAsync(reqBody);

      if (response?.success) {
        clearLoading();
        setSelectedContact(null);
        setCallState((prev) => ({
          ...prev,
          status: CALL_STATUS.IDLE,
          isLoading: false,
        }));
      }
    } catch (error) {
      clearLoading();
    }
  };

  const handleClickCall = async (contactDetail) => {
    try {
      setLoading();
      const checkCallResponse = await checkOnCall(contactDetail?._id);

      const isselectedContact =
        checkCallResponse?.result?.data?.companyId === contactDetail?._id &&
        checkCallResponse?.result?.data?.customerId === contactDetail?._id;

      const callDetail = {
        contactDetails: contactDetail,
        companyDetails,
        companyId: companyDetails?._id,
        lastNote: checkCallResponse?.result?.lastNote || 'ไม่มีโน้ตล่าสุด',
      };

      if (
        checkCallResponse?.success &&
        checkCallResponse?.message === 'calling' &&
        !isselectedContact
      ) {
        openAlert({
          model: 'confirm',
          title: 'คุณกำลังอยู่ในสายโทรศัพท์',
          okText: 'ดำเนินการต่อและตัดสายก่อนหน้า',
          cancelText: 'ปิด',
          onOk: async () => {
            try {
              setLoading();
              const destroyResponse = await destroyTrxCall();
              if (destroyResponse.success) {
                setSelectedContact(callDetail);
                setCallState((prev) => ({
                  ...prev,
                  status: CALL_STATUS.PENDING,
                  isLoading: false,
                }));
              }
              clearLoading();
            } catch (error) {
              clearLoading();
            }
          },
        });
      } else {
        reset(null, false);
        setSelectedContact(callDetail);
        setCallState((prev) => ({
          ...prev,
          status: CALL_STATUS.PENDING,
          isLoading: false,
        }));
      }
      clearLoading();
    } catch (error) {
      clearLoading();
    }
  };

  return {
    selectedContact,
    setSelectedContact,
    cancelSelectedContact: () => {
      setSelectedContact(null);
      setCallState((prev) => ({
        ...prev,
        status: CALL_STATUS.IDLE,
        isLoading: false,
      }));
    },
    handleClickCall,
    totalSeconds,
    isRunning,
    callState,
    handleStartCall,
    handleEndCall,
    handleSubmitTransaction,
  };
};
