import React from 'react';
import Layout from '../../layout';
import Table from '../../../../../components/table-antd';
import { callHistoryData } from './mock';
import { historyCall } from './constants/columns';

function HistoryCall() {
  return (
    <Layout>
      <Layout.Content>
        <Layout.Header>
          <Layout.Header.Title>ประวัติการโทร</Layout.Header.Title>
        </Layout.Header>
        <Table columns={historyCall()} data={callHistoryData} />
      </Layout.Content>
    </Layout>
  );
}

export default HistoryCall;
