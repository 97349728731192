export const formatToColumnPattern = (data) => {
  const formattedData = [
    {
      key: '1',
      category: 'แพ็กเกจล่าสุด',
      jobFinFin: data.find((item) => item.name === 'JobFinFin')?.lastedPackage || '-',
      jobThai: data.find((item) => item.name === 'JobThai')?.lastedPackage || '-',
      jobbkk: data.find((item) => item.name === 'JobBkk')?.lastedPackage || '-',
      jobsdb: data.find((item) => item.name === 'Jobsdb')?.lastedPackage || '-',
      jobTopgun: data.find((item) => item.name === 'JobTopGun')?.lastedPackage || '-',
    },
    {
      key: '2',
      category: 'จำนวนประกาศงาน',
      jobFinFin: data.find((item) => item.name === 'JobFinFin')?.totalJobs || '0',
      jobThai: data.find((item) => item.name === 'JobThai')?.totalJobs || '0',
      jobbkk: data.find((item) => item.name === 'JobBkk')?.totalJobs || '0',
      jobsdb: data.find((item) => item.name === 'Jobsdb')?.totalJobs || '0',
      jobTopgun: data.find((item) => item.name === 'JobTopGun')?.totalJobs || '0',
    },
    {
      key: '3',
      category: 'ยอดรวมราคาสั่งซื้อ',
      jobFinFin: data.find((item) => item.name === 'JobFinFin')?.estimatePrice || '0',
      jobThai: data.find((item) => item.name === 'JobThai')?.estimatePrice || '0',
      jobbkk: data.find((item) => item.name === 'JobBkk')?.estimatePrice || '0',
      jobsdb: data.find((item) => item.name === 'Jobsdb')?.estimatePrice || '0',
      jobTopgun: data.find((item) => item.name === 'JobTopGun')?.estimatePrice || '0',
    },
  ];

  return formattedData;
};
