import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { color } from '../../../resources';

function CheckStatus({ status }) {
  return (
    <Space>
      {status ? (
        <CheckCircleFilled style={{ color: color.green80 }} />
      ) : (
        <CloseCircleOutlined style={{ color: color.redStatus }} />
      )}
    </Space>
  );
}

CheckStatus.propTypes = {
  status: PropTypes.bool,
};

CheckStatus.defaultProps = {
  status: false,
};

export default CheckStatus;
