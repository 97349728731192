export const mockData = [
  {
    id: '1',
    companyName: 'JobFinFin',
    caNumber: 'CA1234',
    businessType: 'Recruiter',
    startDate: '2024-01-15',
    currentPackage: 'Standard',
    lastedNote: 'กำลังติดต่อกลับ',
    quatationNo: 'QT-JFF125658785002',
    total: '17,502 บาท',
  },
  {
    id: '2',
    companyName: 'GMM',
    caNumber: 'CA1555',
    businessType: 'บริการ',
    startDate: '2024-01-11',
    currentPackage: 'Standard',
    lastedNote: 'รอตอบกลับจากบริษัท',
    quatationNo: 'QT-JFF125658731234',
    total: '17,502 บาท',
  },
  {
    id: '3',
    companyName: 'Copperwide',
    caNumber: 'CA4587',
    businessType: 'ค้าปลีก',
    startDate: '2024-01-10',
    currentPackage: 'Beyond',
    lastedNote: 'ติดต่อผ่านทางโทรศัพท์',
    quatationNo: 'QT-JFF125658736582',
    total: '35,502 บาท',
  },
  {
    id: '4',
    companyName: 'Nexon',
    caNumber: 'CA6584',
    businessType: 'นำเข้าส่งออก',
    startDate: '2024-01-09',
    currentPackage: 'Standard',
    lastedNote: 'ติดต่อที่ 18/05/2024 ไม่มีเงิน G ไว้เตือน รีบขอบคุณลูกค้า',
    quatationNo: 'QT-JFF126158354568',
    total: '14,502 บาท',
  },
  {
    id: '5',
    companyName: 'ชาเขียว-ภูเก็ตฮิลส์',
    caNumber: 'CA7874',
    businessType: 'IT',
    startDate: '2024-01-09',
    currentPackage: 'Standard',
    lastedNote: 'ซอฟท์แวร์หมดอายุใหม่',
    quatationNo: 'QT-JFF125658211112',
    total: '14,502 บาท',
  },
  {
    id: '6',
    companyName: 'แสนดี',
    caNumber: 'CA1125',
    businessType: 'Start Up',
    startDate: '2024-01-08',
    currentPackage: 'Standard',
    lastedNote: 'กำลังติดต่อกลับ',
    quatationNo: 'QT-JFF12554728541',
    total: '14,502 บาท',
  },
];
