import React, { useState } from 'react';
import Table from '../../../../../../../components/table-antd';
import { PromoteJobColumn } from './constants/column';
import { jobData } from './constants/mock';
import Layout from '../../layout';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getJobPromoteApi } from './api';

function PromoteJob() {
  const [selectedRows, setSelectedRows] = useState([]);

  const { data } = useQuery({
    queryKey: ['jobPromote'],
    queryFn: () => getJobPromoteApi(),
  });

  const { mute: sendPromote } = useMutation({
    mutationKey: [''],
    mutationFn: () => sendPromoteApi(),
  });

  const handleSendPromote = () => {};

  return (
    <Layout>
      <Layout.Content>
        <Layout.Header>
          <Layout.Header.Title>โปรโมทงาน</Layout.Header.Title>
        </Layout.Header>
        <Table
          columns={PromoteJobColumn({ handleSendPromote })}
          getSelectedRows={setSelectedRows}
          data={jobData}
        />
      </Layout.Content>
    </Layout>
  );
}

export default PromoteJob;
