import React from 'react';
import { DateColumn } from '../../../../../../../screens/telesales/views/table/components/render-column';
import StatusTag from '../../../../../../../components/shared/status-tag';
import CompanyProfileLink from '../../../../../../../components/shared/company-profile-link';

export const column = () => {
  return [
    {
      title: 'ชื่อบริษัท',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (data) => <CompanyProfileLink text={data} href="#" />,
    },
    {
      title: 'ชื่อแพ็กเกจ',
      dataIndex: 'packageName',
      key: 'packageName',
    },

    {
      title: 'ระยะเวลา',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'วันที่หมดอายุ',
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      render: (data) => <DateColumn date={data} />,
    },
    {
      title: 'วันที่ปิดงาน',
      dataIndex: 'closedDate',
      key: 'closedDate',
      render: (data) => <DateColumn date={data} />,
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <StatusTag type="package" status={status} />,
    },
  ];
};
