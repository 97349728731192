import { useSelector } from 'react-redux';
import { ROLES } from '../utils/admin/role';

export const useRole = () => {
  const { user } = useSelector((state) => state.authen);
  const userRole = user?.role;

  return {
    userRole,
    isSuperAdmin: userRole === ROLES.SUPER_ADMIN,
    isCS: userRole === ROLES.CUSTOMER_SERVICE,
    isPR: userRole === ROLES.PR,
    isSales: userRole === ROLES.SALES,
    isTelesale: userRole === ROLES.TELESALE,
  };
};

// const { isSuperAdmin, isCS, isPR, isSales, isTelesale } = useRole();
