import React from 'react';
import {
  ContentBelowContainer,
  FixedContainer,
  RightColumnContainer,
} from '../../styled-component';
import Tabs from '../../components/tabs';
import { useSalesContext } from '../../contexts/sales-context/context';
import CompanyInfomation from '../../../company-info';

function RightColumn() {
  const { companyDetails } = useSalesContext();

  return (
    <RightColumnContainer>
      <FixedContainer>
        <CompanyInfomation {...companyDetails} />
      </FixedContainer>
      <ContentBelowContainer>
        <Tabs />
      </ContentBelowContainer>
    </RightColumnContainer>
  );
}

export default RightColumn;
