import React from 'react';
import LinkComponent from '../../link';
import { generateCompanyProfileUrl } from '../../../utils/client';

function CompanyProfileLink(props) {
  const { label, authorId } = props;

  return (
    <LinkComponent isShowIcon isNewTab href={generateCompanyProfileUrl(authorId)}>
      {label}
    </LinkComponent>
  );
}

export default CompanyProfileLink;
