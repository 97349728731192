import React from 'react';
import { Button, Typography } from 'antd';
import { StarOutlined } from '@ant-design/icons';
import StatusTag from '../../../../../../../../components/shared/status-tag';
import {
  NumberFormat,
  ThaiMonthDateFormat,
} from '../../../../../../../../components/shared/format-text';
import LinkComponent from '../../../../../../../../components/link';
import CreditUsage from '../../../../../../../../components/shared/credit-usage';

export const pagekageColumn = () => {
  return [
    {
      title: 'ชื่อแพ็กเกจ ไอเท็ม',
      key: 'packageName',
      dataIndex: 'packageName',
    },
    {
      title: 'ประกาศที่ใช้',
      key: 'jobPostUsed',
      dataIndex: 'jobPostUsed',
      render: (data) => <CreditUsage {...data} />,
    },
    {
      title: 'เครดิตที่ใช้',
      key: 'creditUsed',
      dataIndex: 'creditUsed',
      render: (data) => <CreditUsage {...data} />,
    },
    {
      title: 'วันที่ซื้อ',
      key: 'packageStart',
      dataIndex: 'packageStart',
      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'วันหมดอายุ',
      key: 'packageExp',
      dataIndex: 'packageExp',
      render: (date) => <ThaiMonthDateFormat date={date} />,
    },
    {
      title: 'ระยะเวลา',
      key: 'packageDuration',
      dataIndex: 'packageDuration',
    },
    {
      title: 'ราคา (บาท)',
      key: 'price',
      dataIndex: 'price',
      render: (str) => <NumberFormat str={str} />,
    },
    {
      title: 'สถานะ',
      key: 'status',
      dataIndex: 'status',
      render: (data) => <StatusTag type="package" status={data} />,
    },
  ];
};

export const quotationColumn = () => {
  return [
    {
      title: 'เลขที่ใบเสนอราคา',
      key: 'quotationId',
      dataIndex: 'quotationId',
      render: (data) => <LinkComponent text={data} />,
    },
    {
      title: 'วันที่ออก',
      key: 'createdDate',
      dataIndex: 'createdDate',
    },
    {
      title: 'วันหมดอายุ',
      key: 'expiredDate',
      dataIndex: 'expiredDate',
    },
    {
      title: 'ยอดรวม (บาท)',
      key: 'total',
      dataIndex: 'total',
    },
    {
      title: 'เดือนที่คาดว่าปิด',
      key: 'closedMonth',
      dataIndex: 'closedMonth',
    },
    {
      key: 'bookmark',
      dataIndex: 'bookmark',
      render: (data) => <Button shape="circle" icon={<StarOutlined />} />,
    },
  ];
};
