import { Descriptions } from 'antd';
import React from 'react';

function ContactDetail(props) {
  const { name, phone, email, isFavorite, position } = props;

  const items = [
    {
      key: '1',
      label: 'ชื่อผู้ติดต่อ',
      children: name || '-',
    },
    {
      key: '4',
      label: 'ตำแหน่ง',
      children: position || '-',
    },
    {
      key: '2',
      label: 'เบอร์โทรศัพท์',
      children: phone || '-',
    },
    {
      key: '3',
      label: 'อีเมล',
      children: email || '-',
    },
  ];

  return <Descriptions items={items} column={{ md: 2, xl: 2 }} size="small" />;
}

export default ContactDetail;
