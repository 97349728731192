import styled from 'styled-components';

export const ContentWrapper = styled.div`
  height: 100%;
  padding: 24px;
  box-shadow: 2px 2px 4px 0px #00000040;
  display: flex;
  flex-direction: column;
  background-color: #f2f5ff;
  border: 1px solid #b7c1f4;
  cursor: pointer;
`;

export const OverflowWrap = styled.div`
  max-width: 100%;
  height: 30px;
  display: block;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Title = styled.span`
  font-family: CS ChatThaiUI;
  font-size: 16px;
  line-height: 16px;
`;

export const HightlightText = styled.span`
  font-family: CS ChatThaiUI;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
`;

export const ValueText = styled.span`
  font-family: CS ChatThaiUI;
  font-size: 56px;
  line-height: 56px;
  color: ${(props) => props.color || '#a0a0a0'};
`;

export const UnitText = styled.span`
  font-family: CS ChatThaiUI;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
`;

export const ValueWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-end;
  height: 100%;
  max-width: 100%;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  max-width: 100%;

  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
