export const mockData = [
  {
    id: '1',
    companyName: 'JobFinFin',
    caNumber: 'CA1234',
    companySize: 'L',
    employeeSize: '501-1000',
    businessType: 'Recruiter',
    startDate: '2024-01-15',
    currentPackage: 'Standard',
    openedJob: 80,
    usageCredit: '2,680',
    totalCandidate: '752',
  },
  {
    id: '2',
    companyName: 'GMM',
    caNumber: 'CA1555',
    companySize: 'S',
    employeeSize: '11-50',
    businessType: 'บริการ',
    startDate: '2024-01-11',
    currentPackage: 'Standard',
    openedJob: 35,
    usageCredit: '850',
    totalCandidate: '388',
  },
  {
    id: '3',
    companyName: 'Nexon',
    caNumber: 'CA6584',
    companySize: 'M',
    employeeSize: '101-500',
    businessType: 'นำเข้าส่งออก',
    startDate: '2024-01-09',
    currentPackage: 'Standard',
    openedJob: 5,
    usageCredit: '150',
    totalCandidate: '80',
  },
  {
    id: '4',
    companyName: 'แสนดี',
    caNumber: 'CA1125',
    companySize: 'L',
    employeeSize: '501-1000',
    businessType: 'Start Up',
    startDate: '2024-01-08',
    currentPackage: 'Standard',
    openedJob: 64,
    usageCredit: '1,540',
    totalCandidate: '530',
  },
  {
    id: '5',
    companyName: 'หจก. โฆษณาใหม่',
    caNumber: 'CA7874',
    companySize: 'XS',
    employeeSize: '1-10',
    businessType: 'จันทร์เจ้า',
    startDate: '2024-01-07',
    currentPackage: 'Beyond',
    openedJob: 20,
    usageCredit: '650',
    totalCandidate: '270',
  },
  {
    id: '6',
    companyName: 'Gyprock',
    caNumber: 'CA1125',
    companySize: 'L',
    employeeSize: '501-1000',
    businessType: 'ค้าปลีก',
    startDate: '2024-01-05',
    currentPackage: 'Beyond Plus',
    openedJob: 34,
    usageCredit: '1,200',
    totalCandidate: '812',
  },
];
