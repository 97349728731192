export const manageHistoryData = [
  {
    reportDate: '14 ก.พ. 2025',
    reportDetail: 'งานประกาศหาย',
    reportTitle: 'เกี่ยวกับการใช้งาน',
    supervisor: 'CS | เจมี่-Apisada',
  },
  {
    reportDate: '13 ก.พ. 2024',
    reportDetail: 'PR ตำแหน่ง UX/UI Designer',
    reportTitle: 'PR งาน',
    supervisor: 'CS | เจมี่-Apisada',
  },
  {
    reportDate: '12 ก.พ. 2025',
    reportDetail: 'กดซื้อโปรไฟล์ไม่ได้',
    reportTitle: 'เกี่ยวกับการใช้งาน',
    supervisor: 'SALE | มั่ม-Montol',
  },
  {
    reportDate: '11 ก.พ. 2024',
    reportDetail: 'PR ตำแหน่ง Developer',
    reportTitle: 'PR งาน',
    supervisor: 'SALE | มั่ม-Montol',
  },
  {
    reportDate: '11 ก.พ. 2024',
    reportDetail: 'PR ตำแหน่ง Designer',
    reportTitle: 'PR งาน',
    supervisor: 'CS | เจมี่-Apisada',
  },
  {
    reportDate: '9 ก.พ. 2025',
    reportDetail: 'ขอส่วนลด',
    reportTitle: 'เกี่ยวกับการใช้งาน',
    supervisor: 'SALE | มั่ม-Montol',
  },
  {
    reportDate: '8 ก.พ. 2025',
    reportDetail: 'ผู้สมัครหายไปจากหน้าผู้สมัคร',
    reportTitle: 'เกี่ยวกับการใช้งาน',
    supervisor: 'CS | เจมี่-Apisada',
  },
];
