import React from 'react';
import ContactForm from './views/contact-form';
import { useFormState } from './views/contact-form/hooks/useFormState';
import { Container } from './styled-component';
import CallTimer from './views/call-timer';
import useCallAction from './hooks/useCallAction';

function Call({ companyDetails }) {
  const { formProps, callStatusProps, onSubmit } = useFormState({ companyDetails });

  const mockContactResponse = {
    success: true,
    message: 'success',
    result: {
      data: [
        {
          _id: '6710cb9b932ba4c33c52b756',
          phone: '0655989298',
          email: 'myungmay42@gmail.com',
        },
      ],
    },
  };

  const { callTimerProps, onSubmitTransaction } = useCallAction({
    contactId: mockContactResponse?.result.data,
    companyId: null,
    onCallDetails: () => {},
    onSubmit,
  });
  return (
    <Container>
      <CallTimer {...callTimerProps} callStatusProps={callStatusProps} />
      <ContactForm {...formProps} />
    </Container>
  );
}

export default Call;
