export const callHistoryData = [
  {
    key: '1',
    name: 'CS-เจมส์',
    callDate: '27 ก.พ. 2025, 11:20 น.',
    callDuration: '00:40 นาที',
    note: 'ไม่รับสายครั้งที่ 2',
    contactName: 'นารัตน์ ยอดเกตุก์',
    tel: '081-954-4848',
  },
  {
    key: '2',
    name: 'Sale-เมย์',
    callDate: '25 ก.พ. 2025, 09:30 น.',
    callDuration: '00:00 นาที',
    note: 'ไม่รับสาย',
    contactName: 'นารัตน์ ยอดเกตุก์',
    tel: '081-954-4848',
  },
  {
    key: '3',
    name: 'CS-มิว',
    callDate: '15 ก.พ. 2025, 11:22 น.',
    callDuration: '00:15 นาที',
    note: 'ฝากหมายเลข',
    contactName: 'จอเจียรัย ลอนธิต',
    tel: '081-954-2146',
  },
  {
    key: '4',
    name: 'Sale-เมย์',
    callDate: '12 ก.พ. 2025, 14:25 น.',
    callDuration: '00:25 นาที',
    note: 'กำลังจะคุย',
    contactName: 'จอเจียรัย ลอนธิต',
    tel: '081-954-2146',
  },
  {
    key: '5',
    name: 'CS-มิว',
    callDate: '10 ก.พ. 2025, 17:14 น.',
    callDuration: '01:25 นาที',
    note: 'โทร Follow Up ยืดเวลา (เบอร์ 081-954-2146) หากมีข้อสงสัยเรื่องรอบชำระเงินโปรดติดต่อ แผนกฝ่ายการเงินเพื่อขอข้อมูลเกี่ยวกับการทำสัญญา',
    contactName: '-',
    tel: '081-954-5555',
  },
  {
    key: '6',
    name: 'Sale-เมย์',
    callDate: '7 ก.พ. 2025, 11:50 น.',
    callDuration: '00:05 นาที',
    note: 'ให้โทรกลับเบอร์ 081-954-5555 ติดประชุม',
    contactName: 'อามิน เมาสี',
    tel: '081-954-7876',
  },
];
