export const jobData = [
  {
    key: '1',
    package: 'Free',
    jobTitle: 'พนักงานบัญชี',
    postDate: '19 พ.ค. 2024',
    updateDate: '18 พ.ค. 2024',
    status: 'เปิดรับสมัคร',
    candidate: '54 คน (+20)',
  },
  {
    key: '2',
    package: 'Buy',
    jobTitle: 'Product Owner',
    postDate: '10 เม.ย. 2024',
    updateDate: '9 พ.ค. 2024',
    status: 'เปิดรับสมัคร',
    candidate: '10 คน (+2)',
  },
  {
    key: '3',
    package: 'Buy',
    jobTitle: 'โปรแกรมเมอร์ iOS',
    postDate: '8 เม.ย. 2024',
    updateDate: '7 พ.ค. 2024',
    status: 'ปิดรับสมัคร',
    candidate: '55 คน (+15)',
  },
  {
    key: '4',
    package: 'Free',
    jobTitle: 'Designer',
    postDate: '14 ธ.ค. 2023',
    updateDate: '20 พ.ค. 2024',
    status: 'ปิดรับสมัคร',
    candidate: '115 คน (+80)',
  },
];

export const mockData = [
  {
    id: 1,
    jobsTitle: ['พนักงานบัญชี (2)', 'โปรแกรมเมอร์ IOS (1)'],
    attachLinks: ['https://facebook.com/fdl213543758fgf', 'https://facebook.com/fdl213543758fgf'],
    sendDate: '10 เม.ย. 2024',
    senderName: 'PR-หมุย',
  },
  {
    id: 2,
    jobsTitle: ['พนักงานบัญชี (2)', 'โปรแกรมเมอร์ IOS (1)'],
    attachLinks: ['https://facebook.com/fdl213543758fgf', 'https://facebook.com/fdl213543758fgf'],
    sendDate: '10 เม.ย. 2024',
    senderName: 'PR-หมุย',
  },
  {
    id: 3,
    jobsTitle: ['พนักงานบัญชี (2)', 'โปรแกรมเมอร์ IOS (1)'],
    attachLinks: ['https://facebook.com/fdl213543758fgf', 'https://facebook.com/fdl213543758fgf'],
    sendDate: '10 เม.ย. 2024',
    senderName: 'PR-หมุย',
  },
];
