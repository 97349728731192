import AssignTelesalesScene from '../screens/assign-telesales/index.jsx';
import CustomerSuccessScene from '../screens/customer-success/index.jsx';
import { ROLES } from '../utils/admin/role.js';

//routes
export const ROUTE_LOGIN = 'https://central.zetta-system.com';
export const ROUTE_HANDLE = '/account/handle';
export const ROUTE_TELESALES = '/telesales';
export const ROUTE_SALES = '';
export const ROUTE_ASSIGN_TELESALES = '/assign-telesales';

//keys
export const KEY_ROUTE_MAIN = '@ROUTES/ROUTE_MAIN';
export const KEY_ROUTE_HOME = '@ROUTES/ROUTE_HOME';
export const KEY_ROUTE_DASHBOARD = '@ROUTES/ROUTE_DASHBOARD';
export const KEY_ROUTE_TELESALES = '@ROUTES/ROUTE_TELESALES';
export const KEY_ROUTE_SALES = '@ROUTES/ROUTE_SALES';
export const KEY_ROUTE_ASSIGN_TELESALES = '@ROUTES/ROUTE_ASSIGN_TELESALES';

export const ROUTES_PATH = {
  ROUTE_MAIN: {
    KEY: KEY_ROUTE_MAIN,
    PATH: '/',
    LABEL: 'Customer Success',
    COMPONENT: CustomerSuccessScene,
    ACCESS_ROLES: [ROLES.TELESALE, ROLES.PR, ROLES.SUPER_ADMIN, ROLES.CUSTOMER_SERVICE],
  },
  ROUTE_ASSIGN_TELESALES: {
    KEY: KEY_ROUTE_ASSIGN_TELESALES,
    PATH: ROUTE_ASSIGN_TELESALES,
    LABEL: 'Assign Admin',
    COMPONENT: AssignTelesalesScene,
    ACCESS_ROLES: [ROLES.SUPER_ADMIN],
  },
};

function getKeyLowerCase(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v]));
}

export const ROUTES_MENU = [
  getKeyLowerCase(ROUTES_PATH.ROUTE_MAIN),
  getKeyLowerCase(ROUTES_PATH.ROUTE_ASSIGN_TELESALES),
];
