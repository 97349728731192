import React from 'react';
import PropTypes from 'prop-types';
import { LinkStyled } from './styled-component';
import { Icon } from '../../resources';

function LinkComponent({
  children,
  href,
  isShowIcon,
  isBlue,
  isNewTab,
  color,
  isUnderline,
  rel,
  style,
  onClick,
}) {
  return (
    <LinkStyled
      onClick={onClick}
      href={href}
      target={isNewTab ? '_blank' : '_self'}
      rel={isNewTab ? 'noopener noreferrer' : rel}
      style={{
        ...style,
        ...(isUnderline && { textDecoration: 'underline' }),
        ...(color && { color: color }),
        ...(isBlue && { color: '#4857DC' }),
      }}
    >
      {children} {isShowIcon && <Icon.externalLink style={{ verticalAlign: 'middle' }} />}
    </LinkStyled>
  );
}

LinkComponent.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  isNewTab: PropTypes.bool,
  rel: PropTypes.string,
  style: PropTypes.object,
  isShowIcon: PropTypes.bool,
  isUnderline: PropTypes.bool,
  isBlue: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

LinkComponent.defaultProps = {
  children: null,
  href: null,
  isNewTab: false,
  rel: 'noopener noreferrer',
  style: {},
  isShowIcon: false,
  isUnderline: false,
  isBlue: false,
  color: null,
  onClick: () => {},
};

export default LinkComponent;
