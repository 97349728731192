import React from 'react';
import Layout from '../../layout';
import Table from '../../../../../components/table-antd';
import { quotationColumn } from './constants/columns';
import { quotationData } from './mock';
import { Typography } from 'antd';

function Quotation() {
  return (
    <Layout>
      <Layout.Content>
        <Layout.Header>
          <Layout.Header.Title>ใบเสนอราคา</Layout.Header.Title>
          <Typography.Text disabled>ใบเสนอราคาที่คาดว่าปิดได้</Typography.Text>
        </Layout.Header>

        <Table columns={quotationColumn()} data={quotationData} />
      </Layout.Content>
    </Layout>
  );
}

export default Quotation;
