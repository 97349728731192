import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 30 minutes
      retry: 1, // ลองดึงข้อมูลใหม่ 1 ครั้งหากเกิดข้อผิดพลาด
      refetchOnWindowFocus: false, // ไม่ดึงข้อมูลใหม่เมื่อกลับมาที่หน้าต่าง
      refetchOnReconnect: true, // ดึงข้อมูลใหม่เมื่อเชื่อมต่ออินเทอร์เน็ตกลับมา
    },
  },
});
