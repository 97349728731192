import React from 'react';
import Tabs from './views/tabs';
import ModalLayout from '../../layout/modal-layout';
import CompanyDetail from '../../components/company-detail';
import { ContentBelowContainer, FixedContainer, RightColumnContainer } from './styled-component';
import { mockDetail } from '../../components/company-detail/mock';
import Details from './views/details';
import Call from './views/call';

function SalesModal(props) {
  const { ...modalProps } = props;

  const companyDetails = mockDetail;

  return (
    <ModalLayout
      modalProps={{
        ...modalProps,
        id: 'sales-modal',
        title: 'รายละเอียดบริษัท',
        fullscreen: true,
      }}
    >
      <ModalLayout.leftCol>
        <Call companyDetails={companyDetails} />
        <Details companyDetails={companyDetails} />
      </ModalLayout.leftCol>
      <ModalLayout.rightCol>
        <RightColumnContainer>
          <FixedContainer>
            <CompanyDetail {...companyDetails} />
          </FixedContainer>
          <ContentBelowContainer>
            <Tabs />
          </ContentBelowContainer>
        </RightColumnContainer>
      </ModalLayout.rightCol>
    </ModalLayout>
  );
}

export default SalesModal;
