export const MODAL_TYPES = {
  TRACKING: 'TRACKING_MODAL',
  SALES: 'SALES_MODAL',
  APPLICANT_COUNT: 'APPLICANTS_COUNT_MODAL',
  CONTACT: 'CONTACT_MODAL',
  HISTORY_CALL: 'HISTORY_CALL_MODAL',
  EDIT_CALL_INFOMATION: 'EDIT_CALL_INFOMATION_MODAL',
  PACKAGE_ITEMS: 'PACKAGE_ITEMS_MODAL',
  NOTE: 'NOTE_MODAL',
  ADDITION_NOTE: 'ADDITION_NOTE_MODAL',
  QUOTATION: 'QUOTATION_MODAL',
};
