import styled from 'styled-components';
import { color } from '../../../../resources';

export const NoteContainer = styled.div`
  width: 100%;
  max-height: 222px;
  padding: 12px 16px;
  overflow: auto;
  background-color: ${color.grey25};
`;
