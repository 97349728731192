import React from 'react';
import { DatePicker, Space } from 'antd';

function CompareDate() {
  return (
    <Space>
      <DatePicker.RangePicker
        placeholder={['', 'วันนี้']}
        allowEmpty={[false, true]}
        onChange={(date, dateString) => {
          console.log(date, dateString);
        }}
      />
    </Space>
  );
}

export default CompareDate;
