import React from 'react';
import { RenderForm } from '../../../../../../../../../../components/forms';
import { useOptions } from '../../../../../../../../../../hooks/useOptions';
import { useSelector } from 'react-redux';
import { FORM_INPUT } from './form';

function CompanyInformationForm({ formProps }) {
  const { options } = useOptions();
  const { user } = useSelector((state) => state.authen);
  return (
    <RenderForm
      forms={FORM_INPUT({
        options,
        isDisabledKeySuccess: user?.role !== 'SUPER_ADMIN',
      })}
      {...formProps}
    />
  );
}

export default CompanyInformationForm;
