export const mockData = [
  {
    id: '1',
    companyName: 'JobFinFin',
    caNumber: 'CA1234',
    companySize: 'L',
    employeeSize: '501-1000',
    businessType: 'Recruiter',
    startDate: '2024-01-15',
    currentPackage: 'Standard',
    total: '17,502 บาท',
  },
  {
    id: '2',
    companyName: 'GMM',
    caNumber: 'CA1555',
    companySize: 'M',
    employeeSize: '101-500',
    businessType: 'บริการ',
    startDate: '2024-01-11',
    currentPackage: 'Standard',
    total: '17,502 บาท',
  },
  {
    id: '3',
    companyName: 'Copperwide',
    caNumber: 'CA4587',
    companySize: 'S',
    employeeSize: '11-50',
    businessType: 'ค้าปลีก',
    startDate: '2024-01-10',
    currentPackage: 'Beyond',
    total: '35,502 บาท',
  },
  {
    id: '4',
    companyName: 'Nexon',
    caNumber: 'CA6584',
    companySize: 'XS',
    employeeSize: '1-10',
    businessType: 'นำเข้าส่งออก',
    startDate: '2024-01-09',
    currentPackage: 'Standard',
    total: '14,502 บาท',
  },
  {
    id: '5',
    companyName: 'ชาเขียว-ภูเก็ตฮิลส์',
    caNumber: 'CA7874',
    companySize: 'M',
    employeeSize: '101-500',
    businessType: 'IT',
    startDate: '2024-01-09',
    currentPackage: 'Standard',
    total: '14,502 บาท',
  },
  {
    id: '6',
    companyName: 'แสนดี',
    caNumber: 'CA1125',
    companySize: 'L',
    employeeSize: '501-1000',
    businessType: 'Start Up',
    startDate: '2024-01-08',
    currentPackage: 'Standard',
    total: '14,502 บาท',
  },
  {
    id: '7',
    companyName: 'หจก. โฆษณาใหม่',
    caNumber: 'CA7874',
    companySize: 'M',
    employeeSize: '101-500',
    businessType: 'จันทร์เจ้า',
    startDate: '2024-01-07',
    currentPackage: 'Beyond Plus',
    total: '56,502 บาท',
  },
  {
    id: '8',
    companyName: 'Supersoft',
    caNumber: 'CA1125',
    companySize: 'L',
    employeeSize: '501-1000',
    businessType: 'ค้าปลีก',
    startDate: '2024-01-05',
    currentPackage: 'Standard',
    total: '12,502 บาท',
  },
];
