import React from 'react';
import { isEmpty } from 'lodash';
import Layout from '../../layout';
import Table from '../../../../../components/table-antd';
import { pagekageColumn, quotationColumn } from './constants/columns';
import { packageData, quotationData } from './mock';
import { Typography } from 'antd';

function Package() {
  return (
    <Layout>
      <Layout.Content>
        <Layout.Header>
          <Layout.Header.Title>แพ็กเกจ</Layout.Header.Title>
        </Layout.Header>
        <Table
          columns={pagekageColumn()}
          data={packageData}
          summary={(data) => {
            const totalSum = data
              .filter((item) => !isEmpty(item?.price))
              .reduce((sum, item) => sum + Number(item.price), 0);

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>ยอดรวม</Table.Summary.Cell>
                  <Table.Summary.Cell index={1} />
                  <Table.Summary.Cell index={2} />
                  <Table.Summary.Cell index={3} />
                  <Table.Summary.Cell index={4} />
                  <Table.Summary.Cell index={5} />
                  <Table.Summary.Cell index={6}>{totalSum}</Table.Summary.Cell>
                  <Table.Summary.Cell index={7} />
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Layout.Content>
    </Layout>
  );
}

export default Package;
