import { Tabs } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const TabStyled = styled(Tabs)`
  height: 100%;

  .ant-tabs-content-holder {
    max-height: calc(100% - 40px);
    overflow-y: auto;
    padding: 8px;
  }
`;
